// packages
import React from 'react';
import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// own css
import { Card, CardContent, CardActions, Button, TextField, Grid, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// сomponents
import { makeStyles } from '@material-ui/core/styles';
import RegisterTableFullScreenRecipient from './RegisterTableFullScreenRecipient';
import RegisterExcel from './RegisterExcel/RegisterExcel';
// redux
import {
  clearRecipient,
  getRecipientsFatch,
  approve,
  denied,
  recipientAction,
  getRecipientsSearch,
  recipientById,
  getRecipientHistoryApplications,
} from '../../../redux/actions/recipient.action';
import { createLoadingSelector, createErrorMessageSelector, clearErrors } from '../../../redux/actions/selectors';
import { localityAction, setLocality } from '../../../redux/actions/catalog.action';
// validation
import rules from '../../../vibe/components/utilities/MainUtils/rules';
import BootstrapTooltip from '../../elements/MaterialBootstrap';
import RegisterJson from './RegisterJson/RegisterJson';
import { sendNotification } from '../../../api/api';

const loadingSelector = createLoadingSelector(['CATALOG', 'LOCALITY']);

const styles = (theme) => ({
  // smallSize: {
  root: {
    fontSize: '12px',
  },
  button: {
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  // },
});

class RegisterRecipients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      p_region: '',
      p_edrpou: '',
      p_orgname: '',
      p_koatuu: '',
      n_number: '',
      p_recipienttype: '',
      defaultValue: '',
      visible: false,
      page: 1,
      col: 10,
    };
  }

  componentDidMount() {
    this.props.clearRecipient();
  }

  filterOptions = (candidate, input) => {
    if (candidate.data.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
    }
    return false;
  };

  // select
  // handleSelectChange = (name) => (e, select, { action, removedValue }) => {
  //   const objDelete = {};
  //   objDelete[name] = '';
  //   switch (action) {
  //     case 'select-option':
  //       const obj = {};
  //       obj[name] = select;
  //       this.setState(obj);
  //       if (name === 'p_region') {
  //         this.setState({ p_koatuu: '' });
  //         this.props.localityAction(select.value);
  //       }
  //       break;
  //     case 'clear':
  //       if (name === 'p_region') {
  //         this.setState({ p_koatuu: '' });
  //         this.props.setLocality([]);
  //       }
  //       this.setState(objDelete);
  //       break;
  //   }
  // };

  handleSelectChange = (name) => (e, select) => {
    const objDelete = {};
    objDelete[name] = '';
    if (select) {
      // const { value } = select;
      const obj = {};
      obj[name] = select;
      this.setState(obj);

      if (name === 'p_region') {
        this.setState({ p_koatuu: '' });
        this.props.localityAction(select.value);
      }
    } else {
      this.setState(objDelete);

      if (name === 'p_region') {
        this.setState({ p_koatuu: '' });
        this.props.setLocality([]);
      }
    }
  };

  // datapicker
  handleChangeData = (name) => (data) => {
    this.setState({ [name]: data });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const valuetoUpperCase = value.toUpperCase();
    if (value === '') {
      this.setState({ [name]: value });
    }
    switch (name) {
      case 'p_edrpou':
        rules.edrpou.test(event.target.value) && this.setState({ [name]: event.target.value });
        break;
      case 'n_number':
        // this.setState({ [name]: event.target.value });
        rules.number.test(event.target.value) && this.setState({ [name]: event.target.value });
        break;
      case 'p_orgname':
        rules.orgName.test(valuetoUpperCase) && this.setState({ [name]: valuetoUpperCase });
        break;
      default:
        break;
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
    const {
      p_region,
      p_edrpou,
      p_orgname,
      p_koatuu,
      startDate,
      endDate,
      n_number,
      p_recipienttype,
      page,
      col,
    } = this.state;
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const data = {
      p_user_id: this.props.user.id,
      p_user_role: this.props.user.c_role,
      p_region: p_region ? (p_region || {}).value : '',
      p_edrpou: p_edrpou.trim(),
      // p_numb: n_number,
      n_number,
      p_orgname: p_orgname.trim(),
      p_koatuu: p_koatuu ? (p_koatuu || {}).value : '',
      dateStart: startDate ? startDate.toLocaleDateString('uk', options) : '',
      dateEnd: endDate ? endDate.toLocaleDateString('uk', options) : '',
      // new field for search
      recipient: p_recipienttype ? Number(p_recipienttype.value) : 0,
      page,
      col,
    };

    this.props.getRecipientsSearch(data);
    this.setState({ visible: true });
  };

  sendEmail = () => {
    sendNotification().then((res) => {});
  };

  handleChangePage = async (event, newPage) => {
    const page = newPage + 1;
    this.setState({ page }, () => {
      this.handleSearch(event);
    });
  };

  handleChangeRowsPerPage = async (event) => {
    this.setState({ col: +event.target.value, page: 0 }, () => {
      this.handleSearch(event);
    });
  };

  render() {
    const { recipients, classes } = this.props;
    const { recipientTypes } = this.props.catalog;
    const data = recipients.cursor.map((el) => ({
      ...el,
      recipient_type: recipientTypes.find((it) => Number(it.value) === Number(el.i_recipient_type))?.label || '',
    }));

    const { p_region, p_edrpou, p_orgname, p_koatuu, startDate, endDate, n_number, p_recipienttype } = this.state;
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };

    const dataToGetExcel = {
      p_user_id: this.props.user.id,
      p_user_role: this.props.user.c_role,
      p_region: p_region ? (p_region || {}).value : '',
      p_edrpou: p_edrpou.trim(),
      // p_numb: n_number,
      n_number,
      p_orgname: p_orgname.trim(),
      p_koatuu: p_koatuu ? (p_koatuu || {}).value : '',
      dateStart: startDate ? startDate.toLocaleDateString('uk', options) : '',
      dateEnd: endDate ? endDate.toLocaleDateString('uk', options) : '',
      // new field for search
      recipient: p_recipienttype ? Number(p_recipienttype.value) : 0,
    };
    return (
      <div className="RegisterRecipient">
        <section className="searchRegisterRecipient">
          <Card>
            <form onSubmit={this.handleSearch} autoComplete="off">
              <CardContent>
                <div className="row">
                  <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                    {/* <label htmlFor="p_region">Область</label> */}
                    <BootstrapTooltip title=" Оберіть область зі списку." placement="top-start">
                      <div>
                        {/* <Select
                          defaulValue={this.state.p_region}
                          onChange={this.handleSelectChange('p_region')}
                          options={this.props.catalog.region}
                          styles={customStyles}
                          placeholder=""
                          isClearable
                          isSearchable
                          isLoading={this.props.loading.CATALOG}
                          noOptionsMessage={() => 'помилка серверу'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                          name="p_region"
                        /> */}
                        <Autocomplete
                          // value={this.props.catalog.documentTypes2.filter(
                          //   ({ value }) => Number(value) === Number(this.state.doctype2.value)
                          // )[0]}
                          // size="small"
                          value={this.state.p_region}
                          // defaulValue={this.state.p_recipienttype}
                          onChange={this.handleSelectChange('p_region')}
                          autoHighlight
                          getOptionSelected={(option) => option.value === this.state.p_region.value}
                          getOptionLabel={(option) => option.label}
                          options={this.props.catalog.region}
                          loading={this.props.loading.CATALOG}
                          renderOption={({ label, district }) => <span style={{ fontSize: '14px' }}>{label}</span>}
                          renderInput={(params) => (
                            <TextField {...params} InputLabelProps={{ style: { fontSize: '12px' } }} label="Область" />
                          )}
                        />
                      </div>
                    </BootstrapTooltip>
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                    {/* <label htmlFor="p_koatuu">Населений пункт</label> */}
                    <BootstrapTooltip title=" Оберіть населений пункт зі списку." placement="top-start">
                      <div>
                        {/* <Select
                          value={this.state.p_koatuu}
                          onChange={this.handleSelectChange('p_koatuu')}
                          getOptionLabel={({ label, district }) => (
                            <div>
                              <span>{label}</span> <span style={{ color: '#999' }}>{district}</span>
                            </div>
                          )}
                          options={this.props.catalog.locality}
                          styles={customStyles}
                          isClearable
                          isLoading={this.props.loading.LOCALITY}
                          isSearchable
                          placeholder=""
                          filterOption={this.filterOptions}
                          noOptionsMessage={() => 'Оберіть область'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                          name="p_koatuu"
                        /> */}

                        <Autocomplete
                          // value={this.props.catalog.documentTypes2.filter(
                          //   ({ value }) => Number(value) === Number(this.state.doctype2.value)
                          // )[0]}
                          value={this.state.p_koatuu}
                          onChange={this.handleSelectChange('p_koatuu')}
                          autoHighlight
                          getOptionSelected={(option) => option.value === this.state.p_koatuu.value}
                          getOptionLabel={(option) => option.label}
                          renderOption={({ label, district }) => (
                            <Grid container direction="column">
                              <span style={{ fontSize: '14px' }}>{label}</span>
                              <span style={{ color: '#999', fontSize: '12px' }}>{district}</span>
                            </Grid>
                          )}
                          options={this.props.catalog.locality}
                          loading={this.props.loading.LOCALITY}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ style: { fontSize: '12px' } }}
                              label="Населений пункт"
                            />
                          )}
                        />
                      </div>
                    </BootstrapTooltip>
                  </div>

                  <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                    {/* <label htmlFor="p_edrpou">Код ЄДРПОУ</label> */}
                    <TextField
                      label="Код ЄДРПОУ"
                      value={this.state.p_edrpou}
                      onChange={this.handleChange}
                      name="p_edrpou"
                      type="text"
                      autocomplete="off"
                      // style={{fontSize: '12px'}}
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      inputProps={{
                        maxLength: 8,
                      }}
                    />
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-md-2 mt-xl-0">
                    {/* <label htmlFor="p_orgname">Назва отримувача</label> */}
                    <TextField
                      label="Назва отримувача"
                      value={this.state.p_orgname}
                      onChange={this.handleChange}
                      name="p_orgname"
                      type="text"
                      // maxLength="250"
                      inputProps={{
                        maxLength: 250,
                      }}
                      autocomplete="off"
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                    />
                  </div>

                  <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-md-2">
                    {/* <label htmlFor="">№ отримувача в реєстрі</label> */}
                    <BootstrapTooltip title="Введіть тільки цифри." placement="top-start">
                      <TextField
                        label="№ отримувача в реєстрі"
                        value={this.state.n_number}
                        onChange={this.handleChange}
                        name="n_number"
                        type="text"
                        // style={{fontSize: '12px'}}
                        // size="small"
                        dense
                        autocomplete="off"
                        InputLabelProps={{ style: { fontSize: '12px' } }}
                      />
                    </BootstrapTooltip>
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                    {/* <label htmlFor="startDate">Дата включення в реєстр</label> */}
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleChangeData('startDate')}
                      dateFormat="dd.MM.yyyy"
                      maxDate={new Date()}
                      locale={uk}
                      className="datePicker"
                      icon={<i className="cal-icon" />}
                      name="startDate"
                      autoComplete="off"
                      customInput={
                        <TextField InputLabelProps={{ style: { fontSize: '12px' } }} label="Дата включення в реєстр" />
                      }
                    />
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                    {/* <label htmlFor="endDate">Дата виключення з реєстру</label> */}
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={this.handleChangeData('endDate')}
                      startDate={this.state.startDate}
                      minDate={this.state.startDate}
                      dateFormat="dd.MM.yyyy"
                      maxDate={new Date()}
                      className="datePicker"
                      locale={uk}
                      autoComplete="off"
                      customInput={
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          label="Дата виключення з реєстру"
                        />
                      }
                    />
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                    {/* <label htmlFor="">Тип отримувача</label> */}
                    <BootstrapTooltip title=" Оберіть тип отримувача зі списку." placement="top-start">
                      <div>
                        {/* <Select
                          defaulValue={this.state.p_recipienttype}
                          onChange={this.handleSelectChange('p_recipienttype')}
                          options={this.props.catalog.recipientTypes}
                          styles={customStyles}
                          placeholder=""
                          isClearable={true}
                          isSearchable={true}
                          isLoading={this.props.loading.CATALOG}
                          noOptionsMessage={() => 'помилка серверу'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                          name="p_recipienttype"
                        /> */}
                        <Autocomplete
                          // value={this.props.catalog.documentTypes2.filter(
                          //   ({ value }) => Number(value) === Number(this.state.doctype2.value)
                          // )[0]}
                          value={this.state.p_recipienttype}
                          // defaulValue={this.state.p_recipienttype}
                          onChange={this.handleSelectChange('p_recipienttype')}
                          autoHighlight
                          getOptionSelected={(option) => option.value === this.state.p_recipienttype.value}
                          getOptionLabel={(option) => option.label}
                          options={this.props.catalog.recipientTypes}
                          loading={this.props.loading.CATALOG}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ style: { fontSize: '12px' } }}
                              label="Тип отримувача"
                            />
                          )}
                          renderOption={({ label, district }) => <span style={{ fontSize: '14px' }}>{label}</span>}
                        />
                      </div>
                    </BootstrapTooltip>
                  </div>

                  <div className="col-12 mt-3 mb-4 text-center" />
                </div>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <i className="fa fa-search mr-1" /> Пошук
                </Button>
                {/* Excel button */}
                <RegisterExcel dataToGetExcel={dataToGetExcel} />
                <RegisterJson />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className="send-email-button"
                  onClick={this.sendEmail}
                  style={{
                    display: 'none',
                  }}
                >
                  Розсилки на email
                </Button>
              </CardActions>
            </form>
          </Card>
        </section>
        <section className="dataMainRegister mt-3">
          <div>
            {this.state.visible ? (
              <RegisterTableFullScreenRecipient
                history={this.props.history}
                getRecipientsFatch={this.props.getRecipientsFatch}
                recipients={data}
                pn_page={recipients.pn_page}
                pn_rows={recipients.pn_rows}
                total_rows={recipients.total_rows}
                loading={this.props.loading}
                approve={this.props.approve}
                denied={this.props.denied}
                error={this.props.errMessage}
                user={this.props.user}
                recipientById={this.props.recipientById}
                getRecipientHistoryApplications={this.props.getRecipientHistoryApplications}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            ) : null}
            <br />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
    recipients: state.recipient.recipients,
    loading: state.isFatching,
    errMessage: state.error,
    user: state.auth.user,
    catalog: state.catalogs,
    recipient: state.recipient.recipient,
    isFatching: loadingSelector(state),
  };
};

RegisterRecipients.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  getRecipientsFatch: PropTypes.func.isRequired,
  recipientById: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  loading: PropTypes.object.isRequired,
  approve: PropTypes.func.isRequired,
  denied: PropTypes.func.isRequired,
  errMessage: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  clearRecipient,
  getRecipientsSearch,
  localityAction,
  recipientAction,
  createLoadingSelector,
  createErrorMessageSelector,
  clearErrors,
  getRecipientsFatch,
  approve,
  denied,
  recipientById,
  setLocality,
  getRecipientHistoryApplications,
})(withStyles(styles)(RegisterRecipients));
