import React from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField } from '@material-ui/core';
import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
import { history } from '../../../../vibe/components/utilities/MainUtils/hist';
import { getDeclaration, getDeclaration2, saveDeclaration } from '../../../../api/api';

import Table1 from './Table1';
import getPage from './getPDFfile';
import DialogSign from './DialogSign';
import DialogView from './DialogView';

import save from '../../../../assets/images/save.png';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#000',
  },
  input: {
    borderColor: '#000',
    borderRadius: 20,
  },
  checkboxLabel: {
    fontSize: 22,
    textAlign: 'left',
    paddingLeft: '20px',
    fontFamily: 'dia',
  },
  buttons_save: {
    display: 'flex',
    width: '100%',
    alignContent: 'space-between',
    margin: '30px auto',
  },

  button_save: {
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',

    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: 'auto',

    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    // display: 'flex',
    // alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',

    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  button_loading_img: {
    marginLeft: '15px',
  },
}));

const DeclarationTab = () => {
  const {
    auth: {
      user: { id: userId },
    },
    cargo: {
      cargoApplication: { i_cargo: cargoId, i_cargo_app },
    },
    declaration,
  } = useSelector((state) => state);

  const classes = useStyles();

  const [tab1, setTab1] = React.useState([]);
  const [tab2, setTab2] = React.useState([]);
  const [inputs, setInputs] = React.useState({
    pc_surname: '',
    pc_name: '',
    pc_patronymic: '',
  });
  const [saved, setSaved] = React.useState(true);
  const [registered, setRegistered] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [openSign, setOpenSign] = React.useState(false);
  // pdf
  const [pdf, setPdf] = React.useState();
  const [string64, setString64] = React.useState('');

  const handleOpenView = () => {
    setOpenView(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  const handleOpenSign = () => {
    setOpenSign(true);
  };

  const handleCloseSign = () => {
    setOpenSign(false);
  };

  const doSignAction = () => {
    setOpenSign(false);
  };

  const previewDeclaration = () => {
    getDeclaration2(cargoId)
      .then((res) => {
        const { data } = res;
        setPdf(getPage(data));
        return getPage(data);
      })
      .then((file) => {
        file.getBase64((data) => {
          setString64(data);
        });
      })
      .then(() => {
        handleOpenView();
      })
      .catch((err) => {});
  };

  const setInitialValues = () => {
    setTab1([]);
    setTab2([]);
    setInputs({
      pc_surname: '',
      pc_name: '',
      pc_patronymic: '',
    });
  };

  React.useEffect(() => {
    if (!isEmpty(declaration)) {
      const { pc_surname, pc_name, pc_patronymic, recordsCarDeparture, recordsCarReboot } = declaration;
      setInputs({
        pc_surname,
        pc_name,
        pc_patronymic,
      });
      const newTab1 = recordsCarDeparture ? [...recordsCarDeparture] : [];
      const newTab2 = recordsCarReboot ? [...recordsCarReboot] : [];
      setTab1([...newTab1]);
      setTab2([...newTab2]);
    } else {
      setInitialValues();
    }
  }, [declaration]);

  const handleInput = (event) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSaveDeclaration = () => {
    const { pc_surname, pc_name, pc_patronymic } = inputs;

    const data = {
      pi_user_id: userId,
      pi_cargo: cargoId,
      pc_surname,
      pc_name,
      pc_patronymic,
      pi_checkpoint: null,
      recordsCarDeparture: [...tab1],
      recordsCarReboot: tab2.length > 0 ? [...tab2] : null,
    };
    saveDeclaration(data)
      .then((res) => {
        setSaved(true);
        const { recordsCarDeparture, recordsCarReboot } = res.data;
        const newTab1 = recordsCarDeparture ? [...recordsCarDeparture] : [];
        const newTab2 = recordsCarReboot ? [...recordsCarReboot] : [];
        setTab1([...newTab1]);
        setTab2([...newTab2]);
        Toast.fire({
          type: 'success',
          title: 'Дані збережено!',
        });
      })
      .catch((err) => {});
  };

  const registerAndLoad = () => {
    swalWithBootstrapButtons.fire({
      text:
        'Для бюджетних організацій та ОМС, або якщо Ви виявили невідповідність фактично отриманої гум.допомоги потрібно подати Інвентаризацію протягом 15 календарних днів з дати митного оформлення',
      showCancelButton: false,
      confirmButtonText: 'Ок',
      cancelButtonText: 'Відміна',
    });
    getDeclaration(i_cargo_app, cargoId)
      .then((res) => {
        const { data } = res;
        return getPage(data);
      })
      .then((file) => {
        file.download();
        setRegistered(true);
        history.push('/pages/delivery');
      })
      .catch((err) => {});
  };

  const check1 = tab1
    // eslint-disable-next-line radix
    .filter((item) => parseInt(item.b_deleted) === 0 || item.b_deleted === null).length;
  const check2 = tab2
    // eslint-disable-next-line radix
    .filter((item) => parseInt(item.b_deleted) === 0 || item.b_deleted === null).length;
  const bothEmpty = check1 === 0 && check2 === 0;

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <h5
                className="text-center"
                style={{ color: 'rgb(127, 143, 164)', fontWeight: 'bold', fontFamily: 'dia' }}
              >
                Особа, що здійснює декларування:
              </h5>
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <TextField
                label="Прізвище"
                type="text"
                name="pc_surname"
                value={inputs.pc_surname}
                onChange={handleInput}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <TextField
                label="Власне ім'я"
                type="text"
                name="pc_name"
                value={inputs.pc_name}
                onChange={handleInput}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <TextField
                label="По батькові (за наявності)"
                type="text"
                name="pc_patronymic"
                value={inputs.pc_patronymic}
                onChange={handleInput}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h4
            className="text-center"
            style={{ color: 'rgb(127, 143, 164)', fontWeight: 'bold', margin: '50px 0 0 0', fontFamily: 'dia' }}
          >
            Транспорт відправлення:
          </h4>

          <Table1 tab={tab1} setTab={setTab1} saved={saved} setSaved={setSaved} showInfo />
        </Grid>
        <Grid item xs={12}>
          <h4
            className="text-center"
            style={{ color: 'rgb(127, 143, 164)', fontWeight: 'bold', margin: '50px 0 0 0', fontFamily: 'dia' }}
          >
            Транспорт перевантаження:
          </h4>

          <p className="text-center" style={{ color: 'rgb(127, 143, 164)', fontFamily: 'dia' }}>
            (заповнюється виключно у випадку, коли на кордоні відбувається перевантаження на інший транспортний засіб)
          </p>
          <Table1 tab={tab2} setTab={setTab2} saved={saved} setSaved={setSaved} />
        </Grid>

        <div className={classes.buttons_save}>
          <Button
            disabled={!cargoId || saved || bothEmpty || registered}
            variant="contained"
            color="primary"
            type="button"
            className={classes.button_save}
            onClick={handleSaveDeclaration}
          >
            Зберегти
            <img className={classes.button_loading_img} src={save} />
          </Button>
          {/* <Button
            disabled={!cargoId}
            variant="contained"
            color="primary"
            type="button"
            className={classes.button_save}
            onClick={previewDeclaration}
          >
            Підписати
          </Button> */}
          <Button
            disabled={!cargoId || bothEmpty || !saved || registered}
            type="button"
            className={classes.button_save}
            variant="contained"
            color="primary"
            onClick={registerAndLoad}
          >
            Зареєструвати
            <img className={classes.button_loading_img} src={save} />
          </Button>
        </div>
      </Grid>
      {pdf && (
        <DialogView
          openView={openView}
          handleOpenView={handleOpenView}
          handleCloseView={handleCloseView}
          handleOpenSign={handleOpenSign}
          pdf={pdf}
        />
      )}
      {string64 && (
        <DialogSign
          openSign={openSign}
          handleOpenSign={handleOpenSign}
          handleCloseSign={handleCloseSign}
          doSignAction={doSignAction}
          strings64={string64}
          cargoId={cargoId}
          pdf={pdf}
        />
      )}
    </>
  );
};

export default DeclarationTab;
