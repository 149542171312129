import React, { useRef, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import uk from 'date-fns/locale/uk';
import format from 'date-fns/format';
import { Typography, TextField, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { ReactComponent as BackIcon } from './reportHelpers/assets/BackIcon.svg';
import NumberFormatCustom from '../../../../vibe/components/inputs/NumberFormatCustom.component';
import {
  setReportAidtype,
  setDistributionCurrent,
  setDistributionInput,
  setDistributionCards,
  setDistributionSaver,
  setReportTab,
  setBalanceArray,
  setDistributionArray,
  setReportCargoList,
  setReportParams,
  setReportAcquirer,
} from '../../../../redux/actions/report.action';
import { getReportData, saveDistribAct, saveReportDistribution, getDistribByAct } from '../../../../api/api';
import { localityAction, setLocality } from '../../../../redux/actions/catalog.action';
import { transformBalance, transformDistribution } from './reportHelpers/helpers';
import rules from '../../../../vibe/components/utilities/MainUtils/rules';
import DistributionCard from './DistributionCard';
import popup from './reportHelpers/Popup.module.css';

const swalWithBootstrapButtons = Swal.mixin({
  buttonsStyling: false,
});

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const useStyles = makeStyles({
  dialog: {
    zIndex: '10001 !important',
  },
  autoPopper: {
    zIndex: '10001 !important',
  },
  paperGoods: {
    border: '1px solid #14141433',
    borderRadius: '4px !important',
    boxShadow: '0px !important',
    padding: '16px',
  },
  paperMain: {
    border: '1px solid #14141433',
    borderRadius: '4px !important',
    boxShadow: '0px !important',
    padding: '16px',
  },
  input: {
    borderBottom: '2px solid #141414',
    '& input.Mui-disabled': {
      backgroundColor: 'transparent !important',
    },
    '& label.Mui-disabled': {
      color: 'transparent',
    },
    '& .MuiInput-underline:hover:before': {
      border: 'none !important',
    },
  },
  actInput: {
    borderBottom: '2px solid #141414',
    '& input.Mui-disabled': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiInput-underline:hover:before': {
      border: 'none !important',
    },
  },
  disabledDate: {
    backgroundColor: 'transparent !important',
  },
  autoEnd: {
    color: '#141414',
  },
  autoCross: {
    color: '#141414',
  },
  cardTitle: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    textAlign: 'left',
    marginTop: 36,
  },
  pageTitle: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'left',
  },
  addBtn: {
    padding: '14px 24px',
    borderRadius: '30px',
    backgroundColor: '#D5D8E0',
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
  },
  formTtl: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    textAlign: 'left',
  },
});

const ReportDistribution = ({ history }) => {
  const [filteredAidtype, setFilteredAidtype] = React.useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  // params
  const curr_date = useSelector((state) => state.report.params.curr_date);
  const i_report = useSelector((state) => state.report.params.i_report);
  // address
  const region = useSelector((state) => state.catalogs.region);
  const locality = useSelector((state) => state.catalogs.locality);
  // fields
  const current = useSelector((state) => state.report.distribution.current);
  const cards = useSelector((state) => state.report.distribution.cards);
  // selects
  const aidtype = useSelector((state) => state.report.aidtype);
  const acquirer = useSelector((state) => state.report.acquirer);
  const aidtypeCatalog = useSelector((state) => state.catalogs.aidType);
  const i_recip = useSelector((state) => state.report.params.i_recip);
  // saver
  const distributionSaver = useSelector((state) => state.report.distributionSaver);

  const updateBothTables = () => {
    getReportData({ pd_rep: curr_date, pi_recip: i_recip })
      .then((res) => {
        // eslint-disable-next-line no-shadow
        const { balance, distrib, cargo: newCargo, aidtype, acquirer, general } = res.data;
        // eslint-disable-next-line no-shadow
        const { i_recip: Irecip, curr_date: currDate, min_date: minDateG, i_report } = general[0];
        dispatch(setReportParams({ i_recip: Irecip, curr_date: currDate, min_date: minDateG, i_report }));
        dispatch(setReportCargoList(newCargo));
        dispatch(setReportAidtype(aidtype));
        dispatch(setReportAcquirer(acquirer));
        dispatch(setBalanceArray(transformBalance(balance, newCargo)));
        dispatch(setDistributionArray(transformDistribution(distrib, acquirer, region)));
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {});
  };

  const gotoTable = () => {
    updateBothTables();
    dispatch(setReportTab(1));
    history.push('/create-report');
  };

  const dateToObject = (strDate) => {
    if (strDate) {
      const [day, month, year] = strDate.split('.');
      return new Date(+year, +month - 1, +day);
    }

    return null;
  };

  const handleCheck = (event) => {
    const { checked } = event.target;
    // saver
    if (distributionSaver) dispatch(setDistributionSaver(false));
    dispatch(setDistributionInput('pl_losses', checked));
  };

  const handleRadio = (event) => {
    const { value } = event.target;
    dispatch(setDistributionInput('pi_acq_type', value));
    dispatch(setDistributionInput('pc_orgname', value === '2' ? 'Фізичні особи' : ''));
    dispatch(setDistributionInput('pc_edrpou', ''));
    if (value === '2') dispatch(setDistributionInput('pl_losses', false));
    // saver
    if (distributionSaver) dispatch(setDistributionSaver(false));
  };

  const handleInput = (event) => {
    const { value, name } = event.target;

    const actInputs = ['pc_document', 'pc_orgname', 'pc_address'];

    if (actInputs.includes(name)) {
      if (distributionSaver) dispatch(setDistributionSaver(false));
    }

    if (name === 'pn_count' && current.pi_balance_obj && value) {
      const { n_koef } = current.pi_balance_obj;
      const result = (n_koef * Number(value)).toFixed(3);
      if (n_koef > 0) dispatch(setDistributionInput('pn_weight', result));
    } else if (name === 'pn_count' && value === '') {
      dispatch(setDistributionInput('pn_weight', ''));
    }

    dispatch(setDistributionInput(name, value));
  };

  const handleSelect = (name, option) => {
    if (name === 'region') {
      dispatch(setLocality([]));
      // saver
      if (distributionSaver) dispatch(setDistributionSaver(false));
      if (option) {
        dispatch(setDistributionInput('pi_region_obj', option));
      } else {
        dispatch(setDistributionInput('pi_region_obj', null));
        dispatch(setDistributionInput('pi_region', ''));
        dispatch(setDistributionInput('pi_atu_obj', null));
      }
    } else if (name === 'locality') {
      // saver
      if (distributionSaver) dispatch(setDistributionSaver(false));
      if (option) {
        dispatch(setDistributionInput('pi_atu_obj', option));
      } else {
        dispatch(setDistributionInput('pi_atu_obj', null));
      }
    } else if (name === 'aidtypeCatalogOption') {
      if (option) {
        dispatch(setDistributionInput('aidtypeCatalogOption', option));
        dispatch(setDistributionInput('pi_balance_obj', null));
        dispatch(setDistributionInput('pc_description', ''));
      } else {
        dispatch(setDistributionInput('aidtypeCatalogOption', null));
      }
    } else if (name === 'pi_balance_obj') {
      if (option) {
        dispatch(setDistributionInput('pi_balance_obj', option));
        dispatch(setDistributionInput('pc_description', option?.c_description));
      } else {
        dispatch(setDistributionInput('pi_balance_obj', null));
        dispatch(setDistributionInput('pc_description', ''));
      }
    } else if (name === 'edrpouOption') {
      if (option && typeof option === 'object') {
        const { c_orgname, i_region, i_locality, c_address } = option;
        dispatch(setDistributionInput('edrpouOption', option));
        dispatch(setDistributionInput('pc_orgname', c_orgname));
        dispatch(
          setDistributionInput(
            'pi_region_obj',
            region.find(({ value }) => value === i_region)
          )
        );
        dispatch(setDistributionInput('pi_atu', i_locality));
        dispatch(setDistributionInput('pc_address', c_address));
      } else if (option === null) {
        dispatch(setDistributionInput('edrpouOption', null));
        dispatch(setDistributionInput('pc_orgname', ''));
        dispatch(setDistributionInput('pi_region_obj', null));
        dispatch(setDistributionInput('pi_atu_obj', null));
        dispatch(setDistributionInput('pi_atu', ''));
        dispatch(setDistributionInput('pc_address', ''));
      }
    }
  };

  const handleSelectInput = (name, newInputValue) => {
    if (rules.edrpouInput.test(newInputValue)) {
      dispatch(setDistributionInput('pc_edrpou', newInputValue));
      // saver
      if (distributionSaver) dispatch(setDistributionSaver(false));
      // onblur
      if (newInputValue?.length === 8) {
        const found = acquirer.find(({ n_edrpou }) => n_edrpou === newInputValue) || null;
        if (found) {
          const { c_orgname, i_region, i_locality, c_address } = found;
          dispatch(setDistributionInput('edrpouOption', found));
          dispatch(setDistributionInput('pc_orgname', c_orgname));
          dispatch(
            setDistributionInput(
              'pi_region_obj',
              // eslint-disable-next-line no-shadow
              region.find(({ value }) => value === i_region)
            )
          );
          dispatch(setDistributionInput('pi_atu', i_locality));
          dispatch(setDistributionInput('pc_address', c_address));
        }
      }
    }
  };

  const handleOnblur = (e) => {
    // eslint-disable-next-line no-unused-vars
    const { name, value } = e.target;
    if (value?.length === 8) {
      const found = acquirer.find(({ n_edrpou }) => n_edrpou === value) || null;
      if (found) {
        const { c_orgname, i_region, i_locality, c_address } = found;
        dispatch(setDistributionInput('edrpouOption', found));
        dispatch(setDistributionInput('pc_orgname', c_orgname));
        dispatch(
          setDistributionInput(
            'pi_region_obj',
            // eslint-disable-next-line no-shadow
            region.find(({ value }) => value === i_region)
          )
        );
        dispatch(setDistributionInput('pi_atu', i_locality));
        dispatch(setDistributionInput('pc_address', c_address));
      }
    }
  };

  const updatePage = async (id_act) => {
    try {
      const res = await getDistribByAct({ pi_report: i_report, pi_act: id_act });
      const { aid_type, act, distribution, acquirer: acquirerData } = res.data;
      const distrArray = distribution || [];
      const acqurArray = acquirerData || [];

      const {
        i_act,
        i_acq_type,
        d_distribution,
        c_document,
        l_losses,
        n_edrpou,
        c_orgname,
        i_region,
        i_locality,
        c_address,
      } = act[0];

      const data = {
        pi_act: i_act,
        // act
        pi_acq_type: Number(i_acq_type).toString(),
        pd_distribut: d_distribution || '',
        pc_document: c_document || '',
        pl_losses: l_losses === 1 || l_losses === '1',
        edrpouOption: acqurArray.find((item) => item.n_edrpou === n_edrpou) || null,
        pc_edrpou: n_edrpou || '',
        pc_orgname: i_acq_type === 2 ? 'Фізичні особи' : c_orgname,
        //  address
        pi_region_obj: region.find(({ value }) => value === i_region),
        pi_atu: i_locality || '',
        pi_atu_obj: locality.filter(({ value }) => value === i_locality)[0] || null,
        pc_address: c_address || '',
        // card
        aidtypeCatalogOption: null,
        pi_balance_obj: null,
        pi_balance: '',
        pc_description: '',
        pn_weight: '',
        pn_count: '',
        pn_acqcnt: '',
      };

      await dispatch(setDistributionCurrent(data));
      await dispatch(setDistributionCards(distrArray));
      await dispatch(setReportAidtype(aid_type));
      await dispatch(setReportAcquirer(acqurArray));
      await dispatch(setDistributionSaver(true));
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  // act
  const saveAct = async () => {
    const { pi_act, pi_acq_type, pd_distribut, pc_document, pl_losses, pc_edrpou, pc_orgname, pi_atu_obj, pc_address } =
      current;

    const data = {
      pi_report: i_report,
      pi_act,
      pi_acq_type,
      pd_distribut,
      pc_document,
      pl_losses: pl_losses ? 1 : 0,
      pc_edrpou,
      pc_orgname,
      pi_atu: pi_atu_obj?.value || '',
      pc_address,
    };

    if (pi_act) {
      swalWithBootstrapButtons
        .fire({
          customClass: {
            popup: popup['swal-popup'],
            container: popup['swal-container'],
            title: [popup['swal-title'], popup['swal-title-m3']],
            htmlContainer: popup['swal-code'],
            confirmButton: popup['swal-custom-btn'],
            cancelButton: [popup['swal-custom-btn'], popup['swal-custom-btn-lt']],
            actions: popup['swal-actions'],
          },
          title: 'Ви бажаєте змінити дані набувача?',
          html: '<p style="color: #141414;font-family: e-Ukraine;font-size: 16px;font-weight: 300;line-height: 24px;text-align: center;margin: 0 0 24px">Зміни відображаться в усих доданих товарах у цьому вікні.</p><p style="color: #141414;font-family: e-Ukraine;font-size: 16px;font-weight: 300;line-height: 24px;text-align: center;">Щоб створити нового набувача -  поверніться до таблиці Розподілу та натисніть кнопку ДОДАТИ РОЗПОДІЛ</p>',
          showConfirmButton: true,
          showCancelButton: true,
          focusConfirm: true,
          focusCancel: false,
          reverseButtons: true,
          confirmButtonText: 'ЗМІНИТИ ДАНІ',
          cancelButtonText: 'ПОВЕРНУТИСЯ',
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const res = await saveDistribAct(data);
              const { i_act } = res.data;
              dispatch(setDistributionInput('pi_act', i_act));
              dispatch(setDistributionSaver(true));
              updatePage(i_act);
              Toast.fire({
                type: 'success',
                title: 'Дані збережено!',
              });
              // eslint-disable-next-line no-empty
            } catch (error) {}
          }
        })
        .catch((err) => {});
    } else {
      const res = await saveDistribAct(data);
      const { i_act } = res.data;
      dispatch(setDistributionInput('pi_act', i_act));
      dispatch(setDistributionSaver(true));
      updatePage(i_act);
      Toast.fire({
        type: 'success',
        title: 'Дані збережено!',
      });
    }
  };

  // card
  const saveDistribCard = async () => {
    const { pi_act, pi_balance_obj, pc_description, pn_count, pn_weight, pn_acqcnt } = current;

    const params = {
      pi_report: i_report,
      pi_act,
      pi_balance: pi_balance_obj?.id || null,
      pc_description,
      pn_weight,
      pn_count,
      pn_acqcnt,
    };

    try {
      await saveReportDistribution(params);
      await updatePage(pi_act);
      Toast.fire({
        type: 'success',
        title: 'Дані збережено!',
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  // datapicker
  const handleChangeDate = (dateObj) => {
    const dateForSet = dateObj ? format(dateObj, 'dd.MM.yyyy') : '';
    dispatch(setDistributionInput('pd_distribut', dateForSet));
    // saver
    if (distributionSaver) dispatch(setDistributionSaver(false));
  };

  React.useEffect(() => {
    setFilteredAidtype(aidtype);
  }, [aidtype]);

  React.useEffect(() => {
    if (current?.pi_balance_obj) {
      const option1 =
        aidtypeCatalog.find(({ value }) => Number(value) === Number(current?.pi_balance_obj?.i_category)) || null;
      dispatch(setDistributionInput('aidtypeCatalogOption', option1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current?.pi_balance_obj]);

  React.useEffect(() => {
    const filtered = aidtype.filter(
      ({ i_category }) => Number(i_category) === Number(current?.aidtypeCatalogOption?.value)
    );

    setFilteredAidtype(filtered || []);

    if (filtered.length === 1) {
      dispatch(setDistributionInput('pi_balance_obj', filtered[0]));
      dispatch(setDistributionInput('pc_description', filtered[0]?.c_description));
    }

    if (!current.aidtypeCatalogOption) {
      setFilteredAidtype(aidtype || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current?.aidtypeCatalogOption?.value]);

  React.useEffect(() => {
    const { pi_region_obj } = current;

    if (pi_region_obj?.value) {
      dispatch(localityAction(pi_region_obj?.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.pi_region_obj?.value]);

  React.useEffect(() => {
    const { pi_atu } = current;

    if (pi_atu && locality.length > 0) {
      const found = locality.filter(({ value }) => value === pi_atu)[0] || null;
      dispatch(setDistributionInput('pi_atu_obj', found));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.pi_atu, locality]);

  return (
    <Card>
      <CardContent style={{ backgroundColor: distributionSaver ? '#fff' : 'rgb(204, 204, 204)' }}>
        <Grid container spacing={5}>
          <Grid container item xs={12} sm={12}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
              <IconButton onClick={() => gotoTable()}>
                <BackIcon />
              </IconButton>
              <span className={classes.pageTitle}>Активний розподіл</span>
            </Box>
          </Grid>
          <Grid container item xs={12} sm={12} md={5} spacing={0} alignItems="flex-start">
            <Paper elevation={0} className={classes.paperMain}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" component="h2" className={classes.formTtl}>
                    Дані набувача
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="entity"
                      row
                      name="entity"
                      value={current.pi_acq_type}
                      onChange={handleRadio}
                    >
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="Юридична особа" />
                      <FormControlLabel value="2" control={<Radio color="primary" />} label="Фізичні особи" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {current.pi_acq_type && (
                  <Grid item xs={12}>
                    <DatePicker
                      selected={current.pd_distribut ? dateToObject(current.pd_distribut) : null}
                      onChange={handleChangeDate}
                      minDate={curr_date ? moment(dateToObject(curr_date)).startOf('month').toDate() : null}
                      maxDate={curr_date ? dateToObject(curr_date) : null}
                      dateFormat="dd-MM-yyyy"
                      locale={uk}
                      className="datePicker"
                      isClearable
                      customInput={
                        <TextField
                          label="Дата розподілу*"
                          InputProps={{
                            classes: {
                              disabled: classes.disabledDate,
                            },
                          }}
                          style={{
                            borderBottom: '2px solid #141414',
                          }}
                        />
                      }
                    />
                  </Grid>
                )}
                {current.pi_acq_type && (
                  <Grid item xs={12}>
                    <TextField
                      name="pc_document"
                      value={current.pc_document}
                      onChange={handleInput}
                      label="Підтверджуючі документи*"
                      className={classes.actInput}
                    />
                  </Grid>
                )}
                {current.pi_acq_type === '1' && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={current.pl_losses}
                          onChange={handleCheck}
                          name="pl_losses"
                          disabled={current.pi_acq_type === '2'}
                        />
                      }
                      label="Відмітити як втрати"
                    />
                  </Grid>
                )}

                {current.pi_acq_type === '1' && (
                  <Grid item xs={12}>
                    <Autocomplete
                      classes={{ popper: classes.autoPopper }}
                      freeSolo
                      value={acquirer.find(({ n_edrpou }) => n_edrpou === current?.edrpouOption?.n_edrpou) || null}
                      onChange={(event, option) => handleSelect('edrpouOption', option)}
                      inputValue={current?.pc_edrpou}
                      onInputChange={(event, newInputValue) => handleSelectInput('pc_edrpou', newInputValue)}
                      disabled={current.pi_acq_type === '2' || current.pl_losses}
                      id="edrpouSelect"
                      options={acquirer}
                      getOptionLabel={(option) => `${option.n_edrpou}`}
                      renderInput={(params) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          name="pc_edrpou"
                          label="Код ЄДРПОУ*"
                          className={classes.actInput}
                        />
                      )}
                    />
                  </Grid>
                )}
                {current.pi_acq_type === '1' && (
                  <Grid item xs={12}>
                    <TextField
                      disabled={current.pl_losses}
                      className={classes.actInput}
                      name="pc_orgname"
                      value={current.pc_orgname}
                      onChange={handleInput}
                      inputProps={{
                        readOnly: current.pi_acq_type === '2',
                      }}
                      label="Назва набувача*"
                    />
                  </Grid>
                )}
                {current.pi_acq_type && (
                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={current.pl_losses}
                      classes={{
                        popper: classes.autoPopper,
                        popupIndicator: classes.autoEnd,
                        clearIndicator: classes.autoCross,
                      }}
                      value={region.filter(({ value }) => value === current.pi_region_obj?.value)[0] || null}
                      onChange={(event, option) => handleSelect('region', option)}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      options={region}
                      renderInput={(params) => (
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label="Область"
                          className={classes.actInput}
                        />
                      )}
                    />
                  </Grid>
                )}
                {current.pi_acq_type && (
                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={current.pl_losses}
                      classes={{
                        popper: classes.autoPopper,
                        popupIndicator: classes.autoEnd,
                        clearIndicator: classes.autoCross,
                      }}
                      value={locality.filter(({ value }) => value === current.pi_atu_obj?.value)[0] || null}
                      onChange={(event, option) => handleSelect('locality', option)}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      options={locality}
                      //   disabled={!current.pi_region_obj?.value}
                      renderInput={(params) => (
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label="Населений пункт"
                          className={classes.actInput}
                        />
                      )}
                    />
                  </Grid>
                )}
                {current.pi_acq_type && (
                  <Grid item xs={12}>
                    <TextField
                      disabled={current.pl_losses}
                      className={classes.actInput}
                      multiline
                      minRows={1}
                      maxRows={3}
                      name="pc_address"
                      value={current.pc_address}
                      onChange={handleInput}
                      InputProps={{
                        inputProps: {
                          maxLength: 120,
                        },
                      }}
                      label="Адреса"
                    />
                  </Grid>
                )}
                {current.pi_acq_type && (
                  <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'start' }}>
                    <Button className={classes.addBtn} variant="contained" onClick={saveAct}>
                      Зберегти
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid container item xs={12} sm={12} md={7} spacing={2}>
            <Grid item xs={12}>
              <Paper
                elevation={0}
                className={classes.paperGoods}
                style={{ backgroundColor: distributionSaver ? '#FFFFFF' : 'rgba(204, 204, 204, 0.3)' }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="h2" className={classes.formTtl}>
                      Додати новий товар
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                      disabled={!distributionSaver}
                      classes={{
                        popper: classes.autoPopper,
                        popupIndicator: classes.autoEnd,
                        clearIndicator: classes.autoCross,
                      }}
                      value={
                        aidtypeCatalog.filter(
                          ({ value }) => Number(value) === Number(current.aidtypeCatalogOption?.value)
                        )[0] || null
                      }
                      onChange={(event, option) => handleSelect('aidtypeCatalogOption', option)}
                      autoHighlight
                      getOptionLabel={(option) => `${option?.label}`}
                      options={aidtypeCatalog}
                      renderInput={(params) => (
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label="Категорія товару"
                          className={classes.input}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                      disabled={!distributionSaver}
                      classes={{
                        popper: classes.autoPopper,
                        popupIndicator: classes.autoEnd,
                        clearIndicator: classes.autoCross,
                      }}
                      value={
                        filteredAidtype.filter(({ id }) => Number(id) === Number(current.pi_balance_obj?.id))[0] || null
                      }
                      onChange={(event, option) => handleSelect('pi_balance_obj', option)}
                      autoHighlight
                      getOptionLabel={(option) => `${option.c_name}`}
                      options={filteredAidtype}
                      renderInput={(params) => (
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label="Товар по коду гуманітарної допомоги"
                          className={classes.input}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      disabled={!distributionSaver}
                      className={classes.input}
                      name="pc_description"
                      value={current.pc_description}
                      onChange={handleInput}
                      label="Найменування товарів"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      disabled={!distributionSaver}
                      className={classes.input}
                      name="pn_count"
                      value={current.pn_count}
                      onChange={handleInput}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          decimalSeparator: '.',
                          decimalScale: 2,
                          maxLength: 10,
                        },
                      }}
                      label="Кількість, шт."
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      disabled={!distributionSaver}
                      className={classes.input}
                      name="pn_weight"
                      value={current.pn_weight}
                      onChange={handleInput}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          decimalSeparator: '.',
                          decimalScale: 3,
                          maxLength: 10,
                        },
                      }}
                      label="Вага, кг/Об'єм, л"
                    />
                  </Grid>
                  {current.pi_acq_type === '2' && (
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        name="pn_acqcnt"
                        className={classes.input}
                        value={current.pn_acqcnt}
                        onChange={handleInput}
                        disabled={current.pi_acq_type === '1' || !distributionSaver}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            readOnly: current.pi_acq_type === '1',
                            decimalSeparator: '.',
                            decimalScale: 0,
                            maxLength: 10,
                          },
                        }}
                        label="Кількість осіб"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'start' }}>
                    <Button
                      className={classes.addBtn}
                      variant="contained"
                      disabled={!distributionSaver}
                      onClick={saveDistribCard}
                    >
                      Додати
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {cards.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h5" component="h2" className={classes.cardTitle}>
                  Товари по поточному набувачу
                </Typography>
              </Grid>
            )}
            {cards.length > 0 &&
              cards.map((item, index) => (
                <Grid item xs={12} key={item?.i_distrib}>
                  <DistributionCard item={item} index={index} updatePage={updatePage} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReportDistribution;
