// packages
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Button, CardFooter } from 'reactstrap';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
// import { IconButton } from '@material-ui/core';
// import * as Feather from 'react-feather';
import { withStyles } from '@material-ui/core/styles';
// import BootstrapTooltip from '../../elements/MaterialBootstrap';
// сomponents
import ControlsRegisterRecipient from './ControlsRegisterRecipient';
import { recipientByIdNew } from '../../../redux/actions/recipient.action';

// utils
import isEmpty from '../../../vibe/components/utilities/MainUtils/is-empty';
import { selectRecipientItem } from '../../../redux/actions/recipient/recipient.selector';

const styles = {
  fontWeight: {
    fontWeight: 800,
    paddingLeft: '10px',
  },
  onLine: {
    display: 'flex',
  },
};

const directoryParse = (arr, val) => {
  if (!isEmpty(arr)) {
    const dir = arr.filter(({ value }) => value == val);
    return dir.length > 0 ? dir[0].label : '';
  }
};
class ViewRegisterRecipient extends Component {
  passToRegister = () => {
    this.props.history.goBack();
  };

  render() {
    const { recipientTypes } = this.props.catalog;
    const {
      p_recipient_type,
      p_agent_position,
      p_agent_surname,
      p_agent_name,
      p_agent_patronymic,
      p_agent_rnokpp,
      p_agent_code,
      p_country,
      p_reg_address,
    } = this.props.recipient;
    const {
      catalog: { country },
    } = this.props;
    const { classes } = this.props;
    const element = (
      <CardBody>
        <p className={classes.onLine}>
          № в Реєстрі: <b className={classes.fontWeight}>{this.props.recipient.p_number}</b>
        </p>
        <p className={classes.onLine}>
          Область:{' '}
          <b className={classes.fontWeight}>
            {directoryParse(this.props.catalog.region, this.props.recipient.p_region)}
          </b>
        </p>
        {/* <p className={classes.fontWeight}>
          Населений пункт: {directoryParse(this.props.catalog.locality, this.props.recipient.p_koatuu)}
        </p> */}
        <p className={classes.onLine}>
          Населений пункт: <b className={classes.fontWeight}>{this.props.recipient.p_locality_name}</b>
        </p>
        <p className={classes.onLine}>
          Місцезнаходження: <b className={classes.fontWeight}>{this.props.recipient.p_adress}</b>
        </p>
        <p className={classes.onLine}>
          Код ЄДРПОУ: <b className={classes.fontWeight}>{this.props.recipient.p_edrpou}</b>
        </p>
        <p className={classes.onLine}>
          Назва отримувача: <b className={classes.fontWeight}>{this.props.recipient.p_orgname}</b>
        </p>
        <p className={classes.onLine}>
          Тип отримувача:{' '}
          <b className={classes.fontWeight}>
            {recipientTypes.find((item) => Number(item.value) === Number(p_recipient_type))?.label || ''}
          </b>
        </p>
        <p className={classes.onLine}>
          ПІБ керівника: <b className={classes.fontWeight}>{this.props.recipient.p_surname} {this.props.recipient.p_name}{' '}
            {this.props.recipient.p_patronymic}</b>
        </p>
        {p_agent_position && (
          <p className={classes.onLine}>
            Посада уповноваженої особи: <b className={classes.fontWeight}>{p_agent_position}</b>
          </p>
        )}
        {p_agent_surname && (
          <p className={classes.onLine}>
            ПІБ уповноваженої особи:{' '}
            <b className={classes.fontWeight}>{`${p_agent_surname} ${p_agent_name} ${p_agent_patronymic}`}</b>
          </p>
        )}
        {p_agent_rnokpp && <p className={classes.onLine}>РНОКПП уповноваженої особи: <b className={classes.fontWeight}>{p_agent_rnokpp}</b></p>}
          {p_agent_code && <p className={classes.onLine}>Код уповноваженої особи: <b className={classes.fontWeight}>{p_agent_code}</b></p>}
        {p_country && (
          <p className={classes.onLine}>
            Країна реєстрації уповноваженої особи:{' '}
            <b className={classes.fontWeight}>{country.find(({ value }) => value === p_country)?.label || ''}</b>
          </p>
        )}
        {p_reg_address && (
          <p className={classes.onLine}>
            Місцезнаходження уповноваженої особи: <b className={classes.fontWeight}>{p_reg_address}</b>
          </p>
        )}
        <p className={classes.onLine}>
          Телефон(и): <b className={classes.fontWeight}>{this.props.recipient.p_phone}</b>
        </p>
        {this.props.recipient.p_phone2 && (
          <p className={classes.onLine}>
            Номер 2: <b className={classes.fontWeight}>{this.props.recipient.p_phone2}</b>
          </p>
        )}
        <p className={classes.onLine}>
          Електронна адреса: <b className={classes.fontWeight}>{this.props.recipient.p_email}</b>
        </p>
        <p className={classes.onLine}>
          Дата включення в Реєстр: <b className={classes.fontWeight}>{this.props.recipient.p_d_include}</b>
        </p>
        <p className={classes.onLine}>
          Дата виключення з Реєстру: <b className={classes.fontWeight}>{this.props.recipient.p_d_delete}</b>
        </p>
      </CardBody>
    );
    return (
      <div>
        <Card>
          <CardHeader>
            <ControlsRegisterRecipient
              id={this.props.recipientID}
              rescipientAppHistory={this.props.rescipientAppHistory}
              user={this.props.user}
              recipientByIdNew={this.props.recipientByIdNew}
              recipient={this.props.recipient}
            />
            <Divider />
          </CardHeader>

          {element}
          {/* <CardFooter>
          <BootstrapTooltip title="вихід">
            <IconButton onClick={this.passToRegister}>
              <Feather.LogOut style={{transform: 'scaleX(-1)'}} size='25' />
            </IconButton>
            </BootstrapTooltip>
          </CardFooter> */}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // recipient: state.recipient.recipient,
  recipient: selectRecipientItem(state),
  recipientID: state.recipient.tabsFlag.recipietnSaveID,
  catalog: state.catalogs,
  rescipientAppHistory: state.recipient.recipientAppHistory,
  user: state.auth.user,
});

export default connect(mapStateToProps, { recipientByIdNew })(withStyles(styles)(ViewRegisterRecipient));
