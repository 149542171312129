import axios from 'axios';

export const SendToServer = (username, password, sgn) => {
  const URL = '/aid/generate-token';
  // const nodeURL = '/token/generate-token'
  return axios({
    method: 'post',
    url: URL,
    data: {
      api: 'authApi',
      username,
      password,
      sign: sgn,
    },
  });
};

export const searchRecipientRegistr = (data) => axios.post(`/aid/recipientSearch`, data);

export const publicSearch = (data) => axios.post(`/aid/publicSearch`, data);

export const getControls = (data) => axios.post(`/aid/getControls`, data);

// --------------------------catalogs------------------------------------------------
export const getMenu = () => axios.get(`/aid/getmenu`);
export const getRegion = () => axios.get(`/aid/getRegion`);
export const getLocality = (region) => axios.get(`/aid/getLocality/${region}`);
export const applicationState = () => axios.get(`/aid/applicationState`);
export const applicationType = () => axios.get(`/aid/applicationType`);
export const acquirerType = () => axios.get(`/aid/acquirerType`);
export const aidCondition = () => axios.get(`/aid/aidCondition`);
export const aidForm = () => axios.get(`/aid/aidForm`);
export const aidType = () => axios.get(`/aid/aidType`);
export const country = () => axios.get(`/aid/country`);
export const getStatusList = () => axios.get(`/aid/getAplState`);
export const organization = () => axios.get(`/aid/organization`);
export const reciver = () => axios.get(`/aid/reciver`);
export const userState = () => axios.get(`/aid/userstate`);
export const meetingState = () => axios.get(`/aid/meetingstate`);
export const decisonType = () => axios.get(`/aid/decisontype`);
export const recipientTypes = () => axios.get(`/aid/getRecipientType`);
export const uktzed = () => axios.get(`/aid/uktzed`);
export const documentTypes1 = () => axios.get(`/aid/doctypes1`);
export const documentTypes2 = () => axios.get(`/aid/doctypes2`);
export const conReason = () => axios.get(`/aid/conReason`);
export const runit = () => axios.get(`/aid/runit`);
export const requestType = () => axios.get('/aid/getRequestType');
export const requestStatus = () => axios.get('/aid/getRequestStatus');
export const fuelTypes = () => axios.get('/aid/fuelTypes');

//---------------------------------------------------------------------------------

export const getNaisData = (edrpou) => axios.get(`/aid/getNaisData/${edrpou}`);
export const getNaisData2 = (recipientId) => axios.post(`/aid/getNaisData2`, { recipient: recipientId });

//---------------------------------------------------------------------------------

export const getRecipient = (id) => axios.get(`/aid/getRecipient/${id}`);
// export const getRecipientFull = (id) =>axios.get(`/aid/getRecipientFull/${id}`);
export const getRecipientFull = (id) => axios.get(`/aid/getRecipientFullold/${id}`);
export const getRecipientFullNew = (id) => axios.get(`/aid/getRecipientFull2/${id}`);
// export const getRecipientFullApr = (id, user_id) =>axios.get(`/aid/getRecipientFullApr/${id}/${user_id}`); //view for register
export const getRecipientHistory = (id) => axios.get(`/aid/ApplicationsByRecipient/${id}`);
export const getRecipients = (id) => axios.get(`/aid/getRecipients/${id}`);
export const deleteRecipient = (id) => axios.get(`/aid/delRecipient/${id}`);
export const saveRecipient = (data) => axios.post(`/aid/saveRecipient`, data);
export const saveRecipientOrd = (data) => axios.post(`/aid/saveRecipientOrd`, data);

export const applyApplication = (id) => axios.get(`/aid/apply/${id}`);
export const getAppsForCheck = () => axios.get('/aid/getAppsForCheck');
export const setCheckResult = (data) => axios.post(`/aid/setCheckResult`, data);

export const approveAuthority = (data) => axios.post(`/aid/approveAuthority`, data);
export const deniedAuthority = (data) => axios.post(`/aid/denieAuthority`, data);

export const controlForDeleteRecipientFromRegister = (user_id, recipient_id) =>
  axios.get(`/aid/statusrecip/${user_id}/${recipient_id}`);

export const deleteRecipientFromRegisterApi = (data) =>
  axios.post(`/aid/setNewApplication`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

// -------------documents recipient---------------
export const saveRecipientDocumentsApi = (info, data) =>
  axios.post(`/aid/docSave/${info.id}/${info.p_application}/${info.p_doctype}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const deleteRecipientDocumentsApi = (id) => axios.get(`/aid/delDocument/${id}`);
export const getRecipientDocumentsApi = (application_id) => axios.get(`/aid/docByApplication/${application_id}`);

export const getAprovedRecipients = (id) => axios.get(`/aid/ApprovedRecipient/${id}`);
export const applyAppRecipient = (data) => axios.post(`/aid/newApply`, data);
export const saveNewApply = (data) =>
  axios.post(`/aid/savenewApply`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
export const getRecipientsForJSON = () => axios.get(`/aid/getRecipientsForJSON`);

// export const setNewApplication = (data) =>axios.post(`/aid/setNewApplication`, data, {
//   headers: {
//       'Content-Type': 'application/json'
//   }
// });

export const setNewApplication = (data) =>
  axios.post(`/aid/appChangesforRecipient`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const setNewApplicationCargo = (data) =>
  axios.post(`/aid/applicationForChangeOrder`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

// -------------acts----------------
export const getActByApplicant = (filter, status) =>
  axios.post(`/aid/getActByApplicant`, { pc_filter: filter, pn_state: status });

// -------------cargo---------------
export const getCargo = (id) => axios.get(`/aid/getCargoByRecipient/${id}`);
export const deleteCargo = (id) => axios.get(`/aid/delCargo/${id}`);
export const deleteAqcuirer = (id) => axios.get(`/aid/delAcquirer/${id}`);
export const deleteAid = (id) => axios.get(`/aid/delAid/${id}`);
export const getCargoByIdFullFetchNewOrder = (id) => axios.get(`/aid/getCargoFull/${id}`);

// сopyAppCargo
export const copyAppCargo = (cargoId) => axios.post(`/aid/сopyAppCargo`, { pi_cargo: cargoId });

export const getCargoByIdFullFetch = (id) => axios.get(`/aid/getCargoFullRead/${id}`);
export const getCargoPublic = (id) => axios.get(`/aid/getCargoPublic/${id}`);
export const getCargoByIdFetch = (id) => axios.get(`/aid/getCargo/${id}`);
export const getMyCargo = (params) => axios.post(`/aid/cargobyApplicant`, params);

export const getAcquirerById = (id) => axios.get(`/aid/Acquirer/${id}`);

export const getAidCargo = (id) => axios.get(`/aid/getAidList/${id}`);

export const saveCargoAid = (data) =>
  axios.post(`/aid/saveAid`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const cargosetapply = (data) =>
  axios.post(`/aid/cargosetapply`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const saveCargoAquire = (data) =>
  axios.post(`/aid/saveAquire`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const saveCargo = (data) =>
  axios.post(`/aid/saveCargoDon`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const saveDistribution = (data) =>
  axios.post(`/aid/saveDistr`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getCargoByFilters = (data) =>
  axios.post(`/aid/getCargoByFilter`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const changeRecipientCargo = (data) =>
  axios.post(`/aid/changeRecipientInCargo`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
export const cargoApply = (data) =>
  axios.post(`/aid/setappstate`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const cargoSetApply = (data) =>
  axios.post(`/aid/cargosetapply`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getInventoryPDF = (id) => axios.get(`/aid/getInventoryPDF/${id}`, { responseType: 'blob' });
// export const cargoReportGetPDF = (id, date) => axios.get(`/aid/cargoReportGetPDF/${id}/${date}`, { responseType: 'blob' });
export const cargoReportGetPDF = (data) =>
  axios.post(`/aid/cargoReportGetPDF`, data, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const signInventory = (idCargo) => axios.get(`/aid/signInventory/${idCargo}`);
export const signReport = (data) =>
  axios.post(`/aid/signReport`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const downloadBalance = (reportId) =>
  axios.get(`/aid/downloadBalance/${reportId}/xlsx`, { responseType: 'blob' });
export const updateReport = (data) => axios.post(`/aid/updateReport`, data);
export const unsignReport = (id) => axios.post(`/aid/unsignReport`, { pi_report: id });
export const unsignInventory = (id) => axios.post(`/aid/unsignInventory`, { pi_cargo: id });

export const getPublicInventoryPDF = (id) => axios.get(`/aid/getPublicInventoryPDF/${id}`, { responseType: 'blob' });

export const getInventoryApplicant = () => axios.get(`/aid/getInventoryApplicant/`);
export const getReportsByUser = () => axios.get(`/aid/getReportsByUser/`);
export const getCargoReport = (id) => axios.get(`/aid/distributionAll/${id}`);

export const getNewReport = (id_rec) => axios.get(`/aid/getNewReport/${id_rec}`);
export const deleteReport = (id) => axios.get(`/aid/deleteReport/${id}`);
export const getReportData = (data) =>
  axios.post(`/aid/getReportData`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getRecipReports = (id) => axios.get(`/aid/getRecipReports/${id}`);

export const setReport = (id) => axios.get(`/aid/setreport/${id}`);
export const getViewReport = (id) => axios.get(`/aid/distributionSum/${id}`);

export const changeCargoRecipient = (user_id, cargo_id) => axios.get(`/aid/statuscargo/${user_id}/${cargo_id}`);

// -------------documents cargo---------------
export const saveCargoDocumentsApi = (info, data) =>
  axios.post(`/aid/docSave/${info.id}/${info.p_application}/${info.p_doctype}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const getСargoDocumentsApi = (application_id) => axios.get(`/aid/docByApplication/${application_id}`);

export const getApplForSaveDecl = (id) => axios.get(`/aid/getApplForSaveDecl/${id}`);
export const deleteCargoDocumentsApi = (id) => axios.get(`/aid/delDocument/${id}`);
export const recDoc = (id) => axios.get(`/aid/recDoc/${id}`);
export const cargDoc = (id) => axios.get(`/aid/cargDoc/${id}`);

export const cargoReport = (id) => axios.get(`/aid/cargoReport/${id}`, { responseType: 'blob' });

// ---- main docs in html --------

export const getOrderDoc = (id) => axios.get(`/aid/getOrder/${id}`);
export const getProtocolDoc = (id) => axios.get(`/aid/getProtocol/${id}`);

export const getTakePDFS = (idMeeting, idShablon) =>
  axios.get(`/aid/getTakePDFS/${idMeeting}/${idShablon}`, { responseType: 'blob' });
export const getTakeHtml = (idMeeting, idShablon) =>
  axios.get(`/aid/getTakeHtml/${idMeeting}/${idShablon}`, { responseType: 'blob' });
export const openModalInfoPDF = (idMeetig) => axios.get(`/aid/openModalInfoPDF/${idMeetig}`);

export const getOrderCargoDoc = (id) => axios.get(`/aid/getOrderCargo/${id}`);
export const getProtocolCargoDoc = (id) => axios.get(`/aid/getProtocolCargo/${id}`);

// export const getOrderCargoDoc = (id) =>axios.get(`/aid/getOrderCargo/${id}`);
// export const getProtocolCargoDoc = (id) =>axios.get(`/aid/getProtocol/${id}`);

// ---- commission --------

export const getCommissionsByFilters = (data) =>
  axios.post(`/aid/getmeeting`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const saveDecision = (data) =>
  axios.post(`/aid/saveDecision`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getNewMeetingInfo = (userId) => axios.get(`/aid/getnewmeeting/${userId}`);
export const getmeetingByRecipient = (id) => axios.get(`/aid/getmeetingByRecipient/${id}`);
export const fetchHistory = (id) => axios.get(`/aid/gethistory/${id}`);
export const getCargoForPublic = (id) => axios.get(`/aid/getCargoForPublic/${id}`);
export const getRecipientFullPublic = (id) => axios.get(`/aid/getRecipientFullPublic/${id}`);

export const getRecipientInfo = (id) => {
  return axios.all([getRecipientFullPublic(id), getmeetingByRecipient(id), fetchHistory(id), getCargoForPublic(id)]);
};

export const getDocCargoPublic = (id) => axios.get(`/aid/getDocCargoPublic/${id}`);

export const getmeetingByCargo = (id) => axios.get(`/aid/getmeetingByCargo/${id}`);
export const deleteMeeting = (id) => axios.get(`/aid/delmeeting/${id}`);

export const getApplicationForCommission = (meetingId) => axios.get(`/aid/getaplication/${meetingId}`);

export const getMyMeetingInfo = (userId) => axios.get(`/aid/getmeeting/${userId}`);
export const meetingResult = (params) => axios.post(`/aid/meetingAppListApr`, params);
export const getRecipientOldNew = (id) => axios.get(`/aid/getRecipientOldNew/${id}`);
// export const getCargoOldNew = (id) =>axios.get(`/aid/getCargoOldNew/${id}`);
export const getCargoOldNew = (id) => axios.get(`/aid/getCargoOldNew2/${id}`);
export const getMeetingList = (id, userId) => axios.get(`/aid/meetingAppList/${id}/${userId}`);
export const finishMeeting = (id, userId) => axios.get(`/aid/finishmeeting/${id}/${userId}`);

export const getDetailedView = (userId) => axios.get(`/aid/getDetailedView/${userId}`);

export const saveCurrentApplicationState = (id_meeting, id_app, state_check) =>
  axios.get(`/aid/agenda/${id_meeting}/${id_app}/${state_check}`);

export const saveAllApplicationState = (id_meeting, state_check) =>
  axios.get(`/aid/agendaAll/${id_meeting}/${state_check}`);

export const meetingStartApi = (id_meeting, id_user) => axios.get(`/aid/startmeeting/${id_meeting}/${id_user}`);

export const saveDataOrd = (data) =>
  axios.post(`/aid/saveDataOrd`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const endMeeting = (id_user, id_meeting) => axios.get(`/aid/endMeeting/${id_user}/${id_meeting}`);
export const orderSearch = (params) => axios.post(`/aid/orderSearch`, params);

// scans upload
export const getOrderTable = (meetingId) => axios.get(`/aid/orderTable/${meetingId}`);
export const getOrderTypes = (meetingId) => axios.get(`/aid/orderTypes/${meetingId}`);
export const deleteScan = (params) =>
  axios.post(`/aid/deleteScan`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const saveScan = (params) =>
  axios.post(`/aid/saveScan`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// download scan
export const downloadOrder = (id) =>
  axios.get(`/aid/downloadZipOrder/${id}`, {
    'Content-Type': 'application/zip',
    responseType: 'arraybuffer',
  });
export const downloadProtocol = (id) =>
  axios.get(`/aid/downloadZipProt/${id}`, {
    'Content-Type': 'application/zip',
    responseType: 'arraybuffer',
  });

// scan
export const getScanApi = (appl_id) => axios.get(`/aid/docByApplication/${appl_id}`);

// user access
export const accessState = () => axios.get('/aid/access');
export const switchAccess = () => axios.get('/aid/switchAccess');

// uktzed save
export const saveUktzed = (data) => axios.post(`/aid/saveUktzed`, data);
// declaration
export const saveDeclaration = (data) => axios.post(`/aid/saveDeclaration`, data);
export const getDeclaration = (appId, cargIg) => axios.get(`/aid/getDeclaration/${appId}/${cargIg}`);
export const getDeclaration2 = (cargIg) => axios.get(`/aid/getDeclaration2/${cargIg}`);
export const viewDeclaration = (cargId) => axios.get(`/aid/viewDeclaration/${cargId}`);
export const viewDeclarationForPublic = (cargId) => axios.get(`/aid/viewDeclarationForPublic/${cargId}`);

export const saveSign = (cargoId, formData) =>
  axios.post(`/aid/regManifest/${cargoId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

// /getZipManifest/{id}
export const downloadZip = (id) =>
  axios.get(`/aid/getZipManifest/${id}`, {
    'Content-Type': 'application/zip',
    responseType: 'blob',
  });

export const getPDFForCargo = (pi_cargo) => axios.get(`/aid/getPDFCargo/${pi_cargo}`);

// report
export const getReportByCargo = (idCargo) => axios.get(`/aid/getRepByCargo/${idCargo}`);
export const getReport = (id, date) => axios.post(`/aid/balanceReport/${id}?dateDoc=${date}`);
// balance
export const saveReportBalance = (data) => axios.post(`/aid/saveBalance`, data);
export const deleteReportBalance = (id) => axios.get(`/aid/deleteBalance/${id}`);
// distribution
export const getDocsReport = () => axios.get(`/aid/getTypeBalance`);
export const saveReportDoc = (params) =>
  axios.post(`/aid/saveDocBalance`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const getDistribByAct = (data) => axios.post(`/aid/getDistribByAct`, data);
export const saveDistribAct = (params) => axios.post('/aid/saveDistribAct', params);
export const saveReportDistribution = (data) => axios.post(`/aid/saveDistribution`, data);
export const deleteReportDistribution = (id) => axios.get(`/aid/deleteDistrub/${id}`);
export const uploadDistribution = (params) =>
  axios.post(`/aid/uploadDistribution`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// inventory
export const saveInventoryDoc = (params) =>
  axios.post(`/aid/saveDocInvent`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getDocByCargo = (group, id) => axios.get(`/aid/getDocByCargo/${group}/${id}`);
export const getDocBalance = (reportId) => axios.get(`/aid/getDocBalance/${reportId}`);

// /saveInventoryAid
export const saveInventoryAid = (data) => axios.post(`/aid/saveInventoryAid`, data);
export const deleteInventoryAid = (id) => axios.get(`/aid/deleteInventoryAid/${id}`);
export const saveInventoryDates = (data) => axios.post(`/aid/saveInventoryDates`, data);
export const getInventByCargo = (id) => axios.get(`/aid/getInventByCargo/${id}`);

// competency
export const competencyRequests = (params) => axios.post('/aid/competencyRequests', params);
export const editRequest = () => axios.get(`/aid/editRequest`);
export const getRecipientId = (id) => axios.get(`/aid/getRecipientId/${id}`);
export const getDocRequest = (id) => axios.get(`/aid/getDocRequest/${id}`);
export const saveDocRequest = (params) =>
  axios.post(`/aid/saveDocRequest`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const saveRequest = (data) => axios.post('/aid/saveRequest', data);
// competency view
export const getRequestAction = (id) => axios.get(`/aid/getRequestAction/${id}`);
export const saveRequestAction = (data) => axios.post('/aid/saveRequestAction', data);
export const sendNotification = () => axios.get(`/aid/sendNotification`);
