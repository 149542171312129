import {
  GET_RECIPIENT,
  SUCCESS_RECIPIENT,
  SUCCESS_RECIPIENTS,
  SUCCESS_RECIPIENTS_PAGE,
  SUCCESS_CONTROLS,
  RECIPIENT_REGISTERED,
  RECIPIENT_REGION,
  RECIPIENT_CITY,
  RECIPIENT_EDRPOU,
  RECIPIENT_TITLE,
  RECIPIENT_SURNAME,
  RECIPIENT_NAME,
  RECIPIENT_LASTNAME,
  RECIPIENT_RECIPIENT_TYPE,
  RECIPIENT_REPR_OCCUPATION,
  RECIPIENT_REPR_SURNAME,
  RECIPIENT_REPR_FIRSTNAME,
  RECIPIENT_REPR_PATRONYMIC,
  RECIPIENT_REPR_RNOKPP,
  RECIPIENT_REPR_CODE,
  RECIPIENT_REPR_COUNTRY,
  RECIPIENT_REPR_LOCALITY,
  RECIPIENT_PHONE,
  RECIPIENT_PHONE2,
  RECIPIENT_PHONE3,
  RECIPIENT_PHONE_MAX,
  RECIPIENT_EMAIL,
  RECIPIENT_ADDRESS,
  RECIPIENT_KOATUU,
  RECIPIENT_P_PRIVATE,
  // disabled
  RECIPIENT_PL_EDRPOU,
  RECIPIENT_PL_REGION,
  RECIPIENT_PL_LOCALITY,
  RECIPIENT_PL_ADRESS,
  RECIPIENT_PL_ORGNAME,
  RECIPIENT_PL_RECIPIENT_TYPE,
  RECIPIENT_PL_SURNAME,
  RECIPIENT_PL_NAME,
  RECIPIENT_PL_PATRONYMIC,
  RECIPIENT_EDITABLE,
  RECIPIENT_PL_GOT_OLFCODE,
  // other
  RECIPIENT_GET_DOCUMENTS,
  RECIPIENT_ID,
  RECIPIENT_CLEAR,
  SUCCESS_RECIPIENT_APPLICATIONS_HISTORY,
  SUCCESS_RECIPIENTS_SELECT,
  SUCCESS_RECIPIENT_SAVE,
  SUCCESS_RECIPIENT_DOCUMENTS,
  APPLICATION_ID,
  APPLICATION_CARGO_ID,
  CARGO_ID,
  AID_ID,
  RECIPIENTS_CLEAR,
  CONTROLS_CLEAR,
  RECIPIENT_NEW,
  RECIPIENT_OLD,
  RECIPIENT_NAIS,
  RECIPIENT_NEW_ID,
} from '../actions/action-types';

const initialState = {
  recipientState: {
    registered: false,
  },
  recipient: {
    p_user_role: '',
    p_edrpou: '',
    p_number: '',
    p_orgname: '',
    p_surname: '',
    p_name: '',
    p_patronymic: '',
    p_recipientType: '',
    //  representative
    p_reprOccupation: '',
    p_reprSurname: '',
    p_reprFirstname: '',
    p_reprPatronymic: '',
    p_agent_rnokpp: '',
    p_agent_code: '',
    p_reprCountry: '',
    p_reprLocality: '',
    p_koatuu: '',
    p_adress: '',
    p_phone: '',
    p_phone2: '',
    p_phone_max: 1,
    p_email: '',
    // disabled values
    pl_edrpou: false,
    pl_region: false,
    pl_locality: false,
    pl_adress: false,
    pl_orgname: false,
    pl_recipient_type: false,
    pl_surname: false,
    pl_name: false,
    pl_patronymic: false,
    // two additional
    editable: null,
    pl_got_olfcode: null,
    // other
    p_private: '',
    p_state: '',
    p_recipient_id: '',
    p_application_id: '',
    p_region: '',
    p_city: '',
    id: '',
    result: 0,
    p_order: '',
    p_dt_order: '',
    p_order_off: '',
    p_dt_order_off: '',
    p_order_cng: '',
    p_dt_order_cng: '',
  },
  recipients: {
    cursor: [],
    pn_page: 1,
    pn_rows: 10,
    total_rows: 0,
  },
  recipientsPage: '',
  controls: [],
  documents: [],
  tabsFlag: {
    recipietnSaveID: '',
    applicationSaveId: '',
    cargoSaveId: '',
    cargoAppSaveId: '',
    aidSaveId: '',
    p_new_application_id: '',
  },
  recipientAppHistory: [],
  selectRecipient: [],
  successText: '',
  documentsCargo: [],
  oldData: {},
  newData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RECIPIENT_NEW:
      return {
        ...state,
        newData: action.payload,
      };
    case RECIPIENT_OLD:
      return {
        ...state,
        oldData: action.payload,
      };
    case APPLICATION_CARGO_ID:
      return {
        ...state,
        tabsFlag: {
          ...state.tabsFlag,
          cargoAppSaveId: action.payload,
        },
      };
    case CARGO_ID:
      return {
        ...state,
        tabsFlag: {
          ...state.tabsFlag,
          cargoSaveId: action.payload,
        },
      };
    case AID_ID:
      return {
        ...state,
        tabsFlag: {
          ...state.tabsFlag,
          aidSaveId: action.payload,
        },
      };
    case SUCCESS_RECIPIENTS_SELECT:
      return {
        ...state,
        selectRecipient: action.payload,
      };
    case SUCCESS_RECIPIENT_SAVE:
      return {
        ...state,
        successText: action.payload,
      };
    case SUCCESS_RECIPIENT_APPLICATIONS_HISTORY:
      return {
        ...state,
        recipientAppHistory: action.payload,
      };
    case SUCCESS_RECIPIENT:
      return {
        ...state,
        recipient: action.payload,
      };
    case RECIPIENT_CLEAR:
      return {
        ...state,
        recipient: initialState.recipient,
        tabsFlag: initialState.tabsFlag,
        recipients: initialState.recipients,
        documents: initialState.documents,
        recipientAppHistory: initialState.recipientAppHistory,
        selectRecipient: initialState.selectRecipient,
        successText: initialState.successText,
        documentsCargo: initialState.documentsCargo,
      };
    case RECIPIENT_ID:
      return {
        ...state,
        tabsFlag: {
          ...state.tabsFlag,
          recipietnSaveID: action.payload,
        },
      };
    case APPLICATION_ID:
      return {
        ...state,
        tabsFlag: {
          ...state.tabsFlag,
          applicationSaveId: action.payload,
        },
      };
    case SUCCESS_RECIPIENT_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case RECIPIENT_REGION:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_region: action.payload,
        },
      };
    case RECIPIENT_CITY:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_city: action.payload.label,
        },
      };
    case RECIPIENT_KOATUU:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_koatuu: action.payload,
        },
      };
    case RECIPIENT_EDRPOU:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_edrpou: action.payload,
        },
      };
    case RECIPIENT_TITLE:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_orgname: action.payload,
        },
      };
    case RECIPIENT_SURNAME:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_surname: action.payload,
        },
      };
    case RECIPIENT_NAME:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_name: action.payload,
        },
      };
    case RECIPIENT_LASTNAME:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_patronymic: action.payload,
        },
      };
    case RECIPIENT_RECIPIENT_TYPE:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_recipientType: action.payload,
        },
      };
    case RECIPIENT_REPR_OCCUPATION:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_reprOccupation: action.payload,
        },
      };
    case RECIPIENT_REPR_SURNAME:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_reprSurname: action.payload,
        },
      };
    case RECIPIENT_REPR_FIRSTNAME:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_reprFirstname: action.payload,
        },
      };
    case RECIPIENT_REPR_PATRONYMIC:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_reprPatronymic: action.payload,
        },
      };
    case RECIPIENT_REPR_RNOKPP:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_agent_rnokpp: action.payload,
        },
      };
    case RECIPIENT_REPR_CODE:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_agent_code: action.payload,
        },
      };
    case RECIPIENT_REPR_COUNTRY:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_reprCountry: action.payload,
        },
      };
    case RECIPIENT_REPR_LOCALITY:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_reprLocality: action.payload,
        },
      };
    case RECIPIENT_PHONE:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_phone: action.payload,
        },
      };
    case RECIPIENT_PHONE2:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_phone2: action.payload,
        },
      };
    case RECIPIENT_PHONE3:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_phone3: action.payload,
        },
      };
    case RECIPIENT_PHONE_MAX:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_phone_max: action.payload,
        },
      };
    case RECIPIENT_NAIS: {
      const {
        p_region_nais,
        p_koatuu_nais,
        p_adress_nais,
        p_edrpou,
        p_orgname_nais,
        p_surname_nais,
        p_name_nais,
        p_patronymic_nais,
        p_phone_nais,
        p_phone2_nais,
        p_phone_max,
        p_email_nais,
        p_olf_code_nais,
      } = action.payload;

      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_region: p_region_nais,
          p_koatuu: p_koatuu_nais,
          p_adress: p_adress_nais,
          p_edrpou,
          p_orgname: p_orgname_nais,
          p_surname: p_surname_nais,
          p_name: p_name_nais,
          p_patronymic: p_patronymic_nais,
          p_phone: p_phone_nais,
          p_phone2: p_phone2_nais,
          p_phone_max,
          p_email: p_email_nais,
          p_recipientType: p_olf_code_nais,
        },
      };
    }
    case SUCCESS_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload,
      };
    case SUCCESS_RECIPIENTS_PAGE:
      return {
        ...state,
        recipientsPage: action.payload,
      };
    case SUCCESS_CONTROLS:
      return {
        ...state,
        controls: action.payload,
      };
    case RECIPIENTS_CLEAR:
      return {
        ...state,
        recipients: initialState.recipients,
      };
    case CONTROLS_CLEAR:
      return {
        ...state,
        controls: initialState.controls,
      };
    case RECIPIENT_EMAIL:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_email: action.payload,
        },
      };
    case RECIPIENT_PL_EDRPOU:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_edrpou: action.payload,
        },
      };
    case RECIPIENT_PL_REGION:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_region: action.payload,
        },
      };
    case RECIPIENT_PL_LOCALITY:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_locality: action.payload,
        },
      };
    case RECIPIENT_PL_ADRESS:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_adress: action.payload,
        },
      };
    case RECIPIENT_PL_ORGNAME:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_orgname: action.payload,
        },
      };
    case RECIPIENT_PL_RECIPIENT_TYPE:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_recipient_type: action.payload,
        },
      };
    case RECIPIENT_PL_SURNAME:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_surname: action.payload,
        },
      };
    case RECIPIENT_PL_NAME:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_name: action.payload,
        },
      };
    case RECIPIENT_PL_PATRONYMIC:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_patronymic: action.payload,
        },
      };
    case RECIPIENT_EDITABLE:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          editable: action.payload,
        },
      };
    case RECIPIENT_P_PRIVATE:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_private: action.payload,
        },
      };
    case RECIPIENT_PL_GOT_OLFCODE:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          pl_got_olfcode: action.payload,
        },
      };
    case 'RECIPIENT_ORDER':
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_order: action.payload,
        },
      };
    case 'RECIPIENT_DATE_ORDER':
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_dt_order: action.payload,
        },
      };
    case 'RECIPIENT_ORDER_OFF':
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_order_off: action.payload,
        },
      };
    case 'RECIPIENT_DATE_ORDER_OFF':
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_dt_order_off: action.payload,
        },
      };
    case RECIPIENT_ADDRESS:
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_adress: action.payload,
        },
      };
    case 'RECIPIENT_ORDER_CNG':
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_order_cng: action.payload,
        },
      };
    case 'RECIPIENT_DATE_CHANGES':
      return {
        ...state,
        recipient: {
          ...state.recipient,
          p_dt_order_cng: action.payload,
        },
      };
    case RECIPIENT_NEW_ID:
      return {
        ...state,
        tabsFlag: {
          ...state.tabsFlag,
          p_new_application_id: action.payload,
        },
      };
    case RECIPIENT_REGISTERED:
      return {
        ...state,
        recipientState: {
          registered: action.payload,
        },
      };

    default:
      return state;
  }
}
