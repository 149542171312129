import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Delete from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditAttributes from '@material-ui/icons/EditAttributes';
import SaveAlt from '@material-ui/icons/SaveAlt';
import AttachMoney from '@material-ui/icons/AttachMoney';
import LocalPostOffice from '@material-ui/icons/LocalPostOffice';
import Add from '@material-ui/icons/Add';
import { UncontrolledTooltip } from 'reactstrap';
import * as Feather from 'react-feather';
import Icon from '@material-ui/core/Icon';
import { loadCSS } from 'fg-loadcss';
import Toolbar from '@material-ui/core/Toolbar';
import NumberFormat from 'react-number-format';
import { Divider, Grid, Input, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';
import SimpleTable from './AcquirerTable';
import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import NumberFormatCustom from '../../../../vibe/components/inputs/NumberFormatCustom.component';
import save from '../../../../assets/images/save.png';
import deleteButton from '../../../../assets/images/icons=delete.png';

const directoryParse = (arr, val) => {
  if (!isEmpty(arr)) {
    const dir = arr.filter(({ value }) => parseInt(value) === parseInt(val));
    return dir.length > 0 ? dir[0].label : '';
  }
};

const useStyles = makeStyles((theme) => ({
  button_save: {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',

    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: 'auto',

    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    // display: 'flex',
    // alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',

    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  button_loading_img: {
    marginLeft: '15px',
  },
}));

// const styles = {
//   centered: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100%',
//   },
// };

const AddCargo = ({
  cargo,
  catalog,
  promiseOptions,
  promiseCode,
  item,
  index,
  deleteItem,
  deleteItemAcquirer,
  handleChange,
  handleChangeSelect,
  addAcquirer,
  toggle,
  editAquirer,
  saveAid,
  editAid,
}) => {
  React.useEffect(() => {
    loadCSS('https://use.fontawesome.com/releases/v5.1.0/css/all.css', document.querySelector('#font-awesome-css'));
  }, []);
  const classes = useStyles();

  return (
    <Paper style={{ padding: '15px', borderBottom: '#ddd' }}>
      {/* <Toolbar dense="true" key={index}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {Number(cargo.i_aidform) === 1 && (
            <span style={{ color: 'red', textAlign: 'center', fontSize: '14px' }}>
              Увага: опис вантажу має відповідати товаросупровідним документам, на підставі яких буде проводитись митне
              оформлення
            </span>
          )}
        </Grid>
        <Grid item xs={1}>
          {Number(cargo.i_aidform) === 1 && (
            <div className="col-sm-1  col-md-1 col-xl-1">
              <BootstrapTooltip title={directoryParse(catalog.aidForm, cargo.i_aidform)}>
                <ListItemIcon>
                  <Feather.Truck id={`tooltipIcon${index}`} />
                </ListItemIcon>
              </BootstrapTooltip>
            </div>
          )}
          {Number(cargo.i_aidform) === 3 && (
            <BootstrapTooltip title={directoryParse(catalog.aidForm, cargo.i_aidform)}>
              <ListItemIcon>
                <i className="fas fa-users-cog" style={{ fontSize: '22px' }} id={`tooltipIcon${index}`} />
                {/* <Icon className='fas fa-users-cog' /> */}
              </ListItemIcon>
            </BootstrapTooltip>
          )}
          {Number(cargo.i_aidform) === 2 && (
            <BootstrapTooltip title={directoryParse(catalog.aidForm, cargo.i_aidform)}>
              <ListItemIcon>
                <AttachMoney id={`tooltipIcon${index}`} />
              </ListItemIcon>
            </BootstrapTooltip>
          )}
          {Number(cargo.i_aidform) === 4 && (
            <div className="col-sm-1  col-md-1 col-xl-1">
              <BootstrapTooltip title={directoryParse(catalog.aidForm, cargo.i_aidform)}>
                <ListItemIcon>
                  <i className="fas fa-car" style={{ fontSize: '22px' }} id={`tooltipIcon${index}`} />
                </ListItemIcon>
              </BootstrapTooltip>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {Number(cargo.i_aidform) === 1 && (
              <>
                <Grid item md={6} xs={12}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`i_aidtype${index}`}>
                        Категорія вантажу
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={directoryParse(catalog.aidType, item.i_aidtype)} id={`i_aidtype${index}`} /> */}
                      <ListItemText
                        primary={directoryParse(catalog.aidType, item.i_aidtype)}
                        secondary={<Typography variant="caption">Категорія вантажу</Typography>}
                        id={`i_aidtype${index}`}
                      />
                    </>
                  ) : (
                    <>
                      {/* <Select
                        value={catalog.aidType.filter(({ value }) => parseInt(value) === parseInt(item.i_aidtype))}
                        onChange={(select) => handleChangeSelect(select, 'i_aidtype', index)}
                        options={catalog.aidType}
                        getOptionLabel={(option) => option.label}
                        styles={customStyles}
                        placeholder="Категорія вантажу"
                        noOptionsMessage={() => 'помилка серверу'}
                        menuPortalTarget={document.body}
                        theme={customTheme}
                        id={`i_aidtype${index}`}
                      /> */}

                      <Autocomplete
                        id={`i_aidtype${index}`}
                        value={catalog.aidType.filter(({ value }) => parseInt(value) === parseInt(item.i_aidtype))[0]}
                        onChange={(e, select) => handleChangeSelect(select, 'i_aidtype', index)}
                        autoHighlight
                        getOptionSelected={(option) => option.value === item.i_aidtype}
                        getOptionLabel={(option) => option.label}
                        options={catalog.aidType}
                        renderInput={(params) => (
                          <TextField
                            InputLabelProps={{ style: { fontSize: '12px' } }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Категорія вантажу"
                          />
                        )}
                      />

                      <UncontrolledTooltip placement="top" target={`i_aidtype${index}`}>
                        Категорія вантажу
                      </UncontrolledTooltip>
                    </>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_description1${index}`}>
                        Найменування вантажу
                      </UncontrolledTooltip>
                      <ListItemText
                        primary={item.c_description}
                        secondary={<Typography variant="caption">Найменування вантажу</Typography>}
                        id={`c_description1${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        InputLabelProps={{ style: { fontSize: '12px' } }}
                        value={item.c_description}
                        onChange={(e) => handleChange(e, item, index)}
                        name="c_description"
                        type="text"
                        id={`c_description1${index}`}
                        // maxLength="250"
                        label="Найменування вантажу"
                        fullWidth
                        inputProps={{
                          maxLength: 250,
                        }}
                      />

                      <UncontrolledTooltip placement="top" target={`c_description1${index}`}>
                        Найменування вантажу
                      </UncontrolledTooltip>
                    </>
                  )}
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {(Number(cargo.i_aidform) === 1 || Number(cargo.i_aidform) === 2 || Number(cargo.i_aidform) === 3) && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {(Number(cargo.i_aidform) === 2 || Number(cargo.i_aidform) === 3) && (
                    <Grid item xs={3}>
                      {!item.isEdit ? (
                        // <ListItemText primary={item.c_description} id={`c_description${index}`} />
                        <ListItemText
                          primary={item.c_description}
                          secondary={
                            <Typography variant="caption">
                              {Number(cargo.i_aidform) === 3
                                ? 'Опис (послуги)'
                                : Number(cargo.i_aidform) === 2
                                ? 'Опис (гроші)'
                                : ''}
                            </Typography>
                          }
                          id={`c_description1${index}`}
                        />
                      ) : (
                        <>
                          <TextField
                            InputLabelProps={{ style: { fontSize: '12px' } }}
                            value={item.c_description}
                            onChange={(e) => handleChange(e, item, index)}
                            name="c_description"
                            type="text"
                            id={`c_description${index}`}
                            // maxLength="250"
                            inputProps={{
                              maxLength: 250,
                            }}
                            label={
                              Number(cargo.i_aidform) === 3
                                ? 'Опис (послуги)'
                                : Number(cargo.i_aidform) === 2
                                ? 'Опис (гроші)'
                                : ''
                            }
                          />

                          <UncontrolledTooltip placement="top" target={`c_description${index}`}>
                            Опис
                          </UncontrolledTooltip>
                        </>
                      )}
                    </Grid>
                  )}
                  <Grid item md={3} xs={3}>
                    {!item.isEdit ? (
                      <>
                        <UncontrolledTooltip placement="top" target={`n_count${index}`}>
                          Кількість
                        </UncontrolledTooltip>
                        {/* <ListItemText primary={item.n_count} id={`n_count${index}`} /> */}
                        <ListItemText
                          primary={item.n_count}
                          secondary={<Typography variant="caption">Кількість</Typography>}
                          id={`n_count${index}`}
                        />
                      </>
                    ) : (
                      <>
                        <BootstrapTooltip title=" Введіть кількість допомоги. Дозволено ведення цифр та символу . ">
                          <TextField
                            InputLabelProps={{ style: { fontSize: '12px' } }}
                            label="Кількість"
                            value={item.n_count}
                            onChange={(e) => handleChange(e.target.value, 'n_count', index)}
                            name="n_count"
                            id={`n_count${index}`}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              inputProps: {
                                readOnly: Number(item.i_aidtype) === 6,
                                decimalSeparator: '.',
                                decimalScale: 0,
                              },
                            }}
                          />
                        </BootstrapTooltip>
                      </>
                    )}
                  </Grid>
                  <Grid item md={3} xs={9}>
                    {!item.isEdit ? (
                      <>
                        <UncontrolledTooltip placement="top" target={`i_unit2${index}`}>
                          Одиниця виміру
                        </UncontrolledTooltip>
                        <ListItemText
                          primary={directoryParse(catalog.runit, item.i_unit2)}
                          secondary={<Typography variant="caption">Одиниця виміру</Typography>}
                          id={`i_unit2${index}`}
                        />
                      </>
                    ) : (
                      <>
                        <Autocomplete
                          id={`i_unit2${index}`}
                          value={catalog.runit.filter(({ value }) => value === item.i_unit2)[0] || null}
                          onChange={(e, select) => handleChangeSelect(select, 'i_unit2', index)}
                          autoHighlight
                          // getOptionSelected={(option) => option.value === item.i_unit2}
                          getOptionLabel={(option) => option.label}
                          options={catalog.runit}
                          renderInput={(params) => (
                            <TextField
                              InputLabelProps={{ style: { fontSize: '12px' } }}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                              label="Одиниця виміру"
                            />
                          )}
                        />

                        <UncontrolledTooltip placement="top" target={`i_unit2${index}`}>
                          Одиниця виміру
                        </UncontrolledTooltip>
                      </>
                    )}
                  </Grid>
                  {/* <Grid item xs={3}>
                    {!item.isEdit ? (
                      <>
                        <UncontrolledTooltip placement="top" target={`i_unit${index}`}>
                          Одиниця виміру
                        </UncontrolledTooltip>
                        <ListItemText
                          primary={item.i_unit}
                          secondary={<Typography variant="caption">Одиниця виміру</Typography>}
                          id={`i_unit${index}`}
                        />
                      </>
                    ) : (
                      <>
                        <BootstrapTooltip title=" Введіть назву одиниці виміру допомоги в залежності від її типу. Дозволено ведення літер українською мовою та символів Ʌ№””.’,-/">
                          <TextField
                            InputLabelProps={{ style: { fontSize: '12px' } }}
                            value={item.i_unit}
                            onChange={(e) => handleChange(e, item, index)}
                            name="i_unit"
                            type="text"
                            label="Одиниця виміру"
                            id={`i_unit${index}`}
                            // maxLength="40"
                            inputProps={{
                              maxLength: 40,
                            }}
                          />
                        </BootstrapTooltip>
                      </>
                    )}
                  </Grid> */}
                  <Grid item md={3} xs={6}>
                    {!item.isEdit ? (
                      <>
                        <UncontrolledTooltip placement="top" target={`n_weight${index}`}>
                          Вага, кг
                        </UncontrolledTooltip>
                        {/* <ListItemText primary={item.n_weight} id={`n_weight${index}`} /> */}
                        <ListItemText
                          primary={item.n_weight}
                          secondary={<Typography variant="caption">Вага, кг</Typography>}
                          id={`n_weight${index}`}
                        />
                      </>
                    ) : (
                      <>
                        <BootstrapTooltip title=" Введіть вагу. Дозволено ведення цифр та символу . ">
                          <TextField
                            InputLabelProps={{ style: { fontSize: '12px' } }}
                            label="Вага, кг"
                            value={item.n_weight}
                            onChange={(e) => handleChange(e.target.value, 'n_weight', index)}
                            name="n_weight"
                            id={`n_weight${index}`}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              inputProps: {
                                decimalSeparator: '.',
                                decimalScale: 3,
                                maxLength: 10,
                              },
                            }}
                          />
                        </BootstrapTooltip>
                      </>
                    )}
                  </Grid>
                  <Grid item md={3} xs={6}>
                    {!item.isEdit ? (
                      <>
                        <UncontrolledTooltip placement="top" target={`n_sum${index}`}>
                          Кількість місць
                        </UncontrolledTooltip>
                        <ListItemText
                          primary={item.n_sum}
                          secondary={<Typography variant="caption">Кількість місць</Typography>}
                          id={`n_sum${index}`}
                        />
                      </>
                    ) : (
                      <>
                        <BootstrapTooltip title=" Введіть кількість місць. Дозволено ведення цифр та символу . ">
                          <TextField
                            InputLabelProps={{ style: { fontSize: '12px' } }}
                            label="Кількість місць"
                            value={item.n_sum}
                            onChange={(e) => handleChange(e.target.value, 'n_sum', index)}
                            name="n_sum"
                            id={`n_sum${index}`}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              inputProps: {
                                readOnly: Number(item.i_aidtype) === 6,
                                decimalSeparator: '.',
                                decimalScale: 0,
                                maxLength: 10,
                              },
                            }}
                          />
                        </BootstrapTooltip>
                      </>
                    )}
                  </Grid>
                </Grid>
                {Number(item.i_aidtype) === 6 && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={3} xs={12}>
                        {!item.isEdit ? (
                          <>
                            {/* <ListItemText primary={'Ідентифікаційний номер транспортного засобу'} /> */}
                            <>
                              <UncontrolledTooltip placement="top" target={`c_vin${index}`}>
                                VIN код (Ідентифікаційний номер транспортного засобу)
                              </UncontrolledTooltip>
                              <ListItemText
                                primary={item.c_vin}
                                secondary={
                                  <Typography variant="caption">
                                    VIN код (Ідентифікаційний номер транспортного засобу)
                                  </Typography>
                                }
                                id={`c_vin${index}`}
                              />
                            </>
                          </>
                        ) : (
                          <ListItemText
                            primary={
                              <BootstrapTooltip title="Введіть VIN код (Ідентифікаційний номер транспортного засобу).">
                                <TextField
                                  InputLabelProps={{ style: { fontSize: '12px' } }}
                                  value={item.c_vin}
                                  onChange={(e) => handleChange(e, item, index)}
                                  name="c_vin"
                                  type="text"
                                  label="VIN код (Ідентифікаційний номер транспортного засобу)"
                                  id={`c_vin${index}`}
                                  maxLength="20"
                                />
                              </BootstrapTooltip>
                            }
                          />
                        )}
                      </Grid>
                      <Grid item md={3} xs={12}>
                        {!item.isEdit ? (
                          <>
                            {/* <ListItemText primary={'Марка'} /> */}
                            <UncontrolledTooltip placement="top" target={`c_brand${index}`}>
                              Марка
                            </UncontrolledTooltip>
                            <ListItemText
                              primary={item.c_brand}
                              secondary={<Typography variant="caption">Марка</Typography>}
                              id={`c_brand${index}`}
                            />
                          </>
                        ) : (
                          <ListItemText
                            primary={
                              <BootstrapTooltip title="Введіть марку транспортного засобу.">
                                <TextField
                                  InputLabelProps={{ style: { fontSize: '12px' } }}
                                  value={item.c_brand}
                                  onChange={(e) => handleChange(e, item, index)}
                                  name="c_brand"
                                  type="text"
                                  label="Марка"
                                  id={`c_brand${index}`}
                                  maxLength="20"
                                />
                              </BootstrapTooltip>
                            }
                          />
                        )}
                      </Grid>
                      <Grid item md={3} xs={12}>
                        {!item.isEdit ? (
                          <>
                            <UncontrolledTooltip placement="top" target={`c_vehicle_model${index}`}>
                              Модель
                            </UncontrolledTooltip>
                            <ListItemText
                              primary={item.c_vehicle_model}
                              secondary={<Typography variant="caption">Модель</Typography>}
                              id={`c_vehicle_model${index}`}
                            />
                          </>
                        ) : (
                          <>
                            <BootstrapTooltip title="Введіть модель.">
                              <TextField
                                InputLabelProps={{ style: { fontSize: '12px' } }}
                                value={item.c_vehicle_model}
                                onChange={(e) => handleChange(e, item, index)}
                                name="c_vehicle_model"
                                type="text"
                                label="Модель"
                                id={`c_vehicle_model${index}`}
                                inputProps={{
                                  maxLength: 20,
                                }}
                              />
                            </BootstrapTooltip>
                          </>
                        )}
                      </Grid>
                      <Grid item md={3} xs={12}>
                        {!item.isEdit ? (
                          <>
                            {/* <ListItemText primary={'Комерційний опис'} /> */}
                            <UncontrolledTooltip placement="top" target={`c_specification${index}`}>
                              Комерційний опис (D.3 в тех паспорті)
                            </UncontrolledTooltip>
                            <ListItemText
                              primary={item.c_specification}
                              secondary={
                                <Typography variant="caption">Комерційний опис (D.3 в тех паспорті)</Typography>
                              }
                              id={`c_specification${index}`}
                            />
                          </>
                        ) : (
                          <ListItemText
                            primary={
                              <BootstrapTooltip title="Введіть комерційний опис  (D.3 в тех паспорті).">
                                <TextField
                                  InputLabelProps={{ style: { fontSize: '12px' } }}
                                  value={item.c_specification}
                                  onChange={(e) => handleChange(e, item, index)}
                                  name="c_specification"
                                  type="text"
                                  label="Комерційний опис (D.3 в тех паспорті)"
                                  id={`c_specification${index}`}
                                  maxLength="20"
                                />
                              </BootstrapTooltip>
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {Number(item.i_aidtype) === 6 && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={4} xs={12} style={{ paddingTop: '12px' }}>
                        {/* <div className="col-sm-12 col-md-6 col-xl-4"> */}
                        {!item.isEdit ? (
                          <>
                            {/* <ListItemText primary={'Тип двигуна'} /> */}
                            <UncontrolledTooltip placement="top" target={`c_engine${index}`}>
                              Тип двигуна
                            </UncontrolledTooltip>
                            <ListItemText
                              // primary={item.c_engine}
                              primary={directoryParse(catalog.fuelTypes, item.c_engine)}
                              secondary={<Typography variant="caption">Тип двигуна</Typography>}
                              id={`c_engine${index}`}
                            />
                          </>
                        ) : (
                          <>
                            <Autocomplete
                              id={`c_engine${index}`}
                              value={catalog.fuelTypes.filter(({ value }) => value === item.c_engine)[0]}
                              onChange={(e, select) => handleChangeSelect(select, 'c_engine', index)}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              options={catalog.fuelTypes}
                              renderInput={(params) => (
                                <TextField
                                  InputLabelProps={{ style: { fontSize: '12px' } }}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...params}
                                  label="Тип двигуна"
                                />
                              )}
                            />

                            <UncontrolledTooltip placement="top" target={`c_engine${index}`}>
                              Тип двигуна
                            </UncontrolledTooltip>
                          </>
                        )}
                        {/* </div> */}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        {!item.isEdit ? (
                          <>
                            {/* <ListItemText primary={'Об’єм двигуна, см-3'} /> */}
                            <UncontrolledTooltip placement="top" target={`n_capacity${index}`}>
                              Об’єм двигуна, см-3 (1л = 1000см-3)
                            </UncontrolledTooltip>
                            <ListItemText
                              primary={item.n_capacity}
                              secondary={<Typography variant="caption">Об’єм двигуна, см-3 (1л = 1000см-3)</Typography>}
                              id={`n_capacity${index}`}
                            />
                          </>
                        ) : (
                          <ListItemText
                            primary={
                              <BootstrapTooltip title="Введіть об’єм двигуна, см-3 (1л = 1000см-3).">
                                <TextField
                                  InputLabelProps={{ style: { fontSize: '12px' } }}
                                  label="Об’єм двигуна, см-3 (1л = 1000см-3)"
                                  value={item.n_capacity}
                                  onChange={(e) => handleChange(e.target.value, 'n_capacity', index)}
                                  name="n_capacity"
                                  id={`n_capacity${index}`}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    inputProps: {
                                      decimalSeparator: '.',
                                      decimalScale: 0,
                                    },
                                  }}
                                />
                              </BootstrapTooltip>
                            }
                          />
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        {/* <div className="col-sm-12 col-md-6 col-xl-4"> */}
                        {!item.isEdit ? (
                          <>
                            {/* <ListItemText primary={'Рік випуску'} /> */}
                            <UncontrolledTooltip placement="top" target={`n_comm_year${index}`}>
                              Рік випуску
                            </UncontrolledTooltip>
                            <ListItemText
                              primary={item.n_comm_year}
                              secondary={<Typography variant="caption">Рік випуску</Typography>}
                              id={`n_comm_year${index}`}
                            />
                          </>
                        ) : (
                          <ListItemText
                            primary={
                              <BootstrapTooltip title="Введіть рік випуску.">
                                <TextField
                                  InputLabelProps={{ style: { fontSize: '12px' } }}
                                  label="Рік випуску"
                                  value={item.n_comm_year}
                                  onChange={(e) => handleChange(e.target.value, 'n_comm_year', index)}
                                  name="n_comm_year"
                                  id={`n_comm_year${index}`}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    inputProps: {
                                      decimalSeparator: '.',
                                      decimalScale: 0,
                                      maxLength: 4,
                                    },
                                  }}
                                />
                              </BootstrapTooltip>
                            }
                          />
                        )}
                        {/* </div> */}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          {Number(cargo.i_aidform) === 4 && ( // 4
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {!item.isEdit ? (
                    // <ListItemText
                    //   primary={directoryParse(catalog.aidCondition, item.i_aidcondition)}
                    //   id={`i_aidcondition${index}`}
                    // />
                    <ListItemText
                      primary={directoryParse(catalog.aidCondition, item.i_aidcondition)}
                      secondary={<Typography variant="caption">Стан</Typography>}
                      id={`i_aidcondition${index}`}
                    />
                  ) : (
                    <>
                      {/* <Select
                          value={catalog.aidCondition.filter(
                            ({ value }) => parseInt(value) === parseInt(item.i_aidcondition)
                          )}
                          onChange={(select) => handleChangeSelect(select, 'i_aidcondition', index)}
                          options={catalog.aidCondition}
                          styles={customStyles}
                          placeholder="Стан"
                          // isMulti
                          noOptionsMessage={() => 'помилка серверу'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                          id={`i_aidcondition${index}`}
                        /> */}

                      <Autocomplete
                        id={`i_aidcondition${index}`}
                        value={
                          catalog.aidCondition.filter(
                            ({ value }) => parseInt(value) === parseInt(item.i_aidcondition)
                          )[0]
                        }
                        onChange={(e, select) => handleChangeSelect(select, 'i_aidcondition', index)}
                        autoHighlight
                        getOptionSelected={(option) => option.value === item.i_aidcondition}
                        getOptionLabel={(option) => option.label}
                        options={catalog.aidCondition}
                        renderInput={(params) => (
                          <TextField InputLabelProps={{ style: { fontSize: '12px' } }} {...params} label="Стан" />
                        )}
                      />

                      <UncontrolledTooltip placement="top" target={`i_aidcondition${index}`}>
                        Стан
                      </UncontrolledTooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_first_registration${index}`}>
                        Рік виробництва
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_first_registration} id={`c_first_registration${index}`} /> */}
                      <ListItemText
                        primary={item.c_first_registration}
                        secondary={<Typography variant="caption">Рік виробництва</Typography>}
                        id={`c_first_registration${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть рік виробництва.">
                        {/* <NumberFormat
                            required
                            value={item.c_first_registration}
                            decimalSeparator="."
                            decimalScale={0}
                            onValueChange={(values) => {
                              const { formattedValue } = values;
                              handleChange(formattedValue, 'c_first_registration', index);
                            }}
                            placeholder="Рік виробництва"
                            id={`c_first_registration${index}`}
                            maxLength="4"
                          /> */}
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          label="Рік виробництва"
                          value={item.c_first_registration}
                          onChange={(e) => handleChange(e.target.value, 'c_first_registration', index)}
                          name="n_count"
                          id={`c_first_registration${index}`}
                          // inputProps={{ maxLength: 12 }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            inputProps: {
                              decimalSeparator: '.',
                              decimalScale: 0,
                              maxLength: 4,
                            },
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`n_year_use_start${index}`}>
                        Рік введення в експлуатацію
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.n_year_use_start} id={`n_year_use_start${index}`} /> */}
                      <ListItemText
                        primary={item.n_year_use_start}
                        secondary={<Typography variant="caption">Рік введення в експлуатацію</Typography>}
                        id={`n_year_use_start${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title=" Введіть рік введення в експлуатацію.">
                        {/* <NumberFormat
                            // required
                            value={item.n_year_use_start}
                            decimalSeparator="."
                            decimalScale={0}
                            onValueChange={(values) => {
                              const { formattedValue } = values;
                              handleChange(formattedValue, 'n_year_use_start', index);
                            }}
                            // name="n_year_use_start"
                            // type="text"
                            placeholder="Рік введення в експлуатацію"
                            id={`n_year_use_start${index}`}
                            maxLength="4"
                          /> */}

                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          label="Рік введення в експлуатацію"
                          value={item.n_year_use_start}
                          onChange={(e) => handleChange(e.target.value, 'n_year_use_start', index)}
                          name="n_year_use_start"
                          id={`n_year_use_start${index}`}
                          // inputProps={{ maxLength: 12 }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            inputProps: {
                              decimalSeparator: '.',
                              decimalScale: 0,
                              maxLength: 4,
                            },
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>

                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_vehicle_type${index}`}>
                        Вид (за призначенням, за використанням енергії)
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_vehicle_type} id={`c_vehicle_type${index}`} /> */}
                      <ListItemText
                        primary={item.c_vehicle_type}
                        secondary={
                          <Typography variant="caption">Вид (за призначенням, за використанням енергії)</Typography>
                        }
                        id={`c_vehicle_type${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть вид (за призначенням, за використанням енергії).">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          required
                          value={item.c_vehicle_type}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_vehicle_type"
                          type="text"
                          label="Вид (за призначенням, за використанням енергії)"
                          id={`c_vehicle_type${index}`}
                          // maxLength="50"
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>

                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_vehicle_category${index}`}>
                        Категорія за класом
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_vehicle_category} id={`c_vehicle_category${index}`} /> */}
                      <ListItemText
                        primary={item.c_vehicle_category}
                        secondary={<Typography variant="caption">Категорія за класом</Typography>}
                        id={`c_vehicle_category${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть категорію за класом.">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          value={item.c_vehicle_category}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_vehicle_category"
                          type="text"
                          label="Категорія за класом"
                          id={`c_vehicle_category${index}`}
                          // maxLength="20"
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>

                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_vehicle_brand${index}`}>
                        Марка
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_vehicle_brand} id={`c_vehicle_brand${index}`} /> */}
                      <ListItemText
                        primary={item.c_vehicle_brand}
                        secondary={<Typography variant="caption">Марка</Typography>}
                        id={`c_vehicle_brand${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть марку.">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          value={item.c_vehicle_brand}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_vehicle_brand"
                          type="text"
                          label="Марка"
                          id={`c_vehicle_brand${index}`}
                          // maxLength="20"
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_vehicle_model${index}`}>
                        Модель
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_vehicle_model} id={`c_vehicle_model${index}`} /> */}
                      <ListItemText
                        primary={item.c_vehicle_model}
                        secondary={<Typography variant="caption">Модель</Typography>}
                        id={`c_vehicle_model${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть модель.">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          value={item.c_vehicle_model}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_vehicle_model"
                          type="text"
                          label="Модель"
                          id={`c_vehicle_model${index}`}
                          // maxLength="20"
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_vehicle_num${index}`}>
                        Номерний знак
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_vehicle_num} id={`c_vehicle_num${index}`} /> */}
                      <ListItemText
                        primary={item.c_vehicle_num}
                        secondary={<Typography variant="caption">Номерний знак</Typography>}
                        id={`c_vehicle_num${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть номерний знак.">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          value={item.c_vehicle_num}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_vehicle_num"
                          type="text"
                          label="Номерний знак"
                          id={`c_vehicle_num${index}`}
                          // maxLength="20"
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_vehicle_engine_num${index}`}>
                        Номер двигуна
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_vehicle_engine_num} id={`c_vehicle_engine_num${index}`} /> */}
                      <ListItemText
                        primary={item.c_vehicle_engine_num}
                        secondary={<Typography variant="caption">Номер двигуна</Typography>}
                        id={`c_vehicle_engine_num${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть номер двигуна.">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          value={item.c_vehicle_engine_num}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_vehicle_engine_num"
                          type="text"
                          label="Номер двигуна"
                          id={`c_vehicle_engine_num${index}`}
                          // maxLength="20"
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_vehicle_VIN_code${index}`}>
                        VIN-код
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_vehicle_VIN_code} id={`c_vehicle_VIN_code${index}`} /> */}
                      <ListItemText
                        primary={item.c_vehicle_VIN_code}
                        secondary={<Typography variant="caption">VIN-код</Typography>}
                        id={`c_vehicle_VIN_code${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть VIN-код.">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          value={item.c_vehicle_VIN_code}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_vehicle_VIN_code"
                          type="text"
                          label="VIN-код"
                          id={`c_vehicle_VIN_code${index}`}
                          // maxLength="20"
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>

                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_truckbody_num${index}`}>
                        Номер кузова
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_truckbody_num} id={`c_truckbody_num${index}`} /> */}
                      <ListItemText
                        primary={item.c_truckbody_num}
                        secondary={<Typography variant="caption">Номер кузова</Typography>}
                        id={`c_truckbody_num${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть номер кузова.">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          value={item.c_truckbody_num}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_truckbody_num"
                          type="text"
                          label="Номер кузова"
                          id={`c_truckbody_num${index}`}
                          // maxLength="20"
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_chassis_num${index}`}>
                        Номер шасі (рами)
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_chassis_num} id={`c_chassis_num${index}`} /> */}
                      <ListItemText
                        primary={item.c_chassis_num}
                        secondary={<Typography variant="caption"> Номер шасі (рами)</Typography>}
                        id={`c_chassis_num${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть номер шасі (рами).">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          value={item.c_chassis_num}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_chassis_num"
                          type="text"
                          label="Номер шасі (рами)"
                          id={`c_chassis_num${index}`}
                          // maxLength="20"
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`n_value${index}`}>
                        Вартість
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.n_value} id={`n_value${index}`} /> */}
                      <ListItemText
                        primary={item.n_value}
                        secondary={<Typography variant="caption">Вартість</Typography>}
                        id={`n_value${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть вартість.">
                        {/* <NumberFormat
                            required
                            value={item.n_value}
                            decimalSeparator="."
                            decimalScale={2}
                            onValueChange={(values) => {
                              const { formattedValue } = values;
                              handleChange(formattedValue, 'n_value', index);
                            }}
                            // name="n_value"
                            // type="text"
                            placeholder="Вартість"
                            id={`.n_value${index}`}
                            maxLength="10"
                          /> */}
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          label="Вартість"
                          value={item.n_value}
                          onChange={(e) => handleChange(e.target.value, 'n_value', index)}
                          name="n_value"
                          id={`n_value${index}`}
                          // inputProps={{ maxLength: 12 }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            inputProps: {
                              decimalSeparator: '.',
                              decimalScale: 2,
                              maxLength: 10,
                            },
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <UncontrolledTooltip placement="top" target={`c_owners${index}`}>
                        Власник (власники із зазначенням частки кожного)
                      </UncontrolledTooltip>
                      {/* <ListItemText primary={item.c_owners} id={`c_owners${index}`} /> */}
                      <ListItemText
                        primary={item.c_owners}
                        secondary={
                          <Typography variant="caption">Власник (власники із зазначенням частки кожного)</Typography>
                        }
                        id={`c_owners${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <BootstrapTooltip title="Введіть власника (власників із зазначенням частки кожного).">
                        <TextField
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                          value={item.c_owners}
                          onChange={(e) => handleChange(e, item, index)}
                          name="c_owners"
                          type="text"
                          label="Власник (власники із зазначенням частки кожного)"
                          id={`c_owners${index}`}
                          // maxLength="50"
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </BootstrapTooltip>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* {Number(item.i_aidtype) === 6 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <ListItemText
                        primary={'value'}
                        secondary={
                          <Typography variant="caption">Ідентифікаційний номер транспортного засобу</Typography>
                        }
                      />
                    </>
                  ) : (
                    <TextField
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      type="text"
                      label="Ідентифікаційний номер транспортного засобу"
                        // maxLength="20"
                        inputProps={{
                        maxLength: 20,
                        }}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <ListItemText
                        primary={'value'}
                        secondary={<Typography variant="caption">Марка</Typography>}
                      />
                    </>
                  ) : (
                    <TextField
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      type="text"
                      label="Марка"
                        // maxLength="20"
                        inputProps={{
                        maxLength: 20,
                        }}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <ListItemText
                        primary={'value'}
                        secondary={<Typography variant="caption">Комерційний опис</Typography>}
                      />
                    </>
                  ) : (
                    <TextField
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      type="text"
                      label="Комерційний опис"
                        // maxLength="20"
                        inputProps={{
                        maxLength: 20,
                        }}
                    />
                  )}
                </Grid>

                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <ListItemText
                        primary={'value'}
                        secondary={<Typography variant="caption">Об’єм двигуна, см-3</Typography>}
                      />
                    </>
                  ) : (
                    <TextField
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      type="text"
                      label="Об’єм двигуна, см-3"
                        // maxLength="20"
                        inputProps={{
                        maxLength: 20,
                        }}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <ListItemText
                        primary={'value'}
                        secondary={<Typography variant="caption">Тип двигуна</Typography>}
                      />
                    </>
                  ) : (
                    <TextField
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      type="text"
                      label="Тип двигуна"
                        // maxLength="20"
                        inputProps={{
                        maxLength: 20,
                        }}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!item.isEdit ? (
                    <>
                      <ListItemText
                        primary={'value'}
                        secondary={<Typography variant="caption">Рік випуску</Typography>}
                      />
                    </>
                  ) : (
                    <TextField
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      type="text"
                      label="Рік випуску"
                        // maxLength="20"
                        inputProps={{
                        maxLength: 20,
                        }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          )} */}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <Grid
          container
          justifyContent="center"
          // alignItems="center"
          spacing={2}
          // alignContent="space-between"
        >
          <Grid item>
            {(parseInt(item.i_aid) === 0 || item.isEdit) && (
              <Button color="primary" className={classes.button_save} onClick={() => saveAid(index)}>
                Зберегти
                <img className={classes.button_loading_img} src={save} />
              </Button>
            )}
          </Grid>
          <Grid item>
            {parseInt(item.i_aid) !== 0 && !item.isEdit && (
              <Button color="primary" className={classes.button_save} onClick={() => editAid(index)}>
                Редагувати
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button variant="outlined" className={classes.button_save} onClick={() => deleteItem(item.i_aid)}>
              Видалити допомогу
              {/* <img className={classes.button_loading_img} src={deleteButton} /> */}
            </Button>
          </Grid>
          {/* <Grid item>
              {Math.abs(parseInt(item.i_aid)) > 0 && (
                <Button color="primary" onClick={() => toggle(index, item.i_aid)}>
                  Додати набувача
                </Button>
              )}
            </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {item.acquirer.length > 0 && (
          <SimpleTable
            aid={item.i_aid}
            acquirer={item.acquirer}
            editAquirer={editAquirer}
            deleteItemAcquirer={deleteItemAcquirer}
            catalog={catalog}
          />
        )}
      </Grid>
    </Paper>
  );
};

AddCargo.propTypes = {
  cargo: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  promiseOptions: PropTypes.func.isRequired,
};

export default AddCargo;
