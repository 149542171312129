// packages
import React from 'react';
import { withRouter } from 'react-router';
// import ReactTable from 'react-table'
import 'react-table/react-table.css';
// import Select from 'react-select'
// import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownCircle from '@material-ui/icons/ArrowDropDownCircle';
import telegram from '../../../assets/images/telegram.png';
import facebook from '../../../assets/images/facebook.png';
import Typography from '@material-ui/core/Typography';

// import uk from 'date-fns/locale/uk'
import { NavLink, Link } from 'react-router-dom';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  // Row,
  // Col,
  // Card,
  // Progress,
  // CardBody,
  Table,
} from 'reactstrap';
// assets

// own css

import CardMaterial from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ButtonMaterial from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
// import { IconButton } from '@material-ui/core'
// import * as Feather from 'react-feather'
// import { history } from '../../../vibe/components/utilities/MainUtils/hist'
// import BootstrapTooltip from '../../elements/MaterialBootstrap'
// import { customStyles, customTheme } from '../../../customStyles/reactSelect'
import { Timeline } from 'react-event-timeline';
import logo from '../../../assets/images/gov.ua.white.png';
import {
  getRecipientInfo,
  getRecipientFull,
  getmeetingByRecipient,
  getOrderDoc,
  getProtocolDoc,
  getDocCargoPublic,
} from '../../../api/api';
import ViewDocModal from '../Dialogs/ViewDocModal';
import HistoryRecipient from './history.component';
import { red } from '@material-ui/core/colors';
import RecipientModal from './RecipientModal';
import logoASRGD from '../../../assets/images/ASRGD-Logo.svg';
import { makeStyles } from '@material-ui/core/styles';

const MOBILE_SIZE = 993;

const useStyles = makeStyles(theme => ({
  Navbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 40px',
    backgroundColor: '#8BAFD2',

    [theme.breakpoints.down(MOBILE_SIZE)]: {
      padding: '8px 16px',
    },
  },
  LogoNavLink: {
    height: '56px',
    marginRight: '16px',
    padding: 0,

    [theme.breakpoints?.down(MOBILE_SIZE)]: {
      // width: '138px',
      height: '50px',
    },
  },
  LoginNavLink: {
    padding: '14px 30px',
    fontSize: '16px',
    lineHeight: '21px',
    textTransform: 'none',
    color: 'white',

    [theme.breakpoints?.down(MOBILE_SIZE)]: {
      padding: '8px 30px',
    },
  },
  MainRegister: {
    padding: '40px',

    [theme.breakpoints?.down(MOBILE_SIZE)]: {
      padding: '24px 16px',
    },
  },
  CardContent: {
    padding: '24px 16px',
  },
  footer: {
    // height: '15vh',
    backgroundColor: '#141414',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px',
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'diaHead',
    // position: 'absolute',
    // bottom: '0',
    // left: '0',

    // marginTop: '50px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px',
      flexWrap: 'wrap',
    },
  },
  footerContact: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '5px 0',
    },
  },
  footerContactText: {
    fontSize: '15px',
    margin: '3px',
    fontFamily: 'diaHead',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      margin: '0px',
    },
  },
  socialIcons: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      paddingBottom: '10px',
    },
  },
  icon: {
    color: '#FFFFFF',
    margin: '0 8px',
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
        width: '20px',
        height: '20px',
    },
  },
}));

function RecipientInfo({ match, history }) {
  const [isOpen, toggle] = React.useState(false);
  const [data, getData] = React.useState({});
  const [dataCargo, getDataCargo] = React.useState([]);
  const [dataDocs, getDataDocs] = React.useState([]);
  const [historyInfo, setHistoryInfo] = React.useState([]);
  const [htmlDoc, getDoc] = React.useState('');
  const [dialog, toggleDoc] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalR, openModal] = React.useState(false);
  const [docCargo, setdocCargo] = React.useState('');
  const [docCargoID, setDocCargoID] = React.useState('');
  const classes = useStyles();

  const getInfo = async (id) => {
    if (!loading) {
      setLoading(true);
      try {
        const [response, success, recipientHistory, respCargo] = await getRecipientInfo(id);
        getData(response.data);
        getDataDocs(success.data);
        setHistoryInfo(recipientHistory.data);
        getDataCargo(respCargo.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        history.goBack();
      }
    }
  };

  const openMod = async (id) => {
    openModal((modalR) => !modalR);
    const response = await getDocCargoPublic(id);
    setdocCargo(response.data);
    setDocCargoID(id);
  };

  React.useEffect(() => {
    getInfo(match.params.id);
  }, [match.params.id]);

  // async function orederView(id) {
  //   getDoc('');
  //   try {
  //     const response = await getOrderDoc(id);
  //     getDoc(response.data);
  //     toggleDoc(!dialog);
  //   } catch (error) {}
  // }

  // async function protocolView(id) {
  //   getDoc('');
  //   try {
  //     const response = await getProtocolDoc(id);
  //     getDoc(response.data);
  //     toggleDoc(!dialog);
  //   } catch (error) {}
  // }

  function toggleModal() {
    toggleDoc(!dialog);
    getDoc('');
  }
  return (
    <div>
        <header>
          <Navbar className={classes.Navbar}>
            <img src={logoASRGD} alt="ASRGD Logo" className={classes.LogoNavLink} />
            <ButtonMaterial variant="contained" color="primary" href="/" className={classes.LoginNavLink}>
              Вхід
            </ButtonMaterial>
          </Navbar>
        </header>
      {/* <header>
        <Navbar
          color="light"
          dark
          expand="md"
          className="bg-gradient text-white"
          style={{ paddingBottom: '1.5rem', paddingTop: '1.5rem' }}
        >
          <Container>
            <NavbarBrand href="https://www.gov.ua/">
              <img src={logo} alt="buttonGovUa" />
            </NavbarBrand>
            <NavbarToggler onClick={() => toggle(!isOpen)} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    activeStyle={{
                      outline: '1px solid #fff',
                      fontFamily: 'dia_bold',
                    }}
                    className="nav-link text-white-btn"
                    to="/register-recipients-public"
                  >
                    Єдиний реєстр отримувачів11
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    activeStyle={{
                      outline: '1px solid #fff',
                      fontFamily: 'dia_bold',
                    }}
                    className="nav-link text-white-btn"
                    to="/register-public"
                  >
                    Реєстр гуманітарної допомоги
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    activeStyle={{
                      outline: '1px solid #fff',
                      fontFamily: 'dia_bold',
                    }}
                    className="nav-link text-white-btn"
                    to="/commission"
                  >
                    Засідання робочої групи
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <Button className="btn-warning text-dark ml-2" onClick={() => history.push('/')}>
                    Вхід
                  </Button>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header> */}
      <div className="mainRegister" style={{ width: '90%', margin: '50px auto' }}>
        <div className="row">
          <div className="col-md-4">
            <CardMaterial>
              <CardContent>
                {loading ? (
                  <>
                    <Skeleton animation="wave" height={36} width="60%" style={{ marginBottom: 15 }} />
                    <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                    <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                    <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                    <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                    <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                    <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                    <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                    <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                    <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                  </>
                ) : (
                  <>
                    <h4 className="text-bold">Детальний перегляд</h4>
                    <div className="p m-t">
                      {(data.recipient || {}).p_d_delete !== null ? (
                        <p style={{ backgroundColor: '#FF0606', fontWeight: 'bold', textAlign: 'center' }}>
                          Виключено із Реєстру
                        </p>
                      ) : null}
                    </div>
                    <div className="p m-t">
                      № в Реєстрі: <span className="p bold text-info m-t">{(data.recipient || {}).p_number}</span>
                    </div>
                    <div className="p  m-t">
                      Область: <span className="p bold text-info m-t">{(data.recipient || {}).p_region_name}</span>
                    </div>
                    <div className="p  m-t">
                      Населений пункт:{' '}
                      <span className="p bold text-info m-t">{(data.recipient || {}).p_locality_name}</span>
                    </div>
                    <div className="p  m-t">
                      Місцезнаходження: <span className="p bold text-info m-t">{(data.recipient || {}).p_adress}</span>
                    </div>
                    <div className="p  m-t">
                      Код ЄДРПОУ: <span className="p bold text-info m-t">{(data.recipient || {}).p_edrpou}</span>
                    </div>
                    <div className="p  m-t">
                      Назва отримувача: <span className="p bold text-info m-t">{(data.recipient || {}).p_orgname}</span>
                    </div>
                    <div className="p  m-t">
                      ПІБ керівника:{' '}
                      <span className="p bold text-info m-t">
                        {(data.recipient || {}).p_surname} {(data.recipient || {}).p_name}{' '}
                        {(data.recipient || {}).p_patronymic}
                      </span>
                    </div>
                    <div className="p  m-t">
                      Телефон: <span className="p bold text-info m-t">{(data.recipient || {}).p_phone}</span>
                    </div>
                    <div className="p  m-t">
                      Електронна адреса: <span className="p bold text-info m-t">{(data.recipient || {}).p_email}</span>
                    </div>
                    <div className="p  m-t">
                      Дата включення в Реєстр:{' '}
                      <span className="p bold text-info m-t">{(data.recipient || {}).p_d_include}</span>
                    </div>
                    <div className="p  m-t">
                      Дата виключення з Реєстру:{' '}
                      <span className="p bold text-info m-t">{(data.recipient || {}).p_d_delete}</span>
                    </div>

                    <div className="p  m-t">
                      Дата внесення змін:{' '}
                      <span className="p bold text-info m-t">{(data.recipient || {}).p_d_changes}</span>
                    </div>
                  </>
                )}
                {/* <div className="p  m-t">Дата виключення з Реєстру: <span className="p bold text-info m-t">{(data.recipient || {}).p_number}</span></div> */}
              </CardContent>
            </CardMaterial>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <CardMaterial>
                  {loading ? (
                    <Table>
                      <thead>
                        <tr className="p text-bold">
                          <th style={{ width: '10%' }}>
                            <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                          </th>
                          <th style={{ width: '60%' }}>
                            <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                          </th>
                          <th style={{ width: '40%' }}>
                            <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="p text-info text-bold">
                          <td>
                            <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                          </td>
                          <td>
                            <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                          </td>
                          <td>
                            <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <div className="table-responsive">
                    <Table style={{color: 'white'}}>
                      <thead style={{background: '#000000'}}>
                        <tr className="p text-bold">
                          <th style={{ width: '10%' }}>№ з/п</th>
                          <th style={{ width: '60%' }}>Назва</th>
                          <th style={{ width: '40%' }}>Скан документу</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(data.docs_recipient || []).map((item, index) => (
                          <tr className="p text-info text-bold" key={item.c_filename}>
                            <td>{1 + index}</td>
                            <td>{item.c_docname}</td>
                            <td>
                              <a href={`/aid/file/${item.c_filename}`} target="blank">
                                {item.c_filename}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </div>
                  )}
                </CardMaterial>
              </div>
              {/* <div className="col-md-12">
                    <CardMaterial>
                <Table>
                    <thead>
                        <tr className="p text-bold">
                        <th style={{ width: '60%' }}>Тип заяви</th>
                        <th style={{ width: '20%' }}>Дата створення документів</th>
                        <th style={{ width: '10%' }}>Накази</th>
                        <th style={{ width: '10%' }}>Протоколи</th>
                        </tr>
                    </thead>
                    <tbody>
                      {dataDocs.map(item => (
                        <tr className="p text-info text-bold">
                            <td>{item.c_name}</td>
                            <td>{item.d_create}</td>
                            <td>
                              <IconButton onClick={() => orederView(item.id)}>
                              <Feather.Clipboard />
                              </IconButton>
                              </td>
                            <td>
                              <IconButton onClick={() => protocolView(item.id)}>
                              <Feather.FileText />
                              </IconButton>
                              </td>
                        </tr>
                      ))}
                        
                    </tbody>
                </Table>
                    </CardMaterial>
                    </div> */}
              <div className="col-md-12">
                <h4 className="text-bold">Історія змін</h4>
                <Timeline>
                  {historyInfo.map((item) => (
                    <HistoryRecipient item={item} key={item.id} />
                  ))}
                </Timeline>
              </div>
            </div>

            <div className="col-md-12" style={{padding: '0'}}>
              <CardMaterial>
                {loading ? (
                  <Table style={{color: 'white'}}>
                    <thead style={{background: '#000000'}}>
                      <tr className="p text-bold">
                        <th style={{ width: '10%' }}>
                          <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                        </th>
                        <th style={{ width: '60%' }}>
                          <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                        </th>
                        <th style={{ width: '40%' }}>
                          <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="p text-info text-bold">
                        <td>
                          <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                        </td>
                        <td>
                          <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                        </td>
                        <td>
                          <Skeleton animation="wave" height={36} style={{ marginBottom: 15 }} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <div className="table-responsive">
                  <Table style={{color: 'white'}} >
                    <thead style={{background: '#000000'}}>
                      <tr className="p text-bold">
                        <th style={{ width: '10%', textAlign: 'center' }}>№ з/п</th>
                        <th style={{ width: '25%', textAlign: 'center' }}>Дата реєстрації</th>
                        <th style={{ width: '25%?', textAlign: 'center' }}>Дата Митного оформлення</th>
                        <th style={{ width: '15%', textAlign: 'center' }}>Вага (кг)</th>
                        <th style={{ width: '15%', textAlign: 'center' }}>Кількість</th>
                        <th style={{ width: '35%', textAlign: 'center' }}>Документи</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(dataCargo || []).map((item, index) => (
                        <tr className="p text-info text-bold" key={item.c_filename} style={{ textAlign: 'center' }}>
                          <td>{1 + index}</td>
                          <td>{item.d_state}</td>
                          {/* <td>
                            {' '}
                            {}
                            <a href={`/aid/file/${item.c_filename}`} target="blank">
                                {item.c_filename}
                              </a>
                          </td> */}
                          <td>{item.d_customs}</td>
                          <td>{item.n_weight}</td>
                          <td>{item.n_cnt}</td>
                          <td>
                            {item.l_display === 1 ? (
                              <IconButton
                                color="primary"
                                aria-label="download picture"
                                component="label"
                                onClick={() => openMod(item.cargo_id)}
                                // disabled={!data.original.isprot}
                                // onClick={() => ()}
                              >
                                <ArrowDropDownCircle />
                              </IconButton>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  </div>
                )}
              </CardMaterial>
            </div>
          </div>
        </div>

        <ButtonMaterial variant="contained" color="primary" href="/register-recipients-public" style={{marginTop: '30px'}} className={classes.LoginNavLink}>Назад</ButtonMaterial>
      </div>
      {htmlDoc && <ViewDocModal modal={dialog} htmlDoc={htmlDoc} toggle={() => toggleModal()} />}
      {docCargo && <RecipientModal modal={modalR} docCargo={docCargo} toggle={() => openMod(docCargoID)} />}
    
      <div className={classes.footer}>
<div className={classes.footerContact}>
  <p className={classes.footerContactText}>© 2023 Мінсоцполітики</p>
  <p className={classes.footerContactText}>
      Розробник:<a target='blank' href="https://www.ioc.gov.ua/"> ДП ІОЦ Мінсоцполітики  </a></p>
  <p className={classes.footerContactText}>(044) 494-19-10</p>
  <p className={classes.footerContactText}>callcenter@ioc.gov.ua</p>
</div>

<div className={classes.socialIcons}>
<Typography style={{fontSize: '15px'}}>Слідкуй за нами тут:</Typography>
<a href="https://m.facebook.com/MLSP.gov.ua">
{' '}
<img className="icon" src={facebook} />{' '}
</a>
<a href="https://t.me/MinSocUA">
{' '}
<img className="icon" src={telegram} />{' '}
</a>
</div>
</div>
    
    </div>
  );
}

export default withRouter(RecipientInfo);
