// packages
import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Delete from '@material-ui/icons/Delete';
import {
  // Card,
  // CardBody,
  // Button,
  // CardFooter,
  Row,
  Col,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { CardHeader, CardContent, Card, Button } from '@material-ui/core';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import TablePagination from '@material-ui/core/TablePagination';
import { applyAppRecipient } from '../../../api/api';
import {
  getRecipientsFatch,
  currentRecipientId,
  currentApplicationId,
  recipientById,
  apply,
  clearRecipient,
  getRecipientHistoryApplications,
  deleteRecipientAction,
  recipientByIdEdit,
} from '../../../redux/actions/recipient.action';
import { clearDeclaration } from '../../../redux/actions/declaration.action';
import { getCargoByID, clearCargo } from '../../../redux/actions/cargo.action';
import { toggleAction } from '../../../redux/actions/toggle.action';
import BootstrapTooltip from '../../elements/MaterialBootstrap';
import { selectRecipientItems } from '../../../redux/actions/recipient/recipient.selector';
import plus from '../../../assets/images/add.png';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const useStyles = (theme) => ({
  button_saveNew: {
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',

    width: '600px',
    height: '24px',
    borderRadius: '40px',
    margin: 'auto',

    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: 'white',
    background: '#141414',
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      padding: '25px 25px',
      fontSize: '11px',

    },

    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  button_loading_img: {
    marginLeft: '15px',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 11,
  },
}))(TableCell);
const Child = ({
  onMouseEnter,
  onMouseLeave,
  text,
  recipientByIdEdit,
  isHovering,
  history,
  recipientById,
  getRecipientHistoryApplications,
  getCargoByID,
  deleteRecipientAction,
  currentApplicationId,
  apply,
  toggleAction,
  applyApp,
}) => {
  return (
    <TableRow
      hover
      component="tr"
      dense="true"
      style={{
        minHeight: '20px',
        position: 'relative',
        cursor: 'pointer',
        zIndex: '1',
        boxShadow: 'inset 0 -1px 0 0 rgba(100,121,143,0.122)',
      }}
      onClick={(e) => {
        recipientById(text.id);
        getRecipientHistoryApplications(text.id);
        // currentApplicationId(text.application_id)
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StyledTableCell>{text.region_name}</StyledTableCell>
      <StyledTableCell>{text.locality_name}</StyledTableCell>
      <StyledTableCell>{text.n_edrpou}</StyledTableCell>
      <StyledTableCell>{text.c_orgname}</StyledTableCell>
      <StyledTableCell>
        {text.c_surname} <br />
        {text.c_name} <br />
        {text.c_patronymic}
      </StyledTableCell>
      <StyledTableCell>{text.n_phone}</StyledTableCell>
      <StyledTableCell>{text.c_email}</StyledTableCell>
      <StyledTableCell>{text.d_include}</StyledTableCell>
      <StyledTableCell>{text.d_delete}</StyledTableCell>
      <StyledTableCell>{text.statetxt}</StyledTableCell>

      <StyledTableCell
        style={{
          // display:  'flex',
          padding: '0px',
          // position: "absolute",
          // border: 'none',
          alignItems: 'center',
          flex: '0 0 auto',
          lineHeight: '20px',
          order: '1',
          emptyCells: 'show',
          fontSize: '.875rem',
          height: 'auto',
          outline: 'none',
          verticalAlign: 'middle',
          whiteSpace: 'nowrap',
          position: 'relative',
          minWidth: '160px',
        }}
      >
        {/* {isHovering && ( */}
        {
          <ul
            style={{
              display: 'flex',
              // marginRight: "6px",
              paddingRight: '20px',
              // paddingLeft: "20px",
              padding: '0px',
              position: 'absolute',
              top: '0%',
            }}
          >
            {/* <UncontrolledTooltip placement="top" target={"edit" + text.id}>
                       Редагувати
              </UncontrolledTooltip> */}
            {/* <UncontrolledTooltip placement="top" target={"delete" + text.id}>
                       Видалити
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="top" target={"done" + text.id}>
                       Подати заяву
              </UncontrolledTooltip> */}
            {text.application_state === 0 && (
              <>
                <li className="bqX">
                  <BootstrapTooltip title="Редагувати">
                    <IconButton
                      id={`edit${text.id}`}
                      color="primary"
                      aria-label="Edit"
                      disabled={text.application_state !== 0}
                      onClick={(e) => {
                        e.stopPropagation();
                        recipientByIdEdit(text.id);
                        setTimeout(() => {
                          history.push('/create-recipient/edit');
                        }, 1000);

                        // currentApplicationId(text.application_id)

                        // getCargoByID(text.id);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </BootstrapTooltip>
                </li>
                <li className="bqX">
                  <BootstrapTooltip title="Видалити">
                    <IconButton
                      id={`delete${text.id}`}
                      color="primary"
                      aria-label="Delete"
                      disabled={text.application_state !== 0}
                      onClick={(e) => {
                        e.stopPropagation();

                        swalWithBootstrapButtons
                          .fire({
                            text: 'Ви дійсно бажаєте видалити заяву',
                            showCancelButton: true,
                            confirmButtonText: 'Так, видалити заяву',
                            cancelButtonText: 'Відміна',
                          })
                          .then((result) => {
                            if (result.value) {
                              deleteRecipientAction(text.id);
                            }
                          });
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </BootstrapTooltip>
                </li>
                {/* <li className="bqX">
                  <BootstrapTooltip title="Подати заяву">
                    <IconButton
                      id={`done${text.id}`}
                      color="primary"
                      aria-label="Send"
                      className="mr-2"
                      disabled={text.application_state !== 0}
                      onClick={(e) => {
                        e.stopPropagation();
                        // cargo id to apply() action
                        // applyApp(text.id)

                        swalWithBootstrapButtons
                          .fire({
                            text: 'Ви дійсно бажаєте подати заяву',
                            showCancelButton: true,
                            confirmButtonText: 'Так, подати заяву',
                            cancelButtonText: 'Відміна',
                          })
                          .then((result) => {
                            if (result.value) {
                              apply(text.id);
                            }
                          });
                      }}
                    >
                      <DoneIcon fontSize="small" />
                    </IconButton>
                  </BootstrapTooltip>
                </li> */}
              </>
            )}
          </ul>
        }
      </StyledTableCell>
    </TableRow>
  );
};

function TablePgination({ recipients, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page }) {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={recipients.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
      labelRowsPerPage="Рядків на сторінці:"
    />
  );
}

class RegisterRecipientsPersonal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseInside: false,
      isHovered: {},
      page: 0,
      rowsPerPage: 5,
    };
    this.applyApp = this.applyApp.bind(this);
  }

  handleMouseEnter = (index) => {
    // this.setState({ isMouseInside: true });
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: true } };
    });
  };

  handleMouseLeave = (index) => {
    // this.setState({ isMouseInside: false });
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: false } };
    });
  };

  componentDidMount() {
    this.props.getRecipientsFatch(this.props.user.id);
    if (this.props.recipientPage === 1) {
      this.props.history.push(`/create-recipient/save`);
    } else if (this.props.recipientPage === 2) {
      this.props.recipientById(this.props.recipients[0].id);
      this.props.getRecipientHistoryApplications(this.props.recipients[0].id);
    } else if (this.props.recipientPage === 4) {
      this.props.history.push(`/home`);
    }
  }

  async applyApp(id) {
    const data = {
      p_id: id,
      p_user: this.props.user.id,
    };
    try {
      const response = await applyAppRecipient(data);
    } catch (error) {
      // if (error.response.status === 400) {
      // }
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  render() {
    const { classes } = this.props;
    const emptyRows =
      this.state.rowsPerPage -
      Math.min(this.state.rowsPerPage, this.props.recipients.length - this.state.page * this.state.rowsPerPage);
    return (
      <Row>
        <Col xs={12}>
          <Card plain="true">
            <CardHeader
              action={
                <Route
                  render={({ history }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button_saveNew}
                      onClick={(e) => {
                        this.props.clearRecipient();
                        this.props.clearCargo();
                        this.props.clearDeclaration();
                        this.props.toggleAction('1');
                        history.push(`/create-recipient/save`);
                      }}
                    >
                      Додати запис до Єдиного реєстру отримувачів
                      <img className={classes.button_loading_img} src={plus} />
                    </Button>
                  )}
                />
              }
            />
            <CardContent>
              <div className="table-responsive">
                <Table style={{ fontSize: '11px' }} hover="true" size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Область</StyledTableCell>
                      <StyledTableCell>Населений пункт </StyledTableCell>
                      <StyledTableCell>Код ЄДРПОУ</StyledTableCell>
                      <StyledTableCell>Назва отримувача</StyledTableCell>
                      <StyledTableCell>ПІБ керівника</StyledTableCell>
                      <StyledTableCell>Телефон</StyledTableCell>
                      <StyledTableCell>Електронна адреса</StyledTableCell>
                      {/* <th>Стан в реєстрі</th> */}
                      <StyledTableCell>Дата включення в реєстр</StyledTableCell>
                      <StyledTableCell>Дата виключення з реєстру</StyledTableCell>
                      <StyledTableCell>Статус</StyledTableCell>
                      <StyledTableCell>Функції</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.recipients.length > 0 &&
                      this.props.recipients
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
                        )
                        .map((item, index) => (
                          <Child
                            onMouseEnter={() => this.handleMouseEnter(index)}
                            onMouseLeave={() => this.handleMouseLeave(index)}
                            text={item}
                            isHovering={this.state.isHovered[index]}
                            key={item.id}
                            index={index}
                            history={this.props.history}
                            recipientById={this.props.recipientById}
                            recipientByIdEdit={this.props.recipientByIdEdit}
                            getCargoByID={this.props.getCargoByID}
                            getRecipientHistoryApplications={this.props.getRecipientHistoryApplications}
                            deleteRecipientAction={this.props.deleteRecipientAction}
                            currentApplicationId={this.props.currentApplicationId}
                            applyApp={this.applyApp}
                            apply={this.props.apply}
                            toggleAction={this.props.toggleAction}
                          />
                        ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 48 * emptyRows }}>
                        <StyledTableCell colSpan={10} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePgination
                  recipients={this.props.recipients}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                />
              </div>
            </CardContent>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // recipients: state.recipient.recipients,
    recipients: selectRecipientItems(state),
    loading: state.isFatching,
    errMessage: state.error,
    user: state.auth.user,
    recipientPage: state.recipient.recipientsPage,
  };
};

RegisterRecipientsPersonal.propTypes = {
  getRecipientsFatch: PropTypes.func.isRequired,
  currentRecipientId: PropTypes.func.isRequired,
  currentApplicationId: PropTypes.func.isRequired,
  recipientById: PropTypes.func.isRequired,
  toggleAction: PropTypes.func.isRequired,
  getRecipientHistoryApplications: PropTypes.func.isRequired,
  deleteRecipientAction: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  loading: PropTypes.object.isRequired,
  errMessage: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  getRecipientsFatch,
  currentRecipientId,
  recipientById,
  recipientByIdEdit,
  apply,
  clearRecipient,
  getCargoByID,
  getRecipientHistoryApplications,
  deleteRecipientAction,
  currentApplicationId,
  toggleAction,
  clearCargo,
  clearDeclaration,
})(withStyles(useStyles)(withRouter(RegisterRecipientsPersonal)));
