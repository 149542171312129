import React, { Component } from 'react';
import { Box, withStyles } from '@material-ui/core';
import NavSpacer from './components/NavSpacer';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import PageAlertContext from '../PageAlert/PageAlertContext';
import NavButtonItem from './components/NavButtonItem';
import NavCloseButton from './components/NavCloseButton';

const profileNavItem = { name: 'Профіль', url: '/user-profile', icon: 'User' };
const logoutNavItem = { name: 'Вихід', url: '#', icon: 'LogOut' };

const NavBrandContainer = withStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})(Box);

const NavBrand = ({ logo, logoGerb, isSidebarCollapsed, LOGO_menu, isMobile, setSidebarCollapsedOff }) => {
  return (
    <NavBrandContainer>
      <div className="site-logo-bar">
        <a href="https://www.ioc.gov.ua/eservices/" className="navbar-brand" target="_blank" rel="noopener noreferrer">
          <img src={LOGO_menu} alt="logo" width="210px" style={{ margin: 'auto' }} />
        </a>
      </div>
      {isMobile && <NavCloseButton onClick={setSidebarCollapsedOff} />}
    </NavBrandContainer>
  );
};

export default class SidebarNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isMobile, isSidebarCollapsed, toggleSidebar, setSidebarCollapsedOff, logoutUser } = this.props;

    const mobileSidebarClassName = isMobile ? 'mobile-sidebar' : '';

    const itemType = (item, index) => {
      if (item.children) {
        return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} />;
      }
      if (item.divider) {
        return <NavDivider key={index} />;
      }
      return <NavSingleItem item={item} key={index} />;
    };

    const navItems = (items) => {
      return items.map((item, index) => itemType(item, index));
    };

    return (
      <PageAlertContext.Consumer>
        {(consumer) => {
          const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
          const sidebarClassNames = `app-sidebar ${hasPageAlertClass} ${mobileSidebarClassName}`.trim();

          return (
            <div>
              <div className={sidebarClassNames}>
                <NavBrand
                  logo={this.props.logo}
                  logoGerb={this.props.logoGerb}
                  LOGO_menu={this.props.LOGO_menu}
                  isSidebarCollapsed={isSidebarCollapsed}
                  isMobile={isMobile}
                  toggleSidebar={toggleSidebar}
                  setSidebarCollapsedOff={setSidebarCollapsedOff}
                />
                <nav>
                  <ul id="main-menu">
                    {navItems(this.props.nav.top)}
                    <NavSpacer />
                    {navItems(this.props.nav.bottom)}
                    {isMobile && (
                      <>
                        <NavSingleItem item={profileNavItem} key="profileNavItem" />
                        <NavButtonItem item={logoutNavItem} key="logoutNavItem" onClick={logoutUser} />
                      </>
                    )}
                  </ul>
                </nav>
              </div>
              {/* {isSidebarCollapsed && <NavOverlay onClick={this.props.toggleSidebar} />} */}
            </div>
          );
        }}
      </PageAlertContext.Consumer>
    );
  }
}
