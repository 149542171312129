import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import isEmpty from '../../vibe/components/utilities/MainUtils/is-empty';
import queryString from 'query-string';
import { history } from '../../vibe/components/utilities/MainUtils/hist';
import telegram from '../../../src/assets/images/telegram.png';
import facebook from '../../../src/assets/images/facebook.png';
import flag from '../../../src/assets/images/ASRGD_Logo.png';
import { AppBar, Toolbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import './InfoUrPerson.css';

const QuestionFizPerson = () => {
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (!isEmpty(query.token)) {
      localStorage.setItem('jwtToken', query.token);
    } else {
      history.push('/');
    }
  });
  return (
    <div className="page">
      <Grid item>
        <Grid container>
          <AppBar position="sticky" elevation={0} style={{ backgroundColor: 'transparent' }}>
            <Toolbar>
              {/* <Container> */}
              <Button href="https://www.msp.gov.ua">
                <img src={flag} alt="buttonGovUa" width='210px' style={{ margin: '25px auto', display: 'block', objectFit: 'cover' }} />
              </Button>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>

      <div className="questionContent">
        <h2 className="title_question">
        <b> Ви здійснили вхід як фізична особа.</b> <br /> Підтвердіть, що ви є уповноваженим представником отримувача гуманітарної допомоги.
        </h2>
        <div className="buttonBlocks">
          <NavLink className="button_saveQuestion" to={'/info-page'}>
            Так
          </NavLink>
          <NavLink className="button_saveQuestion" to={'/error-page'}>
            Ні
          </NavLink>
        </div>
      </div>
      <div className='footer1'>
    <div className='footerContact'>
    <p className='footerContactText'>© 2023 Мінсоцполітики</p>
            <p className='footerContactText'>
              Розробник:<a target='blank' href="https://www.ioc.gov.ua/"> ДП ІОЦ Мінсоцполітики  </a></p>
              <p className='footerContactText'>(044) 494-19-10</p>
              <p className='footerContactText'>callcenter@ioc.gov.ua</p>
    </div>
 
            <div className='socialIcons'>
              <Typography style={{fontSize: '15px'}}>Слідкуй за нами тут:</Typography>
              <a href="https://m.facebook.com/MLSP.gov.ua">
                {' '}
                <img className="icon" src={facebook} />{' '}
              </a>
              <a href="https://t.me/MinSocUA">
                {' '}
                <img className="icon" src={telegram} />{' '}
              </a>
            </div>
  </div>
    </div>
  );
};

export default QuestionFizPerson;
