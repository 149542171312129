import React from 'react';
// material
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
// import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TopBarProgress from 'react-topbar-progress-indicator';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { Grid, CardHeader, CardContent, Card, Button, Icon } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import BootstrapTooltip from '../../elements/MaterialBootstrap';
import { cargoApply, getMyCargo } from '../../../api/api';
// import PageLoaderContext from '../../../vibe/components/PageLoader/PageLoader';
import { clearDeclaration } from '../../../redux/actions/declaration.action';
import {
  clearCargo,
  getCargoByIdFull,
  getCargoByID,
  deleteCargoFromMyDelivery,
  getMyCargoAction,
  setCargoSearch,
} from '../../../redux/actions/cargo.action';
import { getRecipientsForSelect } from '../../../redux/actions/recipient.action';
import DeliveryPanel from './DeliveryPanel';
import { StyledTableSortLabel } from '../RegisterRecipients/StyledTableComponents';
import { getComparator, stableSort } from '../RegisterRecipients/sortFunctions';

import './DeliveryPopup.css';

TopBarProgress.config({
  barColors: {
    0: '#2D5CA6',
    '1.0': '#2D5CA6',
  },
  shadowBlur: 5,
});

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const useStyles = (theme) => ({
  button_saveNew: {
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',

    width: '600px',
    height: '24px',
    borderRadius: '40px',
    margin: 'auto',

    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '24px',
    textAlign: 'center',
    color: 'white',
    background: '#141414',
    [theme.breakpoints.down('sm')]: {
      width: '280px',
      padding: '25px 25px',
      fontSize: '11px',
    },

    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  button_loading_img: {
    marginLeft: '15px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function Child({
  cargo,
  onMouseEnter,
  onMouseLeave,
  text,
  isHovering,
  getCargoByID,
  getCargoByIdFull,
  deleteCargo,
  applyCargo,
  history,
}) {
  const { l_view, l_change, l_delete } = text;

  const handleEditCargo = async () => {
    await getCargoByID(text.cargo_id);
    history.push('/create-delivery/edit');
  };

  return (
    <>
      <TableRow
        hover
        component="tr"
        dense="true"
        style={{
          minHeight: '20px',
          position: 'relative',
          cursor: 'pointer',
          zIndex: '1',
          boxShadow: 'inset 0 -1px 0 0 rgba(100,121,143,0.122)',
        }}
        onClick={(e) => {
          getCargoByIdFull(text.cargo_id);
          history.push('/view-delivery');
        }}
        key={text.cargo_id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <StyledTableCell width="5%">{text.cargo_number}</StyledTableCell>
        <StyledTableCell width="5%">{text.edrpou}</StyledTableCell>
        <StyledTableCell>{text.donor_name}</StyledTableCell>
        <StyledTableCell>{text.weight}</StyledTableCell>
        <StyledTableCell>{text.amount}</StyledTableCell>
        <StyledTableCell width="10%">{text.d_state}</StyledTableCell>
        <StyledTableCell width="10%">{text.d_customs}</StyledTableCell>
        <StyledTableCell width="10%">{text.statetxt}</StyledTableCell>
        <StyledTableCell
          style={{
            // display:  'flex',
            padding: '0',
            // position: "absolute",
            // border: 'none',
            alignItems: 'center',
            flex: '0 0 auto',
            lineHeight: '20px',
            order: '1',
            emptyCells: 'show',
            fontSize: '.875rem',
            height: '50px',
            outline: 'none',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            position: 'relative',
            minWidth: '160px',
            width: '10%',
          }}
        >
          {/* {isHovering && ( */}
          {
            <ul
              style={{
                display: 'flex',
                marginRight: '6px',
                padding: '0',
                position: 'absolute',
                top: '0%',
                // right:"5%",
                // float:"left"
              }}
            >
              <>
                {l_change === 1 && (
                  <li className="bqX">
                    <BootstrapTooltip title="Редагувати">
                      <IconButton
                        id={`edit${text.cargo_id}`}
                        color="primary"
                        aria-label="Delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditCargo();
                          // getCargoByID(text.cargo_id);
                          // history.push('/create-delivery/edit');
                        }}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </BootstrapTooltip>
                  </li>
                )}
                {l_view === 1 && (
                  <li className="bqX">
                    <BootstrapTooltip title="Перегляд">
                      <IconButton id={`view${text.cargo_id}`} color="primary" aria-label="View">
                        <RemoveRedEyeIcon fontSize="inherit" />
                      </IconButton>
                    </BootstrapTooltip>
                  </li>
                )}
                {l_delete === 1 && (
                  <li className="bqX">
                    <BootstrapTooltip title="Видалити">
                      <IconButton
                        id={`delete${text.cargo_id}`}
                        color="primary"
                        aria-label="Delete"
                        onClick={(e) => deleteCargo(e, text.cargo_id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </BootstrapTooltip>
                  </li>
                )}
              </>
            </ul>
          }
        </StyledTableCell>
      </TableRow>
    </>
  );
}

function TablePgination({ cargo, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page }) {
  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 50]}
      component="div"
      count={cargo.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
      labelRowsPerPage="Рядків на сторінці:"
    />
  );
}

const tableHeads = [
  {
    id: 'cargo_number',
    numeric: true,
    label: 'Код вантажу',
  },
  {
    id: 'edrpou',
    numeric: false,
    label: 'ЄДРПОУ Отримувача',
  },
  {
    id: 'donor_name',
    numeric: false,
    label: 'Донор',
  },
  {
    id: 'weight',
    numeric: true,
    label: 'Вага',
  },
  {
    id: 'amount',
    numeric: true,
    label: 'Кількість',
  },
  {
    id: 'd_state',
    numeric: false,
    label: 'Дата реєстрації',
  },
  {
    id: 'd_customs',
    numeric: false,
    label: 'Дата митного оформлення',
  },
  {
    id: 'statetxt',
    numeric: false,
    label: 'Статус',
  },
];

class Delivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      // isMouseInside: false,
      isHovered: {},
      intervalId: 0,
      // cargoSearch: {
      //   currentCount: 0,
      //   page: 0,
      //   rowsPerPage: 5,
      //   order: 'asc',
      //   orderBy: 'code',
      //   search: { code: '', startdate: null, enddate: null, status: null },
      // },
      // old
      // currentCount: 0,
      // page: 0,
      // rowsPerPage: 5,
      // order: 'asc',
      // orderBy: 'code',
      // search: { code: '', startdate: null, enddate: null, status: null },
    };
    this.init = this.init.bind(this);
    this.applyCargo = this.applyCargo.bind(this);
  }

  handleMouseEnter = (index) => {
    // this.setState({ isMouseInside: true });
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: true } };
    });
  };

  handleMouseLeave = (index) => {
    // this.setState({ isMouseInside: false });
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: false } };
    });
  };

  componentDidMount() {
    const { cargoSearch, setCargoSearch } = this.props;
    const {
      search: { code, startdate, enddate, status },
    } = cargoSearch;
    // setCargoSearch({ ...cargoSearch, page: 0 });
    // this.setState({ page: 0 });
    // eslint-disable-next-line react/destructuring-assignment
    // const {
    //   search: { code, startdate, enddate, status },
    // } = this.state;
    const {
      user: { id: userId },
      // eslint-disable-next-line no-shadow
      getMyCargoAction,
    } = this.props;
    getMyCargoAction({
      id: userId,
      // p_cargo: null,
      // p_reg_date: null,
      // p_passed_date: null,
      // p_state: null,
      p_cargo: code || null,
      p_reg_date: startdate || null,
      p_passed_date: enddate || null,
      p_state: status?.value === 0 ? 0 : status?.value || null,
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.getRecipientsForSelect(this.props.user.id);
  }

  deleteCargo = (e, id) => {
    e.stopPropagation();
    swalWithBootstrapButtons
      .fire({
        customClass: {
          popup: 'swal-popup',
          container: 'swal-container',
          title: 'swal-title swal-title-m1',
          confirmButton: 'swal-custom-btn',
          cancelButton: 'swal-custom-btn swal-custom-btn-lt',
          actions: 'swal-actions',
        },
        title: 'Вилучити з реєстру Вантаж і Декларацію по ньому?',
        showConfirmButton: true,
        showCancelButton: true,
        focusConfirm: true,
        focusCancel: false,
        reverseButtons: true,
        confirmButtonText: 'ПІДТВЕРДИТИ',
        cancelButtonText: 'ВІДМІНИТИ',
      })
      .then((result) => {
        if (result?.value) {
          // eslint-disable-next-line react/destructuring-assignment
          this.props.deleteCargoFromMyDelivery(id);
        }
      });

    // this.init(this.props.user.id)
  };

  async init(id) {
    const intervalId = setInterval(this.timer, 10);
    this.setState((prevState) => ({ loading: !prevState.loading, intervalId }));
    try {
      const response = await getMyCargo(id);

      setTimeout(() => {
        if (response.data.length > 0) {
          // this.props.context.loadPage()
          this.setState({ arr: response.data, loading: false, currentCount: 100 });
        }
        clearInterval(this.state.intervalId);
      }, 1000);
    } catch (error) {}
  }

  timer = () => {
    this.setState((prevState) => ({ currentCount: prevState.currentCount + 1 }));
  };

  applyCargo(id) {
    swalWithBootstrapButtons
      .fire({
        text: 'Ви дійсно бажаєте подати заяву',
        showCancelButton: true,
        confirmButtonText: 'Так',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const data = {
              p_id: id,
              p_user: this.props.user.id,
            };
            const response = await cargoApply(data);

            swalWithBootstrapButtons
              .fire({
                type: 'success',
                text: 'Заяву подано!',
              })
              .then(async (res) => {
                if (res.value) {
                  const { cargoSearch, setCargoSearch } = this.props;
                  // const {
                  //   search: { code, startdate, enddate, status },
                  // } = cargoSearch;
                  await setCargoSearch({ ...cargoSearch, page: 0 });
                  // await this.setState({ page: 0 });
                  // eslint-disable-next-line react/destructuring-assignment
                  this.props.getMyCargoAction({
                    // eslint-disable-next-line react/destructuring-assignment
                    id: this.props.user.id,
                    p_state: null,
                    p_cargo: null,
                    p_reg_date: null,
                    p_passed_date: null,
                  });
                }
              });
          } catch (error) {
            swalWithBootstrapButtons.fire({
              type: 'error',
              text: error.response.data,
            });
          }
        }
      });
  }

  handleChangePage = (event, newPage) => {
    const { cargoSearch } = this.props;
    const {
      cargoSearch: { page },
      setCargoSearch,
    } = this.props;
    setCargoSearch({ ...cargoSearch, page: newPage });

    // this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-shadow
    const { cargoSearch, setCargoSearch } = this.props;
    setCargoSearch({ ...cargoSearch, page: 0, rowsPerPage: +event.target.value });

    // eslint-disable-next-line func-names
    setTimeout(function () {
      window.scrollTo({
        top: 100,
        left: 0,
        behavior: 'smooth',
      });
    }, 500);
    // this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  // sorting
  handleRequestSort = (event, property) => {
    const { cargoSearch, setCargoSearch } = this.props;
    const { order, orderBy } = cargoSearch;
    // const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    setCargoSearch({ ...cargoSearch, order: isAsc ? 'desc' : 'asc', orderBy: property });
    // this.setState({ order: isAsc ? 'desc' : 'asc' });
    // this.setState({ orderBy: property });
  };

  createSortHandler = (property) => (event) => {
    this.handleRequestSort(event, property);
  };

  setSearch = (field, value) => {
    const { cargoSearch, setCargoSearch } = this.props;
    const { search } = cargoSearch;
    setCargoSearch({ ...cargoSearch, search: { ...search, [field]: value } });
    // const { search } = this.state;
    // this.setState({ search: { ...search, [field]: value } });
  };

  handleSearch = async () => {
    const {
      cargoSearch,
      setCargoSearch,
      getMyCargoAction,
      user: { id },
    } = this.props;
    const {
      search: { code, startdate, enddate, status },
    } = cargoSearch;
    await setCargoSearch({ ...cargoSearch, page: 0 });
    // const {
    //   search: { code, startdate, enddate, status },
    // } = this.state;
    // this.setState({ page: 0 });
    getMyCargoAction({
      id,
      p_cargo: code || null,
      p_reg_date: startdate || null,
      p_passed_date: enddate || null,
      p_state: status?.value === 0 ? 0 : status?.value || null,
    });
  };

  clearPages = () => {
    const { cargoSearch } = this.props;
    // eslint-disable-next-line no-shadow
    const { setCargoSearch } = this.props;
    setCargoSearch({ ...cargoSearch, page: 0 });
    // this.setState({ page: 0 });
  };

  render() {
    const { classes, cargo } = this.props;
    const {
      cargoSearch: { search },
    } = this.props;
    // const { rowsPerPage, page, currentCount } = this.state;
    // cargo search
    const {
      cargoSearch: { currentCount, page, rowsPerPage, order, orderBy },
    } = this.props;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, cargo.length - page * rowsPerPage);
    const percent = (currentCount * 100) / 100;

    // const { order, orderBy, search } = this.state;

    return (
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <DeliveryPanel
            search={search}
            setSearch={this.setSearch}
            handleSearch={this.handleSearch}
            clearPages={this.clearPages}
          />
        </Grid>
        <Grid item sm={12}>
          <Card>
            <CardHeader
              action={
                <Route
                  render={({ history }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      // className="pull-right"
                      className={classes.button_saveNew}
                      onClick={(e) => {
                        this.props.clearCargo();
                        this.props.clearDeclaration();
                        history.push(`/create-delivery/save`);
                      }}
                    >
                      <Icon className="fa fa-plus mr-3" />
                      Додати нове надходження гуманітарної допомоги
                    </Button>
                  )}
                />
              }
            />
            <CardContent>
              <div className="table-responsive">
                <Table style={{ fontSize: '12px' }} hover="true" size="small">
                  <TableHead>
                    <TableRow>
                      {tableHeads.map(({ id, label }) => (
                        <StyledTableCell key={id}>
                          <StyledTableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : 'asc'}
                            onClick={this.createSortHandler(id)}
                          >
                            {label}
                            {orderBy === id ? (
                              <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </StyledTableSortLabel>
                        </StyledTableCell>
                      ))}
                      <StyledTableCell>Функції</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      // this.props.cargo
                      stableSort(cargo, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => (
                          <Child
                            onMouseEnter={() => this.handleMouseEnter(index)}
                            onMouseLeave={() => this.handleMouseLeave(index)}
                            text={item}
                            isHovering={this.state.isHovered[index]}
                            key={index}
                            index={index}
                            history={this.props.history}
                            getCargoByID={this.props.getCargoByID}
                            getCargoByIdFull={this.props.getCargoByIdFull}
                            deleteCargo={this.deleteCargo}
                            applyCargo={this.applyCargo}
                            cargo={this.props.cargo}
                          />
                        ))
                    }

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 48 * emptyRows }}>
                        <StyledTableCell colSpan={8} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <TablePgination
                cargo={cargo}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                // page={this.state.page}
                page={page}
              />
            </CardContent>
          </Card>
        </Grid>
        {this.props.loading.MY_CARGO && <TopBarProgress />}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cargo: state.cargo.cargos,
    loading: state.isFatching,
    cargoSearch: state.cargo.cargoSearch,
  };
};

export default connect(mapStateToProps, {
  getCargoByID,
  getCargoByIdFull,
  clearCargo,
  deleteCargoFromMyDelivery,
  getMyCargoAction,
  clearDeclaration,
  getRecipientsForSelect,
  setCargoSearch,
})(withStyles(useStyles)(withRouter(Delivery)));
