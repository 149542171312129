/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useReducer, useMemo, memo } from 'react';
// table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { UncontrolledTooltip } from 'reactstrap';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import _debounce from 'lodash/debounce';
import { Chip, InputAdornment, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { StyledTableCell, CustomTablePagination, EnhancedTableHead } from '../../elements/CustomTable';
import CustomLoader from '../../../vibe/components/CustomLoader/CustomLoader';
import { getComparator, stableSort } from '../RegisterRecipients/sortFunctions';
import { history } from '../../../vibe/components/utilities/MainUtils/hist';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '14px 56px 14px 16px',
    backgroundColor: 'white',
    borderRadius: 30,
    width: '100%',
    fontSize: 14,
    height: '48px',
    '& input': {
      paddingRight: 16,
    },
  },
}));

const headCells = [
  { label: 'Тип військового формування (в.ф.)', numeric: false, disablePadding: false, id: 'org_type' },
  { label: 'Номер акту', numeric: false, disablePadding: false, id: 'act_number' },
  { label: 'VIN-код', numeric: false, disablePadding: false, id: 'vin' },
  { label: 'Дата подання', numeric: false, disablePadding: false, id: 'reg_date' },
  { label: 'Статус', numeric: false, disablePadding: false, id: 'act_state' },
  { label: 'Дія', numeric: false, disablePadding: false, id: 'id' },
];

// Initial state
const initialState = {
  data: {
    // pc_filter: '',
    pi_orgtype: null,
    pn_state: null,
    pn_page: 1,
    pn_rows: 10,
  },
  loading: false,
  total_rows: 0,
};

// Reducer function
function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value,
        },
      };
    case 'UPDATE_PAGINATION':
      return {
        ...state,
        data: {
          ...state.data,
          pn_page: action.pn_page,
          pn_rows: action.pn_rows,
        },
      };
    case 'SET_TOTAL_ROWS':
      return {
        ...state,
        total_rows: action.total_rows,
      };
    case 'SET_LOADING':
      return { ...state, loading: action.value };
    case 'ON_INDICATOR':
      return { ...state, indicator: true };
    case 'OFF_INDICATOR':
      return { ...state, indicator: false };
    default:
      return state;
  }
}

const DepartmentalRegistrationList = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dataTable, setDataTable] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [text, setText] = React.useState('');
  const [actStateData, setActStateData] = useState([]);
  const [muTypeData, setMuTypeData] = useState([]);

  async function getPackages(data, textValue) {
    dispatch({ type: 'SET_LOADING', value: true });
    try {
      // Endpoint URL
      const url = '/aid/getPackages';

      // Making the POST request
      const response = await axios.post(url, { ...data, pc_filter: textValue });
      setDataTable(response.data.cursor);
      dispatch({ type: 'UPDATE_PAGINATION', pn_page: +response.data.pn_page, pn_rows: +response.data.pn_rows });
      dispatch({ type: 'SET_TOTAL_ROWS', total_rows: +response.data.total_rows });
      // Handle response data as needed
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    } finally {
      dispatch({ type: 'SET_LOADING', value: false });
    }
  }

  useEffect(() => {
    getPackages(state.data, text);
  }, []);

  const endpoints = ['/aid/submittedActState', '/aid/muType'];

  const fetchData = async (endpoint) => {
    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };
  const fetchDataFromAllEndpoints = async () => {
    dispatch({ type: 'ON_INDICATOR' });
    try {
      const [acts, muType] = await axios.all(endpoints.map(fetchData));
      setActStateData(acts);
      setMuTypeData(muType);
    } catch (error) {
      console.error('Error fetching data from all endpoints:', error);
    } finally {
      dispatch({ type: 'OFF_INDICATOR' });
    }
  };

  useEffect(() => {
    // Fetch data from all endpoints when the component mounts
    fetchDataFromAllEndpoints();
  }, []);

  const handleChangePage = (event, newPage) => {
    const page = newPage + 1;
    // dispatch({ type: 'UPDATE_PAGINATION', pn_page: page, pn_rows: state.data.pn_rows });
    getPackages({ ...state.data, pn_page: page, pn_rows: state.data.pn_rows }, text);
  };

  const handleChangeRowsPerPage = (event) => {
    // dispatch({ type: 'UPDATE_PAGINATION', pn_page: 1, pn_rows: event.target.value });

    getPackages({ ...state.data, pn_page: 1, pn_rows: event.target.value }, text);
  };

  // sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleAutocompleteChange = (field) => (event, value) => {
    dispatch({ type: 'UPDATE_FIELD', field, value: typeof value?.value === 'undefined' ? null : value.value });
    getPackages({ ...state.data, pn_page: 1, [field]: typeof value?.value === 'undefined' ? null : value.value }, text);
  };

  const debouncedHandleTextFieldChange = useMemo(
    () =>
      _debounce((data, value) => {
        setText(value);
        getPackages({ ...data }, value);
      }, 300),
    []
  ); // Adjust debounce time as needed)

  const handleTextFieldChange = (field) => (event) => {
    const { value } = event.target;
    debouncedHandleTextFieldChange(state.data, value);
  };

  // data
  const emptyRows =
    state.data.pn_rows - Math.min(state.data.pn_rows, dataTable.length - (state.data.pn_page - 1) * state.data.pn_rows);

  const stateColors = {
    30: '#EAEFFF', // Подано
    35: '#FFF9D7', // На розгляді
    40: '#FEF2E3', // Заблоковано
    50: '#E6F5EA', // Зареєстровано
    90: '#FFEFEF', // Відхілено
  };

  const stateColorText = {
    30: '#5C6A9D', // Подано
    35: '#8E7B4D', // На розгляді
    40: '#A77438', // Заблоковано
    50: '#5F7B67', // Зареєстровано
    90: '#AB5656', // Відхілено
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputBase
            className={classes.root}
            id="pc_filter"
            placeholder="Пошук за актом, VIN-кодом, в.ф., отриму..."
            onChange={handleTextFieldChange('pc_filter')}
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            disableClearable={false}
            value={muTypeData.find((item) => item.label === state.data.pi_orgtype)}
            onChange={handleAutocompleteChange('pi_orgtype')}
            autoHighlight
            getOptionLabel={(option) => option.label}
            options={muTypeData}
            loading={state.indicator}
            renderOption={({ label }) => <span style={{ fontSize: '14px' }}>{label}</span>}
            loadingText="Завантаження..."
            renderInput={(params) => {
              const { inputLabelProps, InputProps, ...rest } = params;
              return (
                <InputBase
                  {...params.InputProps}
                  inputProps={params.inputProps}
                  className={classes.root}
                  placeholder="Тип в.ф."
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            value={actStateData.find((item) => item.value === state.data.pn_state)}
            onChange={handleAutocompleteChange('pn_state')}
            autoHighlight
            getOptionLabel={(option) => option.label}
            options={actStateData}
            loading={state.indicator}
            loadingText="Завантаження..."
            renderOption={({ label }) => <span style={{ fontSize: '14px' }}>{label}</span>}
            renderInput={(params) => {
              const { InputLabelProps, InputProps, ...rest } = params;
              return <InputBase {...params.InputProps} {...rest} className={classes.root} placeholder="Статус акту" />;
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Table style={{ fontSize: '12px', backgroundColor: 'white', marginTop: 30 }} hover="true" size="small">
            <EnhancedTableHead
              classes={{}}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={2}
              headCells={headCells}
            />
            <TableBody>
              {!state.loading && dataTable.length > 0 ? (
                stableSort(dataTable, getComparator(order, orderBy)).map((item, index) => {
                  const { org_type, act_number, vin, reg_date, act_state, id, act_state_id } = item;
                  return (
                    <TableRow key={id}>
                      <StyledTableCell>{org_type}</StyledTableCell>
                      <StyledTableCell>{act_number}</StyledTableCell>
                      <StyledTableCell>{vin}</StyledTableCell>
                      <StyledTableCell>{reg_date}</StyledTableCell>
                      <StyledTableCell>
                        {' '}
                        <Chip
                          label={act_state}
                          style={{
                            width: '100%',
                            backgroundColor: stateColors[act_state_id],
                            color: stateColorText[act_state_id],
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <UncontrolledTooltip placement="top" target={`eyeview${index}`}>
                          Перегляд
                        </UncontrolledTooltip>
                        <IconButton
                          id={`eyeview${index}`}
                          aria-label="view"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push(`/register-vehicle/${id}`);
                          }}
                        >
                          <VisibilityOutlinedIcon style={{ fontSize: 21 }} />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              ) : (
                <CustomLoader isLoading={state.loading} />
              )}
              {!state.loading && emptyRows > 0 && (
                <TableRow style={{ height: emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <CustomTablePagination
            totalRaws={state.total_rows}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={state.data.pn_rows}
            page={state.data.pn_page - 1}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(DepartmentalRegistrationList);
