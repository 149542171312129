// packages
import React from 'react';
// import ReactTable from 'react-table';
import 'react-table/react-table.css';
// import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Button } from 'reactstrap';
import { connect } from 'react-redux';
// assets
import ButtonMaterial from '@material-ui/core/Button';
import _ from 'lodash';
import { Card, CardContent, Grid, IconButton, TextField } from '@material-ui/core';
import logo from '../../../assets/images/gov.ua.white.png';

// own css
import { customTheme, customStyles } from '../../../customStyles/reactSelect';
import BootstrapTooltip from '../../elements/MaterialBootstrap';

// import { history } from '../../../vibe/components/utilities/MainUtils/hist';

// import { Link } from 'react-router-dom';
// import { Loader } from '../../../vibe/';
import { getRecipientsSearch } from '../../../redux/actions/recipient.action';
import { localityAction, setLocality } from '../../../redux/actions/catalog.action';
import { getLocality, searchRecipientRegistr, getRegion, publicSearch, aidType } from '../../../api/api';
import rules from '../../../vibe/components/utilities/MainUtils/rules';
import { getUTCDate } from '../utils/utc';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import logoASRGD from '../../../assets/images/ASRGD-Logo.svg';
import FooterBase from '../../../vibe/components/Footer/FooterBase';
import RecipientTableV2 from './PublicRecipient/RecipientTableV2';
import FooterComponent from "../LandingPage/FooterComponent/FooterComponent";
import headerLogo from "../../../assets/images/ASRGD_Logo 3.svg";
// const CollisionLink = React.forwardRef((props, ref) => (
//     <Link innerRef={ref} to="/recipient-info/view" {...props} />
//   ));

const MOBILE_SIZE = 993;

const useStyles = (theme) => ({
  TestSelect: {
    border: 'none',
    color: 'blue',
    outline: 'none',
  },
  Navbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 40px',
    backgroundColor: '#8BAFD2',

    [theme.breakpoints.down(MOBILE_SIZE)]: {
      padding: '8px 16px',
    },
  },
  LogoNavLink: {
    // width: '215px',
    height: '56px',
    marginRight: '16px',
    padding: 0,

    [theme.breakpoints?.down(MOBILE_SIZE)]: {
      // width: '138px',
      height: '50px',
    },
  },
  LoginNavLink: {
    padding: '14px 30px',
    fontSize: '16px',
    lineHeight: '21px',
    textTransform: 'none',
    color: 'white',

    [theme.breakpoints?.down(MOBILE_SIZE)]: {
      padding: '8px 30px',
    },
  },
  MainRegister: {
    padding: '40px',
    minHeight: 'calc(100vh - 56px - 56px)',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints?.down(MOBILE_SIZE)]: {
      padding: '24px 16px',
    },
  },
  CardContent: {
    padding: '24px 16px',
  },
  FiltersTitleContainer: {
    display: 'flex',
    marginBottom: '24px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  FiltersTitle: {
    flexGrow: 1,
    color: '#141414',
    fontSize: '24px',
    lineHeight: '31px',
    textAlign: 'center',
  },
  ButtonFiltersToggle: {
    flexShrink: 0,
    marginLeft: '8px',
    padding: 0,
    fontSize: '30px',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  ButtonSearch: {
    width: '100%',
    maxWidth: '300px',
    textTransform: 'none',
  },
  TextFieldLabel: {
    fontSize: '12px',
  },
  AutocompleteLabelRoot: {
    paddingRight: '24px',
    fontSize: '12px',
  },
  AutocompleteShrink: {
    paddingRight: 0,
  },
});

class RegisterRecipients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      distrDate: '',
      pd_distr_date: '',
      // p_region: '',
      pn_region: '',
      pn_acquirer_region: '',
      region: [],
      // p_edrpou: '',
      pn_edrpou: '',
      pn_acquirer_edrpou: '',
      // p_orgname: '',
      pc_orgname: '',
      pn_aidtype: '',
      pc_aid_name: '',
      // p_koatuu: '',
      pn_atu: '',
      pn_acquirer_atu: '',
      locality: [],
      acquirerLocality: [],
      defaultValue: '',
      visible: false,
      loading: false,
      data: [],
      // n_number: '',
      pn_numb: '',
      productType: [],
      isMobile: false,
      isFiltersVisible: true,
      isLoading: false
    };
    this.fetchRegionLocality = this.fetchRegionLocality.bind(this);
    this.fetchAcquirerRegionLocality = this.fetchAcquirerRegionLocality.bind(this);
    this.catalogRegion = this.catalogRegion.bind(this);
    this.productCategory = this.productCategory.bind(this);
    // this.emitChange = _.debounce(this.emitChange, 250);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    if (localStorage.region) {
      this.setState({ region: JSON.parse(localStorage.region) });
    } else {
      this.catalogRegion();
    }
    if (localStorage.productType) {
      this.setState({ productType: JSON.parse(localStorage.productType) });
    } else {
      this.productCategory();
    }
    if (localStorage.recipient) {
      this.setState({ data: JSON.parse(localStorage.recipient) });
    }

    if (sessionStorage.recipientObject) {
      const {
        p_region,
        pn_region,
        pn_acquirer_region,
        p_edrpou,
        pn_edrpou,
        pn_acquirer_edrpou,
        p_orgname,
        pn_aidtype,
        pc_aid_name,
        pc_orgname,
        p_koatuu,
        pn_atu,
        pn_acquirer_atu,
        startDate,
        distrDate,
        endDate,
        n_number,
        pn_numb,
      } = JSON.parse(sessionStorage.recipientObject);
      const dateFrom = startDate ? getUTCDate(startDate) : '';
      const dateTill = endDate ? getUTCDate(endDate) : '';
      const date_distr = distrDate ? getUTCDate(distrDate) : '';
      this.setState({
        p_region,
        pn_region,
        pn_acquirer_region,
        p_edrpou,
        pn_edrpou,
        pn_acquirer_edrpou,
        p_orgname,
        pn_aidtype,
        pc_aid_name,
        pc_orgname,
        p_koatuu,
        pn_atu,
        pn_acquirer_atu,
        startDate: dateFrom,
        endDate: dateTill,
        n_number,
        pn_numb,
        distrDate: date_distr,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth < MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false, isFiltersVisible: true });
    }
  };

  toggleFiltersVisibility = () => {
    this.setState((prev) => ({ isFiltersVisible: !prev.isFiltersVisible }));
  };

  filterOptions = (candidate, input) => {
    if (candidate.data.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
    }
    return false;
  };

  handleRoute = (id) => {
    const { history } = this.props;
    history.push(`/recipient-info/${id}/view`);
  };

  // select
  handleSelectChange = (name) => (select, { action, removedValue }) => {
    const objDelete = {};
    objDelete[name] = '';
    const obj = {};
    switch (action) {
      case 'select-option':
        obj[name] = select;
        this.setState(obj);
        if (name === 'pn_region') {
          this.setState({ pn_atu: '' });
          this.fetchRegionLocality(select.value);
        } else if (name === 'pn_acquirer_region') {
          this.setState({ pn_acquirer_atu: '' });
          this.fetchAcquirerRegionLocality(select.value);
        } else if (name === 'pn_aidtype') {
          this.setState({ pc_aid_name: '' });
          // this.fetchAcquirerRegionLocality(select.value);
        }
        break;
      // case 'select-option':
      //   obj[name] = select;
      //   this.setState(obj);
      //   if (name === 'pn_acquirer_region') {
      //     this.setState({ pn_acquirer_atu: '' });
      //     this.fetchAcquirerRegionLocality(select.value);
      //   }
      //   break;
      case 'clear':
        if (name === 'pn_region') {
          this.setState({ pn_atu: '' });
          this.props.setLocality([]);
        }

        if (name === 'pn_acquirer_region') {
          console.log('clearNabyvach');
          this.setState({ pn_acquirer_atu: '' });
          this.props.setLocality([]);
        }

        this.setState(objDelete);
        break;
      // case 'clear':
      //   if (name === 'pn_acquirer_region') {
      //     console.log('clearNabyvach');
      //     this.setState({ pn_acquirer_atu: '' });
      //     this.props.setLocality([]);
      //   }
      //   this.setState(objDelete);
      //   break;
      default:
        return select;
    }
  };

  handleAutocompleteChange = (name) => (event, select) => {
    const action = select ? 'select-option' : 'clear';

    const objDelete = {};
    objDelete[name] = '';
    const obj = {};

    switch (action) {
      case 'select-option':
        obj[name] = select;
        this.setState(obj);

        if (name === 'pn_region') {
          this.setState({ pn_atu: '' });
          this.fetchRegionLocality(select.value);
        } else if (name === 'pn_acquirer_region') {
          this.setState({ pn_acquirer_atu: '' });
          this.fetchAcquirerRegionLocality(select.value);
        } else if (name === 'pn_aidtype') {
          this.setState({ pc_aid_name: '' });
          // this.fetchAcquirerRegionLocality(select.value);
        }

        break;
      case 'clear':
        if (name === 'pn_region') {
          this.setState({ pn_atu: '' });
          this.props.setLocality([]);
        }

        if (name === 'pn_acquirer_region') {
          this.setState({ pn_acquirer_atu: '' });
          this.props.setLocality([]);
        }

        this.setState(objDelete);

        break;
      default:
        return select;
    }
  };

  // datapicker
  handleChangeData = (name) => (data) => {
    this.setState({ [name]: data });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.emitChange(name, value);
  };

  // componentWillUnmount() {
  //   this.emitChangeDebounced.cancel();
  // }

  emitChange = (name, value) => {
    // const { name, value } = target;

    const valuetoUpperCase = value.toUpperCase();
    const validateId = rules.edrpou.test(value);
    const validateOrgName = rules.orgName.test(valuetoUpperCase);
    const validateNumber = rules.number.test(value);
    if (value === '') {
      this.setState({ [name]: value });
    }
    switch (name) {
      case 'pn_edrpou':
        if (validateId) this.setState((prevState) => ({ ...prevState, pn_edrpou: value }));
        break;
      case 'pc_orgname':
        if (validateOrgName) this.setState({ pc_orgname: valuetoUpperCase });
        break;
      case 'pn_numb':
        if (validateNumber) this.setState((prevState) => ({ ...prevState, pn_numb: value }));
        break;
      case 'pn_acquirer_edrpou':
        if (validateId) this.setState((prevState) => ({ ...prevState, pn_acquirer_edrpou: value }));
        break;
      case 'pn_aidtype':
        this.setState((prevState) => ({ ...prevState, pn_aidtype: value }));
        break;
      case 'pc_aid_name':
        this.setState((prevState) => ({ ...prevState, pc_aid_name: value }));
        break;
      default:
        break;
    }
  };

  handleSearch = async (e) => {
    this.setState({isLoading: true});
    e.preventDefault();
    this.setState({
      submitted: true,
    });
    const {
      // p_region,
      pn_region,
      pn_acquirer_region,
      // p_edrpou,
      pn_edrpou,
      pn_acquirer_edrpou,
      // p_orgname,
      pn_aidtype,
      pc_aid_name,
      pc_orgname,
      // p_koatuu,
      pn_atu,
      // startDate,
      // endDate,
      // n_number,
      pn_numb,
      distrDate,
      pn_acquirer_atu,
    } = this.state;
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const data = {
      pn_numb: pn_numb.trim() ? pn_numb.trim() : null,
      pn_region: pn_region ? (pn_region || {}).value : null,
      pn_atu: pn_atu ? (pn_atu || {}).value : null,
      pn_edrpou: pn_edrpou.trim() ? pn_edrpou.trim() : null,
      pc_orgname: pc_orgname.trim() ? pc_orgname.trim() : null,
      pn_aidtype: pn_aidtype ? (pn_aidtype || {}).value : null,
      pc_aid_name: pc_aid_name.trim() ? pc_aid_name.trim() : null,
      pn_acquirer_edrpou: pn_acquirer_edrpou.trim() ? pn_acquirer_edrpou.trim() : null,
      pd_distr_date: distrDate ? distrDate.toLocaleDateString('uk', options) : null,
      pn_acquirer_region: pn_acquirer_region ? (pn_acquirer_region || {}).value : null,
      pn_acquirer_atu: pn_acquirer_atu ? (pn_acquirer_atu || {}).value : null,
      pn_page: null,
      pn_row: null,

      // p_user_id: '',
      // p_user_role: '',
      // p_region: p_region ? (p_region || {}).value : '',

      // p_edrpou: p_edrpou.trim(),

      // p_orgname: p_orgname.trim(),

      // p_koatuu: p_koatuu ? (p_koatuu || {}).value : '',

      // dateStart: startDate ? startDate.toLocaleDateString('uk', options) : '',
      // dateEnd: endDate ? endDate.toLocaleDateString('uk', options) : '',

      // n_number: n_number.trim(),
    };
    this.setState({ data: [], loading: true });
    try {
      const response = await publicSearch(data);
      localStorage.setItem('recipient', JSON.stringify(response.data.cursor));
      sessionStorage.setItem('recipientObject', JSON.stringify(this.state));
      this.setState({ data: response.data.cursor, loading: false });
      this.setState({isLoading: false});
    } catch (error) {
      this.setState({ data: [], loading: false });
      this.setState({isLoading: false});
    }
    // this.props.getRecipientsSearch(data);
    this.setState({ visible: true });
  };

  async fetchRegionLocality(value) {
    try {
      const response = await getLocality(value);
      const localityCatalog = response.data.map((item) => {
        return {
          label: `${item.c_type}${item.c_name}`,
          value: item.id,
          district: item.district_name,
        };
      });
      this.setState({ locality: localityCatalog });
      localStorage.setItem('locality', JSON.stringify(localityCatalog));
    } catch (error) {
      console.log(error);
    }
  }

  async fetchAcquirerRegionLocality(value) {
    try {
      const response = await getLocality(value);
      const localityCatalog = response.data.map((item) => {
        return {
          label: `${item.c_type}${item.c_name}`,
          value: item.id,
          district: item.district_name,
        };
      });
      this.setState({ acquirerLocality: localityCatalog });
      localStorage.setItem('acquirerLocality', JSON.stringify(localityCatalog));
    } catch (error) {
      console.log(error);
    }
  }

  async catalogRegion() {
    try {
      const response = await getRegion();
      const region = response.data.map((el) => {
        return {
          label: el.label,
          value: el.value,
        };
      });
      this.setState({ region });
    } catch (error) {}
  }

  async productCategory() {
    try {
      const response = await aidType();
      const productType = response.data.map((el) => {
        return {
          label: el.label,
          value: el.value,
        };
      });
      this.setState({ productType });
      localStorage.setItem('productType', JSON.stringify(productType));
    } catch (error) {}
  }

  render() {
    const {
      // selectedOption,
      // isOpen,
      region,
      productType,
      // p_region,
      pn_region,
      pn_acquirer_region,
      // p_koatuu,
      pn_atu,
      locality,
      acquirerLocality,
      // p_edrpou,
      pn_edrpou,
      pn_acquirer_edrpou,
      // p_orgname,
      pc_orgname,
      pn_aidtype,
      pc_aid_name,
      pn_acquirer_atu,
      // startDate,
      // endDate,
      distrDate,
      data,
      // n_number,
      pn_numb,
      isMobile,
      isFiltersVisible,
    } = this.state;
    const { history, loading } = this.props;
    const { classes } = this.props;

    return (
      <div>
        <div className="modalHeader">
          <a href="https://www.msp.gov.ua">
            <img className="modalLogo" src={headerLogo} alt="modalLogo" />
          </a>
          <ButtonMaterial variant="contained" color="primary" href="/" className={classes.LoginNavLink}>
            Вхід
          </ButtonMaterial>
        </div>
        {/*<header>*/}
        {/*  <Navbar className={classes.Navbar}>*/}
        {/*    <img src={logoASRGD} alt="ASRGD Logo" className={classes.LogoNavLink} />*/}
        {/*    <ButtonMaterial variant="contained" color="primary" href="/" className={classes.LoginNavLink}>*/}
        {/*      Вхід*/}
        {/*    </ButtonMaterial>*/}
        {/*  </Navbar>*/}
        {/*  /!* <Navbar*/}
        {/*    color="light"*/}
        {/*    dark*/}
        {/*    expand="md"*/}
        {/*    className="bg-gradient text-white"*/}
        {/*    style={{ paddingBottom: '1.5rem', paddingTop: '1.5rem' }}*/}
        {/*  >*/}
        {/*    <Container>*/}
        {/*      <NavbarBrand href="https://www.gov.ua/">*/}
        {/*        <img src={logo} alt="buttonGovUa" />*/}
        {/*      </NavbarBrand>*/}
        {/*      <NavbarToggler onClick={this.toggle} />*/}
        {/*      <Collapse isOpen={isOpen} navbar>*/}
        {/*        <Nav className="ml-auto" navbar>*/}
        {/*          <NavItem> *!/*/}
        {/*  /!* <NavNavLink className="btn-outline" href="/register-recipients-public">Реєстр отримувачів</NavNavLink> *!/*/}
        {/*  /!* <NavLink*/}
        {/*              activeStyle={{*/}
        {/*                outline: '1px solid #fff',*/}
        {/*                fontFamily: 'dia_bold',*/}
        {/*              }}*/}
        {/*              className="nav-link text-white-btn"*/}
        {/*              to="/register-recipients-public"*/}
        {/*            >*/}
        {/*              Єдиний реєстр отримувачів*/}
        {/*            </NavLink>*/}
        {/*          </NavItem> *!/*/}
        {/*  /!* <NavItem>*/}
        {/*        <NavLink activeStyle={{*/}
        {/*              outline: '1px solid #fff',*/}
        {/*              fontFamily:"dia_bold"*/}
        {/*            }}*/}
        {/*            className="nav-link text-white-btn" to='/register-public'>Реєстр гуманітарної допомоги</NavLink>*/}
        {/*      </NavItem> *!/*/}
        {/*  /!* <NavItem>*/}
        {/*        <NavLink activeStyle={{*/}
        {/*              outline: '1px solid #fff',*/}
        {/*              fontFamily:"dia_bold"*/}
        {/*            }}*/}
        {/*            className="nav-link text-white-btn" to='/commission' >Засідання робочої групи</NavLink>*/}
        {/*      </NavItem> *!/*/}
        {/*  /!* <NavItem>*/}
        {/*            <Button className="btn-warning text-dark ml-2" onClick={() => history.push('/')}>*/}
        {/*              Вхід*/}
        {/*            </Button>*/}
        {/*          </NavItem>*/}
        {/*        </Nav>*/}
        {/*      </Collapse>*/}
        {/*    </Container>*/}
        {/*  </Navbar> *!/*/}
        {/*</header>*/}
        <div className={classes.MainRegister}>
          <Card>
            <CardContent className={classes.CardContent}>
              <section className="searchMainRegister">
                <div className={classes.FiltersTitleContainer}>
                  <Typography className={classes.FiltersTitle}>Єдиний реєстр отримувачів</Typography>
                  {isMobile && (
                    <IconButton
                      size="small"
                      className={classes.ButtonFiltersToggle}
                      onClick={this.toggleFiltersVisibility}
                    >
                      {isFiltersVisible ? <ExpandLess fontSize="inherit" /> : <ExpandMore fontSize="inherit" />}
                    </IconButton>
                  )}
                </div>
                <Collapse isOpen={isFiltersVisible}>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                      {/* <label htmlFor="">Область, де зареєстрований отримувач</label> */}
                      <BootstrapTooltip title=" Оберіть область зі списку." placement="top-start">
                        <div>
                          {/* <Select
                          // defaulValue={p_region}
                          value={pn_region}
                          onChange={this.handleSelectChange('pn_region')}
                          options={region}
                          styles={customStyles}
                          className={classes.TestSelect}
                          placeholder=""
                          isClearable
                          isSearchabl
                          isLoading={loading.CATALOG}
                          noOptionsMessage={() => 'некоректне введення даних'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                          name="pn_region"
                        /> */}
                          <Autocomplete
                            name="pn_region"
                            value={pn_region}
                            onChange={this.handleAutocompleteChange('pn_region')}
                            options={region}
                            autoHighlight
                            getOptionSelected={(option) => option.value === pn_region?.value}
                            getOptionLabel={(option) => option.label ?? ''}
                            loading={loading.CATALOG}
                            noOptionsText="Некоректне введення даних"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Область, де зареєстрований отримувач"
                                InputLabelProps={{
                                  classes: { root: classes.AutocompleteLabelRoot, shrink: classes.AutocompleteShrink },
                                }}
                              />
                            )}
                            renderOption={({ label }) => <span style={{ fontSize: '14px' }}>{label}</span>}
                          />
                        </div>
                      </BootstrapTooltip>
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      {/* <label htmlFor="">Населений пункт, де зареєстрований отримувач</label> */}
                      <BootstrapTooltip title=" Оберіть населений пункт зі списку." placement="top-start">
                        <div>
                          {/* <Select
                          value={pn_atu}
                          onChange={this.handleSelectChange('pn_atu')}
                          getOptionLabel={({ label, district }) => (
                            <div>
                              <span>{label}</span> <span style={{ color: '#999' }}>{district}</span>
                            </div>
                          )}
                          options={locality}
                          styles={customStyles}
                          isClearable
                          isLoading={loading.LOCALITY}
                          isSearchable
                          placeholder=""
                          filterOption={this.filterOptions}
                          noOptionsMessage={() => 'Оберіть область'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                          name="pn_atu"
                        /> */}
                          <Autocomplete
                            name="pn_atu"
                            value={pn_atu}
                            onChange={this.handleAutocompleteChange('pn_atu')}
                            options={locality}
                            autoHighlight
                            getOptionSelected={(option) => option.value === pn_atu?.value}
                            getOptionLabel={(option) => {
                              const label = option.label ? option.label : '';
                              const district = option.district ? `, ${option.district}` : '';

                              return `${label}${district}`;
                            }}
                            loading={loading.LOCALITY}
                            noOptionsText="Оберіть область"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Населений пункт, де зареєстрований отримувач"
                                InputLabelProps={{
                                  classes: { root: classes.AutocompleteLabelRoot, shrink: classes.AutocompleteShrink },
                                }}
                              />
                            )}
                            renderOption={({ label, district }) => (
                              <div style={{ fontSize: '14px' }}>
                                <span>{label}</span> <span style={{ color: '#999' }}>{district}</span>
                              </div>
                            )}
                          />
                        </div>
                      </BootstrapTooltip>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                      {/* <label htmlFor="">Код ЄДРПОУ</label> */}
                      <TextField
                        label="Код ЄДРПОУ отримувача"
                        value={pn_edrpou}
                        onChange={(e) => this.handleChange(e)}
                        name="pn_edrpou"
                        type="text"
                        // maxLength="8"
                        autocomplete="off"
                        inputProps={{
                          maxLength: 8,
                        }}
                        InputLabelProps={{ className: classes.TextFieldLabel }}
                      />
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      {/* <label htmlFor="">Назва отримувача</label> */}
                      <TextField
                        label="Назва отримувача"
                        value={pc_orgname}
                        onChange={this.handleChange}
                        name="pc_orgname"
                        type="text"
                        // maxLength="250"
                        autocomplete="off"
                        inputProps={{
                          maxLength: 250,
                        }}
                        InputLabelProps={{ className: classes.TextFieldLabel }}
                      />
                    </div>

                    {/* <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                    <label htmlFor="">Стан в реєстрі</label>
                    <Select
                      value={selectedOption}
                      onChange={this.handleChangeSelect}
                      options={this.state.options}
                      styles={customStyles}
                      placeholder=""
                      // isMulti
                      noOptionsMessage={() => 'помилка серверу'}
                      menuPortalTarget={document.body}
                      theme={customTheme}
                    />
                  </div> */}

                    {/* <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">


                    <DatePicker
                      selected={startDate}
                      onChange={this.handleChangeData('startDate')}
                      dateFormat="dd.MM.yyyy"
                      maxDate={new Date()}
                      locale={uk}
                      className="datePicker"
                      icon={<i className="cal-icon" />}
                      name="startDate"
                      autoComplete="off"
                      customInput={<TextField label="Дата включення в реєстр" />}
                    />
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">


                    <DatePicker
                      selected={endDate}
                      onChange={this.handleChangeData('endDate')}
                      startDate={startDate}
                      minDate={startDate}
                      dateFormat="dd.MM.yyyy"
                      maxDate={new Date()}
                      className="datePicker"
                      locale={uk}
                      autoComplete="off"
                      customInput={<TextField label="Дата виключення з реєстру" />}
                    />
                  </div> */}

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      {/* <label htmlFor="">Номер в реєстрі</label> */}
                      <BootstrapTooltip title="Введіть тільки цифри" placement="top-start">
                        <TextField
                          label="Номер в реєстрі"
                          value={pn_numb}
                          onChange={this.handleChange}
                          name="pn_numb"
                          type="text"
                          // maxLength="250"
                          autocomplete="off"
                          inputProps={{
                            maxLength: 250,
                          }}
                          InputLabelProps={{ className: classes.TextFieldLabel }}
                        />
                      </BootstrapTooltip>
                    </div>

                    {/* <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                    <TextField
                      label="Назва набувача"
                      value={p_orgname}
                      onChange={this.handleChange}
                      name="p_orgname"
                      type="text"
                      // maxLength="250"
                      autocomplete="off"
                      inputProps={{
                        maxLength: 250,
                      }}
                    />
                  </div> */}

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      {/* <label htmlFor="">Категорія товару</label> */}
                      <BootstrapTooltip title=" Оберіть категорію товару зі списку." placement="top-start">
                        <div>
                          {/* <Select
                          // defaulValue={p_region}
                          value={pn_aidtype}
                          onChange={this.handleSelectChange('pn_aidtype')}
                          options={productType}
                          styles={customStyles}
                          placeholder=""
                          isClearable
                          isSearchabl
                          isLoading={loading.CATALOG}
                          noOptionsMessage={() => 'некоректне введення даних'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                          name="pn_aidtype"
                        /> */}
                          <Autocomplete
                            name="pn_aidtype"
                            value={pn_aidtype}
                            onChange={this.handleAutocompleteChange('pn_aidtype')}
                            options={productType}
                            autoHighlight
                            getOptionSelected={(option) => option.value === pn_aidtype?.value}
                            getOptionLabel={(option) => option.label ?? ''}
                            loading={loading.CATALOG}
                            noOptionsText="Некоректне введення даних"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Категорія товару"
                                InputLabelProps={{
                                  classes: { root: classes.AutocompleteLabelRoot, shrink: classes.AutocompleteShrink },
                                }}
                              />
                            )}
                            renderOption={({ label }) => <span style={{ fontSize: '14px' }}>{label}</span>}
                          />
                        </div>
                      </BootstrapTooltip>
                    </div>

                    {/* <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                    <TextField
                      label="Категорія товару"
                      value={pn_aidtype}
                      onChange={this.handleChange}
                      name="pn_aidtype"
                      type="text"
                      // maxLength="250"
                      autocomplete="off"
                      inputProps={{
                        maxLength: 250,
                      }}
                    />
                  </div> */}

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      {/* <label htmlFor="">Назва отримувача</label> */}
                      <TextField
                        label="Найменування товару"
                        value={pc_aid_name}
                        onChange={this.handleChange}
                        name="pc_aid_name"
                        type="text"
                        disabled={pn_aidtype === ''}
                        placeholder={pn_aidtype === '' ? 'Найменування товару' : ''}
                        // maxLength="250"
                        autoComplete="off"
                        inputProps={{
                          maxLength: 250,
                        }}
                        InputLabelProps={{ className: classes.TextFieldLabel }}
                      />
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      {/* <label htmlFor="">Дата включення в реєстр</label> */}

                      <DatePicker
                        selected={distrDate}
                        onChange={this.handleChangeData('distrDate')}
                        dateFormat="dd.MM.yyyy"
                        maxDate={new Date()}
                        locale={uk}
                        className="datePicker"
                        icon={<i className="cal-icon" />}
                        name="distrDate"
                        autoComplete="off"
                        customInput={
                          <TextField label="Дата розподілу" InputLabelProps={{ className: classes.TextFieldLabel }} />
                        }
                      />
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                      {/* <label htmlFor="">Код ЄДРПОУ</label> */}
                      <TextField
                        label="Код ЄДРПОУ набувача"
                        value={pn_acquirer_edrpou}
                        onChange={(e) => this.handleChange(e)}
                        name="pn_acquirer_edrpou"
                        type="text"
                        // maxLength="8"
                        autocomplete="off"
                        inputProps={{
                          maxLength: 8,
                        }}
                        InputLabelProps={{ className: classes.TextFieldLabel }}
                      />
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      {/* <label htmlFor="">Область, де зареєстрований набувач</label> */}
                      <BootstrapTooltip title=" Оберіть область зі списку." placement="top-start">
                        <div>
                          {/* <Select
                          // defaulValue={p_region}
                          value={pn_acquirer_region}
                          onChange={this.handleSelectChange('pn_acquirer_region')}
                          options={region}
                          styles={customStyles}
                          placeholder=""
                          isClearable
                          isSearchabl
                          // isLoading={loading.CATALOG}
                          noOptionsMessage={() => 'некоректне введення даних'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                          name="pn_acquirer_region"
                        /> */}
                          <Autocomplete
                            name="pn_acquirer_region"
                            value={pn_acquirer_region}
                            onChange={this.handleAutocompleteChange('pn_acquirer_region')}
                            options={region}
                            autoHighlight
                            getOptionSelected={(option) => option.value === pn_acquirer_region?.value}
                            getOptionLabel={(option) => option.label ?? ''}
                            loading={loading.CATALOG}
                            noOptionsText="Некоректне введення даних"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Область, де зареєстрований набувач"
                                InputLabelProps={{
                                  classes: { root: classes.AutocompleteLabelRoot, shrink: classes.AutocompleteShrink },
                                }}
                              />
                            )}
                            renderOption={({ label }) => <span style={{ fontSize: '14px' }}>{label}</span>}
                          />
                        </div>
                      </BootstrapTooltip>
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      {/* <label htmlFor="">Населений пункт, де зареєстрований набувач</label> */}
                      <BootstrapTooltip title=" Оберіть населений пункт зі списку." placement="top-start">
                        <div>
                          {/* <Select
                            value={pn_acquirer_atu}
                            onChange={this.handleSelectChange('pn_acquirer_atu')}
                            getOptionLabel={({ label, district }) => (
                              <div>
                                <span>{label}</span> <span style={{ color: '#999' }}>{district}</span>
                              </div>
                            )}
                            options={acquirerLocality}
                            styles={customStyles}
                            isClearable
                            isLoading={loading.LOCALITY}
                            isSearchable
                            placeholder=""
                            filterOption={this.filterOptions}
                            noOptionsMessage={() => 'Оберіть область набувача'}
                            menuPortalTarget={document.body}
                            theme={customTheme}
                            name="pn_acquirer_atu"
                          /> */}
                          <Autocomplete
                            name="pn_acquirer_atu"
                            value={pn_acquirer_atu}
                            onChange={this.handleAutocompleteChange('pn_acquirer_atu')}
                            options={acquirerLocality}
                            autoHighlight
                            getOptionSelected={(option) => option.value === pn_acquirer_atu?.value}
                            getOptionLabel={(option) => {
                              const label = option.label ? option.label : '';
                              const district = option.district ? `, ${option.district}` : '';

                              return `${label}${district}`;
                            }}
                            loading={loading.LOCALITY}
                            noOptionsText="Оберіть область набувача"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Населений пункт, де зареєстрований набувач"
                                InputLabelProps={{
                                  classes: { root: classes.AutocompleteLabelRoot, shrink: classes.AutocompleteShrink },
                                }}
                              />
                            )}
                            renderOption={({ label, district }) => (
                              <div style={{ fontSize: '14px' }}>
                                <span>{label}</span> <span style={{ color: '#999' }}>{district}</span>
                              </div>
                            )}
                          />
                        </div>
                      </BootstrapTooltip>
                    </div>

                    <div className="col-12 mt-4 mb-4 text-center">
                      <ButtonMaterial
                        type="submit"
                        color="primary"
                        variant="contained"
                        className={classes.ButtonSearch}
                        onClick={this.handleSearch}
                      >
                        <i className="fa fa-search mr-1" /> Пошук
                      </ButtonMaterial>
                    </div>
                    {/* {this.state.loading && <Loader type="spin" small/>} */}
                  </div>
                </Collapse>
              </section>
              <section className="dataMainRegister">
                <RecipientTableV2 tableData={data} history={history} isLoading={this.state.isLoading} />
              </section>
            </CardContent>
          </Card>
        </div>
        <FooterComponent/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    catalog: state.catalogs,
    loading: state.isFatching,
    errMessage: state.error,
  };
};

export default withStyles(useStyles)(
  connect(mapStateToProps, {
    getRecipientsSearch,
    localityAction,
    setLocality,
  })(RegisterRecipients)
);
