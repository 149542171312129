import React from 'react';
import iocLogo from '../../../assets/images/ioc-logo.svg';
import facebookLogo from '../../../assets/images/facebook-logo.svg';
import telegramLogo from '../../../assets/images/telegram-logo.svg';
import linkedinLogo from '../../../assets/images/linkedin.svg';
import youtubeLogo from '../../../assets/images/youtube.svg';

export default function Footer() {
  return (
    <footer className="app-footer">
      <div className="modalFooter">
        <div className="modalFooter-content">
          <div className="modalFooter-start">
            <a href="https://www.ioc.gov.ua/">
              <img className="ioc-logo" src={iocLogo} alt="ioc-logo" />
            </a>
            <div className="modalFooter-rights">© 2024 ДП «ІОЦ Мінсоцполітики України»</div>
          </div>
          <div className="modalFooter-middle">
            <div className="modalFooter-contacts-title">Наші контакти:</div>
            <a className="modalFooter-phone" href="tel:+380444941910">
              (044) 494 19 10
            </a>
            <a className="modalFooter-email" href="mailto:callcenter@ioc.gov.ua">
              callcenter@ioc.gov.ua
            </a>
          </div>
          <div className="modalFooter-end">
            <div className="modalFooter-links-title">Слідкуйте за нами:</div>
            <div className="modalFooter-links">
              <a href="https://www.facebook.com/ICC.MSP/">
                <img className="modalLogo" src={facebookLogo} alt="modalLogo" />
              </a>
              <a href="https://t.me/+DMro2EEWOGplOTZi">
                <img className="modalLogo" src={telegramLogo} alt="modalLogo" />
              </a>
              <a href="https://www.linkedin.com/company/ioc-gov-ua/">
                <img className="modalLogo" src={linkedinLogo} alt="modalLogo" />
              </a>
              <a href="https://www.youtube.com/@ICCMinSocUA">
                <img className="modalLogo" src={youtubeLogo} alt="modalLogo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
