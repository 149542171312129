import React from 'react';
import { useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import { Autocomplete } from '@material-ui/lab';
import { IconButton, Input, TextField, Typography } from '@material-ui/core';
import Select from 'react-select';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import { StyledTableCell } from './StyledTableCell';
import NumberFormatCustom from '../../../../vibe/components/inputs/NumberFormatCustom.component';

// import saveTable from '../../../../assets/images/saveTable.png';
// import editTable from '../../../../assets/images/edittable.png';
// import deleteTable from '../../../../assets/images/deleteTable.png';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
}));

const InventoryRow = ({
  index,
  currentIndex,
  item,
  handleInput,
  handleSelect,
  saveInventoryRow,
  deleteInventoryRow,
  editInventoryRow,
  handleSelectUnit,
  disableChanges,
}) => {
  const classes = useStyles();

  const {
    catalogs: { aidType, runit },
    inventory: { current },
  } = useSelector((state) => state);

  const { aidTypeObj, description, runitObj, weight, count, price, value } = item;

  return (
    <>
      <TableRow>
        <StyledTableCell width="21%" style={{ minWidth: 250 }}>
          {currentIndex === index ? (
            <Select
              value={aidType.filter(({ value }) => Number(value) === Number(current.aidTypeObj?.value))}
              onChange={handleSelect('aidTypeObj')}
              name="aidTypeObj"
              options={aidType}
              styles={customStyles}
              // isLoading={this.props.loading.CATALOG}
              placeholder=""
              noOptionsMessage={() => 'помилка серверу'}
              menuPortalTarget={document.body}
              theme={customTheme}
            />
          ) : (
            <Typography>{aidTypeObj?.label}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%">
          {currentIndex === index ? (
            <Input className={classes.input} name="description" value={current.description} onChange={handleInput} />
          ) : (
            <Typography>{description}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%" style={{ minWidth: 250 }}>
          {currentIndex === index ? (
            <Autocomplete
              value={runit.filter(({ value }) => value === current.runitObj?.value)[0]}
              onChange={(event, option) => handleSelectUnit(option)}
              // autoHighlight
              getOptionLabel={(option) => option.label}
              options={runit}
              renderInput={(params) => (
                <TextField
                  // InputLabelProps={{ style: { fontSize: '12px' } }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Одиниця виміру"
                />
              )}
            />
          ) : (
            <Typography>{runitObj?.label}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%">
          {currentIndex === index ? (
            <Input
              className={classes.input}
              name="weight"
              value={current.weight}
              onChange={handleInput}
              inputComponent={NumberFormatCustom}
              inputProps={{
                decimalSeparator: '.',
                decimalScale: 3,
                maxLength: 10,
              }}
            />
          ) : (
            <Typography>{weight}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%">
          {currentIndex === index ? (
            <Input
              className={classes.input}
              name="count"
              value={current.count}
              onChange={handleInput}
              inputComponent={NumberFormatCustom}
              inputProps={{
                decimalSeparator: '.',
                decimalScale: 2,
                maxLength: 10,
              }}
            />
          ) : (
            <Typography>{count}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%">
          {currentIndex === index ? (
            <Input
              className={classes.input}
              name="price"
              value={current.price}
              onChange={handleInput}
              inputComponent={NumberFormatCustom}
              inputProps={{
                decimalSeparator: '.',
                decimalScale: 4,
                maxLength: 10,
              }}
            />
          ) : (
            <Typography>{price}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%">
          {currentIndex === index ? (
            <Input
              className={classes.input}
              name="value"
              value={current.value}
              onChange={handleInput}
              inputComponent={NumberFormatCustom}
              inputProps={{
                decimalSeparator: '.',
                decimalScale: 4,
                maxLength: 14,
              }}
            />
          ) : (
            <Typography>{value}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="17%" style={{ minWidth: '300px' }}>
          <BootstrapTooltip title="Зберегти">
            <span>
              <IconButton
                disabled={currentIndex !== index || disableChanges}
                color="primary"
                aria-label="Done"
                onClick={(e) => {
                  e.stopPropagation();
                  saveInventoryRow();
                }}
              >
                <DoneIcon fontSize="inherit" />
                {/* <img src={saveTable} /> */}
              </IconButton>
            </span>
          </BootstrapTooltip>
          <BootstrapTooltip title="Редагувати">
            <span>
              <IconButton
                disabled={currentIndex !== -1 || disableChanges}
                color="primary"
                aria-label="Edit"
                onClick={(e) => {
                  e.stopPropagation();
                  editInventoryRow(index, item.pi_id);
                }}
              >
                <EditIcon fontSize="inherit" />
                {/* <img src={editTable} /> */}
              </IconButton>
            </span>
          </BootstrapTooltip>
          <BootstrapTooltip title="Видалити">
            <span>
              <IconButton
                color="primary"
                aria-label="Delete"
                disabled={disableChanges}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteInventoryRow(item.pi_id);
                }}
              >
                <DeleteIcon fontSize="inherit" />
                {/* <img src={deleteTable} /> */}
              </IconButton>
            </span>
          </BootstrapTooltip>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export default InventoryRow;
