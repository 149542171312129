// packages
import React from 'react';
import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// own css
import { Card, CardContent, CardActions, Button, TextField, Grid, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { customTheme, customStyles } from '../../../customStyles/reactSelect';

// сomponents
import InvestorTableFullScreen from './InvestorTableFullScreen';
import RegisterExcel from './RegisterExcel/RegisterExcel';
// redux
import {
  clearRecipient,
  getRecipientsFatch,
  approve,
  denied,
  recipientAction,
  getRecipientsSearch,
  getControlsSearch,
  recipientById,
  getRecipientHistoryApplications,
  clearControls,
} from '../../../redux/actions/recipient.action';
import { createLoadingSelector, createErrorMessageSelector, clearErrors } from '../../../redux/actions/selectors';
import { localityAction, setLocality } from '../../../redux/actions/catalog.action';
import { makeStyles } from '@material-ui/core/styles';
// validation
import rules from '../../../vibe/components/utilities/MainUtils/rules';
import BootstrapTooltip from '../../elements/MaterialBootstrap';

const loadingSelector = createLoadingSelector(['CATALOG', 'LOCALITY']);

const styles = (theme) => ({
  // smallSize: {
  root: {
    fontSize: '12px',
  },
  button: {
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  // },
});

class RegisterRecipients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      p_region: '',
      p_edrpou: '',
      p_orgname: '',
      p_koatuu: '',
      n_number: '',
      p_recipienttype: '',
      conReason: '',
      defaultValue: '',
      visible: false,

      dataTable: [],
      pd_create: '',
      pi_reason: '',
      pn_edrpou: '',
    };
  }

  componentDidMount() {
    this.props.clearControls();
  }

  handleSelectChange = (name) => (e, select) => {
    const objDelete = {};
    objDelete[name] = '';
    if (select) {
      // const { value } = select;
      const obj = {};
      obj[name] = select;
      this.setState(obj);

      if (name === 'conReason') {
        this.setState({ p_koatuu: '' });
        this.props.localityAction(select.value);
      }
    } else {
      this.setState(objDelete);

      if (name === 'p_region') {
        this.setState({ p_koatuu: '' });
        this.props.setLocality([]);
      }
    }
  };

  // datapicker
  handleChangeData = (name) => (data) => {
    this.setState({ [name]: data });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    // const valuetoUpperCase = value.toUpperCase();
    if (value === '') {
      this.setState({ [name]: value });
    }
    switch (name) {
      case 'pn_edrpou':
        rules.edrpou.test(event.target.value) && this.setState({ [name]: event.target.value });
        break;
      default:
        break;
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
    const { conReason, pd_create, pn_edrpou } = this.state;
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const data = {
      pn_edrpou: pn_edrpou ? pn_edrpou.trim() : null,
      pd_create: pd_create ? pd_create.toLocaleDateString('uk', options) : null,
      pi_reason: conReason ? Number(conReason.value) : null,
      pn_rows: null,
      pn_page: null,
    };

    this.props.getControlsSearch(data);
    this.setState({ visible: true });
  };

  render() {
    const { controls, classes } = this.props;

    return (
      <div className="RegisterRecipient">
        <section className="searchRegisterRecipient">
          <Card>
            <form onSubmit={this.handleSearch} autoComplete="off">
              <CardContent>
                <div className="row">
                  <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                    <TextField
                      label="ЄДРПОУ отримувача"
                      value={this.state.pn_edrpou}
                      onChange={this.handleChange}
                      name="pn_edrpou"
                      type="text"
                      autocomplete="off"
                      // style={{fontSize: '12px'}}
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      inputProps={{
                        maxLength: 8,
                      }}
                    />
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                    <DatePicker
                      selected={this.state.pd_create}
                      onChange={this.handleChangeData('pd_create')}
                      dateFormat="dd.MM.yyyy"
                      maxDate={new Date()}
                      locale={uk}
                      className="datePicker"
                      icon={<i className="cal-icon" />}
                      name="pd_create"
                      autoComplete="off"
                      customInput={
                        <TextField InputLabelProps={{ style: { fontSize: '12px' } }} label="Дата створення" />
                      }
                    />
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                    <BootstrapTooltip title=" Оберіть тип отримувача зі списку." placement="top-start">
                      <div>
                        <Autocomplete
                          value={this.state.conReason}
                          onChange={this.handleSelectChange('conReason')}
                          autoHighlight
                          getOptionSelected={(option) => option.value === this.state.conReason.value}
                          getOptionLabel={(option) => option.label}
                          options={this.props.catalog.conReason}
                          loading={this.props.loading.CATALOG}
                          renderInput={(params) => (
                            <TextField {...params} InputLabelProps={{ style: { fontSize: '12px' } }} label="Причина" />
                          )}
                          renderOption={({ label, district }) => <span style={{ fontSize: '14px' }}>{label}</span>}
                        />
                      </div>
                    </BootstrapTooltip>
                  </div>

                  <div className="col-12 mt-3 mb-4 text-center" />
                </div>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  className={classes.button}
                >
                  <i className="fa fa-search mr-1" /> Пошук
                </Button>
                {/* Excel button */}
                {/* <RegisterExcel /> */}
              </CardActions>
            </form>
          </Card>
        </section>
        <section className="dataMainRegister mt-3">
          <div>
            {this.state.visible ? (
              <InvestorTableFullScreen
                history={this.props.history}
                getRecipientsFatch={this.props.getRecipientsFatch}
                // recipients={data}
                dataTable={controls}
                loading={this.props.loading}
                approve={this.props.approve}
                denied={this.props.denied}
                error={this.props.errMessage}
                user={this.props.user}
                recipientById={this.props.recipientById}
                getRecipientHistoryApplications={this.props.getRecipientHistoryApplications}
              />
            ) : (
              ''
            )}
            <br />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
    recipients: state.recipient.recipients,
    controls: state.recipient.controls,
    loading: state.isFatching,
    errMessage: state.error,
    user: state.auth.user,
    catalog: state.catalogs,
    recipient: state.recipient.recipient,
    isFatching: loadingSelector(state),
  };
};

export default connect(mapStateToProps, {
  clearRecipient,
  clearControls,
  getRecipientsSearch,
  getControlsSearch,
  localityAction,
  recipientAction,
  createLoadingSelector,
  createErrorMessageSelector,
  clearErrors,
  getRecipientsFatch,
  approve,
  denied,
  recipientById,
  setLocality,
  getRecipientHistoryApplications,
})(withStyles(styles)(RegisterRecipients));
